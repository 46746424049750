import { Component, Injector } from '@angular/core';
import { BaseResourceListComponent } from '@sicapes/core';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { ParametrizacaoService } from '../../../../../shared/service/parametrizacao/parametrizacao.service';
import { InscricaoService } from '../../../../../shared/service/inscricao/inscricao.service';
import { OrcamentoModel } from '../../../../../shared/models/inscricao/orcamento.model';
import { OrcamentoProjetoService } from '../../../../../shared/service/inscricao/orcamento.projeto.service';
import { ItemBolsaModel } from '../../../../../shared/models/inscricao/item-bolsa.model';
import { Pais } from '../../../../../shared/models/corporativo/pais.model';
import { VinculoModalidadeBolsa } from '../../../../../shared/models/edital/vinculo-modalidade-bolsa.model';
import { PaisService } from '../../../../../shared/service/corporativo/pais.service';
import { ItemOrcamentoModel } from 'src/app/shared/models/inscricao/item-orcamento.model';
import { ModalidadeBolsa } from 'src/app/shared/models/edital/modalidade-bolsa.model';
import { ParametroProjetoBolsa } from 'src/app/shared/models/parametrizacao/parametro-projeto-bolsa.model';

@Component({
    selector: 'app-inscricao-orcamento-projeto-form',
    templateUrl: './inscricao-orcamento-projeto-form.component.html',
    styleUrls: ['./inscricao-orcamento-projeto-form.component.less']
})

export class InscricaoOrcamentoProjetoFormComponent extends BaseResourceListComponent<OrcamentoModel> {

    /**
     * Variável de orcamento 
     */
    orcamento: OrcamentoModel;

    /**
     * A variável vinculoModalidadeBolsa era obtid aa partir da informação do edital, mas como
     * os as informações de modalidade de bolsas, quantidades são mantidas na parametrização 
     * (GESTORES) essa informcão agora é um parametros_projeto_bolsa
     * @see obterListaModalidade()
     */
    vinculosModalidadesBolsa: VinculoModalidadeBolsa[] = [];

    /**
     * Variável antiga onde era feito um backup do vinculomodalidadebolsa do edital
     *
     * @type {VinculoModalidadeBolsa[]}
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    vinculosModalidadesBolsaMemoria: VinculoModalidadeBolsa[] = [];

    /**
     * A lista de parametrosProjetoBolsa de uma parametrizacao
     *
     * @type {ParametroProjetoBolsa[]}
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    parametroProjetoBolsa: ParametroProjetoBolsa[] = [];

    /**
     * Variavel criada para não atrapalhar o fluxo antigo (atualmente a variavel vinculosModalidadeBolsa 
     * está usando os dados do parametroBolsaProjeto)
     *
     * @type {VinculoModalidadeBolsa[]}
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    editalVinculosModalidadeBolsa: VinculoModalidadeBolsa[] = [];

    /**
     * Lista de países utilizados pela parametrização, sendo possível ter somente 1 país
     * parametrizado ou uma lista de países 
     * @see obterPaisDestino()
     * @see obterPaises()
     */
    paises: Pais[] = [];

    /**
     * 
     */
    flagBotaoInserirModalidadeValid = false;

    /**
     * Flag em que é feita a desativação do campo país destido, tornado somente um campo
     * Readonly e o valor preselecionado.
     * @see obterPaisDestino()
     */
    desabilitaCampoPaisDestino = false;

    /**
     * Model utilizado para fazer a inserção de uma bolsa selecionada (ItemBolsaModel)
     * @see ItemBolsaModel
     */
    bolsa: ItemBolsaModel | any = {};


    protected regraMultiplicadorIesAplicada: boolean = false;

    /**
     * Creates an instance of InscricaoOrcamentoProjetoFormComponent.
     * @param {Injector} injector
     * @param {ToastrService} toastr
     * @param {ConfirmationService} confirmationService
     * @param {OrcamentoProjetoService} orcamentoProjetoService
     * @param {PaisService} paisService
     * @param {ParametrizacaoService} parametrizacaoService
     * @param {InscricaoService} inscricaoService
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    constructor(protected injector: Injector,
        protected toastr: ToastrService,
        protected confirmationService: ConfirmationService,
        public orcamentoProjetoService: OrcamentoProjetoService,
        public paisService: PaisService,
        public parametrizacaoService: ParametrizacaoService,
        public inscricaoService: InscricaoService) {
        super(injector, confirmationService, parametrizacaoService, inscricaoService);
    }

    protected loadFormulario(): void {
        this.initOrcamento();
    }

    /**
     * Método em que a cadeia de inicialização é chamado carregando os dados de orçamento da
     * inscrição, o orcamento consiste nos dados de itens de custeio e itens de bolsa
     *
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    public initOrcamento(): void {
        this.vinculosModalidadesBolsa = [];
        this.vinculosModalidadesBolsaMemoria = [];
        this.orcamentoProjetoService.obterOrcamento(this.proponente, this.codigo).subscribe(
            (row: OrcamentoModel) => {
                this.orcamento = row;
                this.obterListaModalidade();
                this.obterPaisDestino();
                this.setQuantidadeOrcamentoCusteio(this.orcamento);
            },
            error => this.toastr.error(this.MSG_SERVIDOR_FOR_ERROR)
        );
    }

    /**
     * Método para adicionar automaticamente a quantidade de um item de custeio, uma vez que,
     * esse valor ATUALMENTE é tido com padrão 1
     *
     * @private
     * @param {OrcamentoModel} orcamento
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    private setQuantidadeOrcamentoCusteio(orcamento: OrcamentoModel): void {
        for (let index = 0; index < orcamento.itens_orcamento.length; index++) {
            const element = orcamento.itens_orcamento[index];
            element.quantidade = 1
        }
    }

    /**
     * Método utilizado para realizar a conta dos valores totais de um orcamento/custeio
     *
     * @param {OrcamentoModel} orcamento
     * @returns {number}
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    public contaTotal(orcamento: OrcamentoModel): number {

        let total = 0;
        if (!orcamento) {
            return 0;
        }

        if (orcamento.itens_orcamento.length) {
            orcamento.itens_orcamento.forEach((item) => total += (item.quantidade * item.valor_unitario));
        }

        return Math.round(total * 100) / 100;
    }

    /**
     * Realiza o bloqueio do campo quantidade. Esse campo só pode ser preenchido após os dados
     * de país e modalidade de bolsa serem preenchidos.
     *
     * @returns {boolean}
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    public bloqueiaCampoQuantidade(): boolean {
        return this.bolsa.modalidade_bolsa === null || typeof this.bolsa.modalidade_bolsa === 'undefined';
    }

    /**
     * Método para validação do quantitativo de bolsas selecionado no campo QUANTIDADE
     * onde deve bloquear solicitar mais que o permitido para o edital e mais que o
     * permitido para cada modalidade de bolsa parametrizada.
     *
     * @returns {(boolean | void)}
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    public validarBolsas(): boolean | void {

        const modalidadeSelecionada: ModalidadeBolsa = this.bolsa.modalidade_bolsa;
        if (typeof modalidadeSelecionada === 'undefined' || modalidadeSelecionada === null) return false;

        const limiteMaximoBolsasEdital: number = this.parametrizacao.quantidade_maxima_bolsas;
        const limiteMaximoModalidade: number = this.parametrizacao.parametros_projetos_bolsas.filter((m: ParametroProjetoBolsa) => m.modalidade.sigla === modalidadeSelecionada.sigla)[0].maximo;
        const limiteMinimoModalidade: number = this.parametrizacao.parametros_projetos_bolsas.filter((m: ParametroProjetoBolsa) => m.modalidade.sigla === modalidadeSelecionada.sigla)[0].minimo;

        let total: number = 0;
        let totalModalidade: number = 0;

        // Filtra a modalidade de bolsa atual com as cadastradas
        for (let index = 0; index < this.orcamento.bolsas_orcamento.length; index++) {
            const element = this.orcamento.bolsas_orcamento[index];
            total = total + element.quantidade;
            if (modalidadeSelecionada.sigla === element.modalidade_bolsa.sigla && this.bolsa.pais.sigla === element.pais.sigla)
                totalModalidade = totalModalidade + element.quantidade;
        }


        // 1. Verifica máximo de bolsas permitido para o edital
        if (this.bolsa.quantidade + total > limiteMaximoBolsasEdital) {
            this.bolsa.quantidade = limiteMaximoBolsasEdital - total;
            this.toastr.warning(`Limite de ${limiteMaximoBolsasEdital} beneficiários para o edital`);
            return false;
        }

        // 2. Verifica a quantidade de Bolsas para a modalidade
        if (this.bolsa.quantidade + totalModalidade > limiteMaximoModalidade) {
            this.bolsa.quantidade = limiteMaximoModalidade - totalModalidade;
            this.toastr.warning(`Limite de ${limiteMaximoModalidade} beneficiários para a modalidade de bolsa ${modalidadeSelecionada.descricao}`);
            return false;
        }
        // 3. Verifica valor mínimo
        if (this.bolsa.quantidade + totalModalidade < limiteMinimoModalidade) {
            this.bolsa.quantidade = limiteMinimoModalidade;
            return false;
        }

        return true;

    }

    /**
     * Método para exclusão de uma bolsa cadastrada no inscrição.
     *
     * @param {ItemBolsaModel} itemBolsa
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    public excluirItemBolsa(itemBolsa: ItemBolsaModel): void {

        this.confirmationService.confirm({
            message: this.MSG_CONFIRM,
            accept: () => {
                this.orcamentoProjetoService.excluirItemBolsa(this.proponente, this.codigo, itemBolsa).subscribe(
                    (r) => {
                        this.toastr.success(this.MSG_DELETE);
                        this.regraMultiplicadorIesAplicada = false;
                        this.ngOnInit();
                    },
                    error => this.toastr.error(this.MSG_SERVIDOR_FOR_ERROR)
                );

            }
        });
    }

    /**
     * Obtém a lista de país destido baseada na parametrização
     *
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    public obterPaisDestino(): void {
        const paises: Pais[] = [];

        if (this.parametrizacao.edital.vinculosModalidadesBolsa.length > 0) {

            // seta o país se for brasil
            const modalidadeBrasil = this.parametrizacao.edital.vinculosModalidadesBolsa.filter((item, index, array) => item.pais.id === 31);
            if (modalidadeBrasil.length > 0 && modalidadeBrasil[0].pais.id === 31) {
                paises.push(modalidadeBrasil[0].pais);
            }

            // Adiciona país a lista de países utilizados na configuração do edital (VinculoModalidadeBolsa -> Editais)
            this.parametrizacao.edital.vinculosModalidadesBolsa.forEach((m) => {
                if (!(paises.filter((p) => p.id === m.pais.id).length > 0)) {
                    // País NÃO INFORMADO - Exterior
                    if (m.pais.id === 251) {
                        m.pais.nome = 'Exterior';
                    }
                    paises.push(m.pais);
                }
            });
        }

        if (paises.length === 1) {
            this.bolsa.pais = paises[0];
            this.paises = [...paises];
            this.desabilitaCampoPaisDestino = true;
            this.limitaModalidadePorDestino(paises[0]);
        } else if (paises.length > 1) {
            this.paises = [...paises];
            this.desabilitaCampoPaisDestino = false;
        }

        if (paises.length === 0) {
            this.obterPaises();
        }
    }

    /**
     * Obtém a lista de países do service
     *
     * @see PaisService
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    public obterPaises(): void {
        this.paisService.getAllDataToResources().subscribe((paises) => {
            this.paises = paises;
            // this.obterPaisDestino();
            if (this.parametrizacao.parametros_projetos_paises.length === 1) {
                this.bloqueaPaisDestino();
            } else if (this.parametrizacao.parametros_projetos_paises.length > 1) {
                this.listaPaisesParametrizacao();
            }
        });
    }

    /**
     * Obtém a lista de modalidade de bolsas a partir dos dados da parametrização
     * 
     * @returns {void}
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    public obterListaModalidade(): void {
        this.vinculosModalidadesBolsaMemoria = [];
        this.vinculosModalidadesBolsa = [];

        if (this.orcamento !== null) {
            this.parametrizacao.parametros_projetos_bolsas.filter((modalidade) => {
                if (
                    modalidade !== null
                    // && this.orcamento.bolsas_orcamento.filter(e => modalidade.modalidade.id === e.modalidade_bolsa.id).length === 0
                    // && this.vinculosModalidadesBolsa.filter(mb => mb.modalidade.id === modalidade.modalidade.id).length === 0
                ) {
                    this.vinculosModalidadesBolsa.push(modalidade);
                    this.parametroProjetoBolsa.push(modalidade);
                }
            });
            this.editalVinculosModalidadeBolsa = this.parametrizacao.edital.vinculosModalidadesBolsa.map((vinculoModalidade) => { return vinculoModalidade; })
        }
        if (this.vinculosModalidadesBolsa.length < 1)
            return;
        this.vinculosModalidadesBolsa.sort((a, b) => {
            if (a.modalidade.descricao > b.modalidade.descricao)
                return 1;

            if (a.modalidade.descricao < b.modalidade.descricao)
                return -1;

            return 0;

        });

        this.vinculosModalidadesBolsaMemoria = JSON.parse(JSON.stringify(this.vinculosModalidadesBolsa));

    }

    /**
     * Salva um item orçamento
     *
     * @param {ItemOrcamentoModel} itemOrcamento
     * @param {number} [size=1]
     * @param {number} [index=0]
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    public salvarItemOrcamento(itemOrcamento: ItemOrcamentoModel, size: number = 1, index: number = 0): void {
        this.orcamentoProjetoService.salvarItemOrcamento(this.proponente, this.codigo, this.orcamento.id, itemOrcamento).subscribe((ib) => {

            // Recarrega o controller
            if (size - 1 === index) {
                this.ngOnInit();
                this.toastr.success('Item salvo com sucesso.');
            }

        }, error => {
            this.toastr.error(`Não foi possível salvar o item: ${itemOrcamento.descricao}`);
        })
    }

    /**
     * Salva a lista de Itens orçamento
     *
     * @param {OrcamentoModel} orcamento
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    public salvarItemOrcamentoLote(orcamento: OrcamentoModel): void {

        orcamento.itens_orcamento.forEach((item, index: number) => {
            item.quantidade = 1;
            const totalItens: number = item.quantidade * item.valor_unitario;
            const objParametrizado = orcamento.itens_orcamento.filter((o) => o.parametro_item_despesa.sub_tipo_despesa.id === item.sub_tipo_despesa.id)[0].parametro_item_despesa;
            if (totalItens > objParametrizado.valor_maximo_item_despesa) {
                this.toastr.warning(`Não foi possível salvar o item: ${item.descricao}. O valor total do item ultrapassa o limite máximo.`);
                item.valor_unitario = 0;
                return;
            }
            this.salvarItemOrcamento(item, orcamento.itens_orcamento.length, index);
        });
    }


    /**
     * Valida se todos os campos estão preenchidos
     *
     * @returns {boolean}
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    public validarIncluirBolsa(): boolean {

        if (this.validarBolsas() && this.bolsa.pais && this.bolsa.modalidade_bolsa && (typeof this.bolsa.quantidade !== 'undefined' && this.bolsa.quantidade !== null && this.bolsa.quantidade !== 0)) {
            return false;
        }
        return true;
    }

    /**
     * Salva o Item bolsa
     *
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    public salvarItemBolsa(): void {

        const itemBolsa = ItemBolsaModel.fromJson(this.bolsa);

        this.orcamentoProjetoService.salvarItemBolsa(this.proponente, this.codigo, itemBolsa).subscribe(
            (p: any) => {
                //this.atualizarListaBolsa(itemBolsa);
                this.toastr.success('Item salvo com sucesso.');
                this.flagBotaoInserirModalidadeValid = true;
                this.bolsa = {};
                this.ngOnInit();
            },
            error => {
                this.toastr.error('Não foi possível salvar o cadastro da bolsa: ' + this.bolsa.vinculoEmpregaticio.modalidadeBolsa.descricao);
                this.flagBotaoInserirModalidadeValid = false;
            }
        );
    }

    /**
     *
     *
     * @private
     * @param {*} itemBolsa
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    private atualizarListaBolsa(itemBolsa): void {
        this.vinculosModalidadesBolsa = this.vinculosModalidadesBolsaMemoria;

        this.vinculosModalidadesBolsa.forEach((mb, index) => {
            if (mb.modalidade.id === itemBolsa.modalidade_bolsa.id) {
                this.vinculosModalidadesBolsa.splice(index, 1);
            }
        });
    }

    /**
     * Validação do valores permitidos no  itens de despesa
     *
     * @param {ItemOrcamentoModel} itemDespesa
     * @returns {void}
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    public verificaValorTotal(itemDespesa: ItemOrcamentoModel): void {
        setTimeout(() => {

            if (!this.isInteger(itemDespesa.quantidade * itemDespesa.valor_unitario)) {
                itemDespesa.valor_unitario = 0;
                this.toastr.error('O valor total do item de despesa deve ser inteiro.');
            }

            const itensDespesa: ItemOrcamentoModel[] = this.orcamento.itens_orcamento;


            let total = this.verificaValorUtilizado(itemDespesa);

            // 1. verifica valor total do orcamento
            itemDespesa.valor_unitario = this.contaValorTotalItem(itemDespesa, total);

            // 2. Verifica valor mínimo
            itemDespesa.valor_unitario = this.contaValorMinimoParametrizado(itemDespesa);

            // 3. Se o valor total n passar verifica o valor unitario
            itemDespesa.valor_unitario = this.contaValorMaximoParametrizado(itemDespesa);

        }, 100);
    }

    /**
     *
     *
     * @private
     * @param {ItemOrcamentoModel} itemDespesa
     * @returns {number}
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    private verificaValorUtilizado(itemDespesa: ItemOrcamentoModel): number {
        // itens de orcamento
        let orcamento = this.orcamento;

        let valorUtilizado = orcamento.itens_orcamento.reduce((prev: any, curr: any): any => {
            return prev + (curr.quantidade * curr.valor_unitario)
        }, 0)

        // retorna a soma dos valores no orcamento
        return valorUtilizado;
    }

    /**
     * Calcula resto do valor total parametrizado para o edital
     *
     * @private
     * @param {ItemOrcamentoModel} itemDespesa
     * @param {number} total
     * @returns {number}
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    private contaValorTotalItem(itemDespesa: ItemOrcamentoModel, total: number): number {
        return (total > this.parametrizacao.valor_maximo_orcamento) ? this.parametrizacao.valor_maximo_orcamento - (total - (itemDespesa.quantidade * itemDespesa.valor_unitario)) : itemDespesa.valor_unitario;
    }

    /**
     * calcula se o valor informado é menor que o valor mínimo
     * parametrizado para o item receita
     *
     * @private
     * @param {ItemOrcamentoModel} itemDespesa
     * @returns {number}
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    private contaValorMinimoParametrizado(itemDespesa: ItemOrcamentoModel): number {
        if (typeof itemDespesa.parametro_item_despesa.valor_minimo_item_despesa === 'undefined')
            return itemDespesa.valor_unitario;

        return ((itemDespesa.valor_unitario * itemDespesa.quantidade) < itemDespesa.parametro_item_despesa.valor_minimo_item_despesa) ? itemDespesa.parametro_item_despesa.valor_minimo_item_despesa : itemDespesa.valor_unitario;
    }

    /**
     * calcula se o valor informado é maior que o valor máximo 
     * parametrizado para o item receita
     *
     * @private
     * @param {ItemOrcamentoModel} itemDespesa
     * @returns {number}
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    private contaValorMaximoParametrizado(itemDespesa: ItemOrcamentoModel): number {
        if (typeof itemDespesa.parametro_item_despesa.valor_maximo_item_despesa === 'undefined')
            return itemDespesa.valor_unitario;

        return ((itemDespesa.valor_unitario * itemDespesa.quantidade) > itemDespesa.parametro_item_despesa.valor_maximo_item_despesa) ? itemDespesa.parametro_item_despesa.valor_maximo_item_despesa : itemDespesa.valor_unitario;
    }

    /**
     * 
     *
     * @param {*} itemDespesa
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    public verificaQuantidadeInteiro(itemDespesa): void {
        if (!this.isInteger(itemDespesa.quantidade)) {
            itemDespesa.quantidade = 0;
            this.toastr.error('A quantidade deve ser um número inteiro.');
        }
        this.verificaValorTotal(itemDespesa);
    }

    /**
     *
     *
     * @private
     * @param {*} x
     * @returns {*}
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    private isInteger(x): any {
        return x % 1 === 0;
    }

    /**
     *
     *
     * @private
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    private listaPaisesParametrizacao(): void {
        const paises = [];

        this.parametrizacao.parametros_projetos_paises.forEach((p) => {
            paises.push(p.pais);
        });
        this.paises = paises;
    }

    /**
     *
     *
     * @private
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    private bloqueaPaisDestino(): void {

        const siglaPais = this.parametrizacao.parametros_projetos_paises[0].pais.sigla;
        const paises = this.paises.filter((p) => {
            return p.sigla === siglaPais;
        });

        // Adiciona automaticamente o país ao model de inclusão de bolsa
        if (paises.length > 0) {
            this.paises = paises;
            this.bolsa = {
                pais: this.paises[0]
            };
            this.desabilitaCampoPaisDestino = true;
        }
    }

    /**
     * Listagem para modalidades de acordo com o pais destino (Brasil, Exterior)
     * 
     * @private
     * @param {Pais} pais
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    private limitaModalidadePorDestino(pais: Pais): void {
        this.bolsa.modalidadeBolsa = null;
        if (pais != null && typeof pais !== undefined) {
            this.filterModalidadeBolsa(pais);
        }
    }

    protected filterModalidadeBolsa(pais: Pais): void {
        // this.vinculosModalidadesBolsaMemoria.filter((e) => {
        //     this.vinculosModalidadesBolsa = this.parametrizacao.edital.vinculosModalidadesBolsa.filter((ppb) => {
        //         if (pais.sigla === 'BRA') {
        //             return e.modalidade.sigla === ppb.modalidadeBolsa.sigla && pais.sigla === ppb.pais.sigla;
        //         } else {
        //             return e.modalidade.sigla === ppb.modalidadeBolsa.sigla && pais.sigla !== 'BRA';
        //         }
        //     });
        // });
    }

    /**
     *
     *
     * @param {*} event
     * @returns {boolean}
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    public somenteNumeros(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    /**
     * Filtra a modalidade de bolsa de acordo com o país selecionado
     *
     * @param {Pais} pais
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    public filtrarModalidadePais(pais: Pais): void {

        this.vinculosModalidadesBolsa = [];
        /**
         *  Lista de bolsas cadastradas ao orcamento
         */
        let bolsasOrcamento = (this.orcamento.bolsas_orcamento);

        /**
         * Copia a lista anterior da memória
         */
        this.vinculosModalidadesBolsa = this.vinculosModalidadesBolsaMemoria;

        /**
         * Filtra a lista de modalidades cadastrada para o país no EDITAL
         */
        let modalidadesPermitidas = this.editalVinculosModalidadeBolsa.filter((p: VinculoModalidadeBolsa) => (pais.sigla === 'BRA' && p.pais.sigla === pais.sigla) || (pais.sigla !== 'BRA' && p.pais.sigla !== 'BRA'));

        /**
         * Filtra a lista de modalidades do parametroProjeotBolsa (nome da variável utilizada está errada)
         */
        this.vinculosModalidadesBolsa = this.vinculosModalidadesBolsa.filter(vmb => {
            let retorno = false;
            for (let index = 0; index < modalidadesPermitidas.length; index++) {
                const mp = modalidadesPermitidas[index];
                if (mp.modalidadeBolsa.sigla === vmb.modalidade.sigla) retorno = true;
            }
            return retorno;
        })

        /**
         * filtra pelo total permitido na parametrizacão por país
         */
        this.vinculosModalidadesBolsa = this.vinculosModalidadesBolsa.filter((ppb: ParametroProjetoBolsa) => {
            let retorno = true;
            for (let index = 0; index < bolsasOrcamento.length; index++) {
                const element = bolsasOrcamento[index];
                if (element.pais.sigla === pais.sigla && element.modalidade_bolsa.sigla === ppb.modalidade.sigla)
                    retorno = false;
            }
            return retorno;
        });
    }

}
