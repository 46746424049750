import { Component, Injector } from '@angular/core';
import { InscricaoOrcamentoProjetoFormComponent } from '../inscricao-orcamento-projeto-form/inscricao-orcamento-projeto-form.component';
import { ItemBolsaModel } from 'src/app/shared/models/inscricao/item-bolsa.model';
import { ParametroProjetoBolsa } from 'src/app/shared/models/parametrizacao/parametro-projeto-bolsa.model';
import { ParametroProjetoBolsaAno } from 'src/app/shared/models/parametrizacao/parametro-projeto-bolsa-ano.model';
import { OrcamentoModel } from 'src/app/shared/models/inscricao/orcamento.model';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { OrcamentoProjetoService } from 'src/app/shared/service/inscricao/orcamento.projeto.service';
import { PaisService } from 'src/app/shared/service/corporativo/pais.service';
import { ParametrizacaoService } from 'src/app/shared/service/parametrizacao/parametrizacao.service';
import { InscricaoService } from 'src/app/shared/service/inscricao/inscricao.service';
import { ParticipanteService } from 'src/app/shared/service/inscricao/participante.service';
import { Participante } from 'src/app/shared/models/inscricao/participante.model';
import {
    CaracteristicaProjetoService
} from '../../../inscricao-dados-gerais-projeto/shared/service/caracteristica.projeto.service';
import {
    InscricaoCaracteristicaProjetoModel
} from '../../../../../shared/models/inscricao/inscricao-caracteristica-projeto.model';
import { Observable } from 'rxjs';

/**
 * Componente para formulário de ORÇAMENTO PROJETO BOLSA (Bolsa com seleção por ano - tipos de integração Parcela/Cota Ano)
 *
 * @export
 * @class InscricaoOrcamentoProjetoBolsaFormComponent
 * @extends {InscricaoOrcamentoProjetoFormComponent}
 */
@Component({
    selector: 'app-inscricao-orcamento-projeto-bolsa-form',
    templateUrl: './inscricao-orcamento-projeto-bolsa-form.component.html',
    styleUrls: ['./inscricao-orcamento-projeto-bolsa-form.component.less']
})

export class InscricaoOrcamentoProjetoBolsaFormComponent extends InscricaoOrcamentoProjetoFormComponent {

    /**
     * Lista os anos de um projeto (acompanhamento e parametrizacao)
     */
    public anos: string[];

    /**
     * Limite anual de bolsas é parametrizado em parametro_projeot_bolsa_anos
     */
    public limitesAnuais: any[] = [];
    public limitesAnuaisCache: any[] = [];

    /**
     * Lista de participantes - DO TIPO IES DO BRASIL.
     * É utilizado quando adicionada a parametrizacao o campo (Edital Com bolsa Variável por IES?) 
     * o dado na parametrização é possui_bolsa_variavel_ies 
     */
    public participantes: Participante[];

    /**
     * Quando executada a regra para multiplicar os limites de bolsa máximo esse valor deve ser TRUE
     * @default false
     */
    protected regraMultiplicadorIesAplicada: boolean = false;

    public caracteristicaProjeto: InscricaoCaracteristicaProjetoModel;

    public limiteBeneficiarios: any;

    /**
     * Creates an instance of InscricaoOrcamentoProjetoBolsaFormComponent.
     * @param {Injector} injector
     * @param {ToastrService} toastr
     * @param {ConfirmationService} confirmationService
     * @param {OrcamentoProjetoService} orcamentoProjetoService
     * @param {PaisService} paisService
     * @param {ParametrizacaoService} parametrizacaoService
     * @param {InscricaoService} inscricaoService
     * @param {ParticipanteService} participanteService
     * @memberof InscricaoOrcamentoProjetoBolsaFormComponent
     */
    constructor(protected injector: Injector,
        protected toastr: ToastrService,
        protected confirmationService: ConfirmationService,
        public orcamentoProjetoService: OrcamentoProjetoService,
        public paisService: PaisService,
        public parametrizacaoService: ParametrizacaoService,
        public inscricaoService: InscricaoService,
        public participanteService: ParticipanteService,
        public caracteristicaProjetoService: CaracteristicaProjetoService) {
        super(
            injector,
            toastr,
            confirmationService,
            orcamentoProjetoService,
            paisService,
            parametrizacaoService,
            inscricaoService
        );
    }

    public obterParametrizacaoLimpa = new Observable(observer => {
        this.parametrizacaoService.obterParametrizacaoInscricao(this.inscricao.chave_parametrizacao).subscribe(parametrizacao => {
            observer.next(parametrizacao);
        });
    });


    public initOrcamento(): void {
        this.obterParametrizacaoLimpa.subscribe(param => {
            this.parametrizacao = param;
            this.limitesAnuais = [];
            this.orcamentoProjetoService.obterOrcamento(this.proponente, this.codigo).subscribe(
                (row: OrcamentoModel) => {
                    this.orcamento = row;

                    // Regra para multiplicar maximo de bolsas dependendo das IES BRASILEIRAS
                    if (this.parametrizacao.possui_bolsa_variavel_ies
                        && this.parametrizacao.quantidade_multiplicador_bolsa > 0
                        && !this.regraMultiplicadorIesAplicada) {
                        this.regraMultiplicadorBolsaPorIesBrasileira();
                    }

                    this.obterListaModalidade();
                    this.obterPaisDestino();
                    this.obterListaAnos();
                },
                error => this.toastr.error(this.MSG_SERVIDOR_FOR_ERROR)
            );
        })

    }

    /**
     * Método para validação do quantitativo de bolsas selecionado no campo QUANTIDADE
     * onde deve bloquear solicitar mais que o permitido para o edital e mais que o
     * permitido para cada modalidade de bolsa parametrizada além dos valores anuais
     * permitidos
     *
     * @returns {void}
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    public validarBolsas(): boolean {


        if (this.bolsa != null && this.parametrizacao.parametros_projetos_bolsas.length > 0) {
            if (this.bolsa.quantidade < 0) { this.bolsa.quantidade = 0; }

            // Valida total de bolsa Global
            if (this.validarTotalBolsas()) return false;
            // valida total de bolsas por ano
            if (this.validarLimiteBolsaAno()) return false;

            if (this.validarLimiteBolsaAnoCadastrados()) return false;

            this.validarLimiteModalidadeAnual();

            return true;
        } else {
            return true;
        }
    }

    private validarTotalBolsas(): boolean {

        // Modalidades de bolsa parametrizada (PARAMETRIZACAO)
        const modalidadesParametrizadas = this.parametrizacao.parametros_projetos_bolsas;

        // Total máximo (global) de beneficiários permitidos globalmente (PARAMETRIZACAO)
        const quantidadeMaximaPermitida = this.parametrizacao.quantidade_maxima_bolsas;

        // Modalidade selecionada
        const modalidadeBolsa = this.bolsa.modalidade_bolsa;

        // Bolsas cadastradas
        const beneficiarios = this.orcamento.bolsas_orcamento.filter(a => {
            return a.modalidade_bolsa.sigla === modalidadeBolsa.sigla && (!a.pais || a.pais.sigla === this.bolsa.pais.sigla);
        });

        // quantidade
        const quantidade = this.bolsa.quantidade;

        // total de bolsas
        let total = 0;
        beneficiarios.forEach(b => { total += b.quantidade; });
        const totalfinal = JSON.parse(JSON.stringify(total));
        total += quantidade;

        // limite por modaldiade
        const limiteGlobalModalidade = modalidadesParametrizadas.filter((a) =>
            a.modalidade.sigla === modalidadeBolsa.sigla && (!a.pais || a.pais.sigla === this.bolsa.pais.sigla)
        )[0];

        if (total > limiteGlobalModalidade.maximo) {
            const msg = 'O total de beneficiários para a modalidades de bolsa ' + modalidadeBolsa.descricao + ' dever menor ou igual a ' + limiteGlobalModalidade.maximo;
            this.toastr.error(msg);
            this.bolsa.quantidade = limiteGlobalModalidade.maximo - (totalfinal);
            return true;
        }

        return false;
    }

    private validarLimiteBolsaAno(): boolean {

        // ano
        const ano = this.bolsa.ano;

        // pais
        const pais = this.bolsa.pais.sigla;

        // quantidade
        const quantidade = this.bolsa.quantidade;

        // Bolsas cadastradas
        const beneficiarios = this.orcamento.bolsas_orcamento.filter(a => a.ano == ano && (a.modalidade_bolsa.id === this.bolsa.modalidade_bolsa.id && (!a.pais || a.pais.sigla === pais)));

        let totalBolsaAno = 0;

        beneficiarios.forEach(a => { totalBolsaAno += a.quantidade; });

        // limite anual do edital
        const limiteAno = this.obterLimiteAnualEdital(this.inscricao.sigla_edital);

        let limiteAnoSelecionado = null;

        this.obterListaAnos().filter((a, i) => {
            if (a === ano) {
                limiteAnoSelecionado = limiteAno.filter(b => {
                    return b.descricao === a && (!b.parametro_bolsa.pais || b.parametro_bolsa.pais.sigla === pais);
                })[0];
            }
        });

        if (limiteAnoSelecionado.quantidade_minima > (totalBolsaAno + quantidade)) {
            this.bolsa.quantidade = limiteAnoSelecionado.quantidade_minima;
        }
        if (limiteAnoSelecionado.quantidade_maxima < (totalBolsaAno + quantidade)) {
            this.bolsa.quantidade = ((limiteAnoSelecionado.quantidade_maxima - totalBolsaAno) < 0) ? 0 : (limiteAnoSelecionado.quantidade_maxima - totalBolsaAno);
            this.toastr.error('O total de beneficiários para ' + ano + ' deve ser menor ou igual a ' + limiteAnoSelecionado.quantidade_maxima + '.');
            return true;
        }

        return false;
    }

    private validarLimiteBolsaAnoCadastrados(): boolean {

        // Limite anual do edital
        const limiteAno = this.obterLimiteAnualEdital(this.inscricao.sigla_edital);

        if (limiteAno.length < 1)
            return false;

        // ano
        const ano = this.bolsa.ano;

        // pais
        const pais = this.bolsa.pais.sigla;

        // quantidade
        const quantidade = this.bolsa.quantidade;

        // Quantidade Utilizada (cadastrada) para o ano
        let quantidadeUtilizada = 0;

        // Lista de bolsas solicitadas no ano
        const modalidadesCadastradasAno = this.orcamento.bolsas_orcamento.filter((bs) => bs.ano === ano && ((!bs.pais || bs.pais.sigla === pais)));

        for (let index = 0; index < modalidadesCadastradasAno.length; index++) {
            const e = modalidadesCadastradasAno[index];
            // Entrar no calculo de quantidadeUtilizada validando a modalidade
            if (this.bolsa.modalidade_bolsa.sigla === e.modalidade_bolsa.sigla) {
                quantidadeUtilizada = quantidadeUtilizada + e.quantidade;
            }
        }

        // if (limiteAno[0].quantidade_maxima < (quantidadeUtilizada + quantidade)) {
        //     this.bolsa.quantidade = limiteAno[0].quantidade_maxima - quantidadeUtilizada;
        //     return true;
        // }

        return false;
    }


    private validarLimiteModalidadeAnual(): boolean {
        if (this.parametrizacao.parametros_projetos_bolsas_anos.length <= 0) return false;

        // quantidade
        const quantidade = this.bolsa.quantidade;

        return false;
    }

    // Função onde é definido o total de bolsas por ano
    // Deverá ser implementado na parametrização para que seja retirado do código [FRONTEND].
    private obterLimiteAnualEdital(siglaEdital: string): any[] {

        let lista = [];

        if (this.parametrizacao.parametros_projetos_bolsas_anos.length > 0) {
            this.limitesAnuais = [...this.limitesAnuais, ...this.parametrizacao.parametros_projetos_bolsas_anos];
            let anoModalidade = lista = this.parametrizacao.parametros_projetos_bolsas_anos.filter((ppba) => ppba.parametro_bolsa!)
            lista = this.parametrizacao.parametros_projetos_bolsas_anos.filter((ppba) => ppba.descricao === this.bolsa.ano);
            // lista = this.parametrizacao.parametros_projetos_bolsas_anos.filter(ppba => ppba.parametro_bolsa_ano_pai === null);

            if (anoModalidade.length > 0)
                lista = this.parametrizacao.parametros_projetos_bolsas_anos.filter((ppba) => ppba.descricao === this.bolsa.ano && ppba.parametro_bolsa! && ppba.parametro_bolsa!.modalidade.id === this.bolsa.modalidade_bolsa.id);

            this.limiteBeneficiarios = JSON.parse(JSON.stringify(lista));
        }
        return lista;
    }

    public organizarListaBolsaAno(parametroProjetoBolsasAnos): any {
        parametroProjetoBolsasAnos.sort((a, b) => {
            if (a.descricao > b.descricao) { return 1; }
            if (a.descricao < b.descricao) { return -1; }
            return 0;
        });
        return parametroProjetoBolsasAnos.filter((ppba) => {
            ppba.possui_filho = true;
            return ppba.parametro_bolsa_ano_pai === null;
        });
    }

    public possuiModalidadeFilho(parametroProjetoBolsasAnos: any): any {
        return this.parametrizacao.parametros_projetos_bolsas_anos.filter((ppba) => {
            if (ppba.parametro_bolsa_ano_pai) {
                if (ppba.parametro_bolsa_ano_pai.id === parametroProjetoBolsasAnos.id) {
                    return ppba.parametro_bolsa_ano_pai;
                }
            }
        });
    }

    /**
     * Ao Iniciar o controller as modaldiades de bolsa são adicionadas
     *
     * @memberof InscricaoOrcamentoProjetoBolsaFormComponent
     */
    public obterListaModalidade(): void {

        this.vinculosModalidadesBolsa = [];

        // Se tiver parametro_projeto_bolsa adiciona a listagem se o total cadastrado
        // no orçamento for menor que o limite máximo

        if (this.orcamento !== null) {
            this.parametrizacao.parametros_projetos_bolsas.forEach((modalidade: ParametroProjetoBolsa) => {
                if (modalidade !== null) {
                    let total: number = 0;
                    this.orcamento.bolsas_orcamento.forEach((bo: ItemBolsaModel) => {
                        if (modalidade.modalidade.id === bo.modalidade_bolsa.id && (!bo.pais || bo.pais.sigla === modalidade.pais.sigla))
                            total = total + bo.quantidade;
                    })
                    if (total < modalidade.maximo)
                        this.vinculosModalidadesBolsa.push(modalidade);
                }
            });
        }

        // Sort por nome de modalidade de bolsa
        this.vinculosModalidadesBolsa.sort((a, b) => a.modalidade.descricao > b.modalidade.descricao ? 1 : a.modalidade.descricao === b.modalidade.descricao ? 0 : -1);

        // Reparse do JSON
        this.vinculosModalidadesBolsaMemoria = JSON.parse(
            JSON.stringify(
                this.vinculosModalidadesBolsa
            )
        );
    }

    /**
     * Cria lista de anos selecionáveis para o projeto de bolsa
     *
     * @memberof InscricaoOrcamentoProjetoBolsaFormComponent
     */
    public obterListaAnos(): any[] {
        this.anos = [];
        this.parametrizacao.parametros_projetos_bolsas_anos.filter((i) => {
            this.anos.push(i.descricao);
        });
        this.anos = [...new Set(this.anos)];

        this.anos.sort((a, b) => {
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
        });
        return this.anos;
    }

    public validarAnoSelecionado(ano: number): void {
        this.bolsa = { ano: ano };
    }

    // Valida se todos os campos estão preenchidos
    public validarIncluirBolsa(): boolean {
        if (
            this.bolsa.ano
            && this.bolsa.pais
            && this.bolsa.modalidade_bolsa
            && (typeof this.bolsa.quantidade !== 'undefined' && this.bolsa.quantidade !== null && this.bolsa.quantidade !== 0)
            && (this.parametrizacao.tipo_integracao_bolsa !== 'P' || this.parametrizacao.tipo_integracao_bolsa === 'P' && (typeof this.bolsa.mes !== 'undefined' && this.bolsa.mes !== null && this.bolsa.mes !== 0))
        )
            return false;
        return true;
    }

    /**
     * Salva o Item bolsa
     *
     * @memberof InscricaoOrcamentoProjetoFormComponent
     */
    public salvarItemBolsa(): void {
        if (this.validarBolsas()) {
            const itemBolsa = ItemBolsaModel.fromJson(this.bolsa);
            this.orcamentoProjetoService.salvarItemBolsa(this.proponente, this.codigo, itemBolsa).subscribe(
                (p: any) => {
                    this.toastr.success('Item salvo com sucesso.');
                    this.bolsa = {};
                    this.regraMultiplicadorIesAplicada = false;
                    this.initOrcamento();
                },
                error => {
                    this.toastr.error('Não foi possível salvar o cadastro da bolsa: ' + this.bolsa.vinculoEmpregaticio.modalidadeBolsa.descricao);
                }
            );
        }
    }

    /**
     * Controla o total máximo de bolsas de acordo com a quantidade de instituições no país cadastradas
     * 
     * @private
     * @memberof InscricaoOrcamentoProjetoBolsaFormComponent
     * 
     * Na parametrização:
     *  quantidade_multiplicador_bolsa = Quantidade máxima de instituiçoes que serão multiplicadas
     *  possui_bolsa_variavel_ies      = Flag que habilita a regra
     */
    private regraMultiplicadorBolsaPorIesBrasileira(): void {
        this.participanteService.obterParticipantes(this.proponente, this.codigo).subscribe(t => {
            this.participantes = t.filter(
                (p: Participante) => p.tipo.id === ParticipanteService.ID_TIPO_IES_PRINCIPAL_BRASIL || p.tipo.id === ParticipanteService.ID_TIPO_IES_ASSOCIADA_BRASIL
            );

            if (this.participantes.length > 0) {


                for (let index = 0; index < this.parametrizacao.parametros_projetos_bolsas.length; index++) {
                    let totalParticipante = (this.parametrizacao.quantidade_multiplicador_bolsa <= this.participantes.length) ? this.parametrizacao.quantidade_multiplicador_bolsa : this.participantes.length;
                    const ppb: ParametroProjetoBolsa = this.parametrizacao.parametros_projetos_bolsas[index];
                    ppb.maximo = ppb.maximo * totalParticipante;

                }

                for (let index = 0; index < this.parametrizacao.parametros_projetos_bolsas_anos.length; index++) {
                    let totalParticipantes = (this.parametrizacao.quantidade_multiplicador_bolsa <= this.participantes.length) ? this.parametrizacao.quantidade_multiplicador_bolsa : this.participantes.length;
                    const ppba: ParametroProjetoBolsaAno = this.parametrizacao.parametros_projetos_bolsas_anos[index];
                    ppba.quantidade_maxima = ppba.quantidade_maxima * totalParticipantes;
                }

                this.obterListaModalidade();

                this.regraMultiplicadorIesAplicada = true;
            }
        }, err => this.toastr.error('Não foi possivel recuperar os participantes da inscrição.')
        );
    }

    public filterModalidadeBolsa(pais): void {
        this.bolsa.modalidade_bolsa = null;
        const parametroBolsaAnoModalidade = this.parametrizacao.parametros_projetos_bolsas_anos.filter((ppba) => ppba.parametro_bolsa !== null);

        this.vinculosModalidadesBolsa = this.vinculosModalidadesBolsaMemoria.filter((e) => {
            let check = false;
            this.parametrizacao.edital.vinculosModalidadesBolsa.forEach((v) => {
                if ((e.pais && e.pais.sigla === pais.sigla) || (!e.pais && e.modalidade.sigla === v.modalidadeBolsa.sigla && pais.sigla === v.pais.sigla)) {
                    check = true;
                }
            });
            return check;
        });

        if (parametroBolsaAnoModalidade.length > 0) {
            this.vinculosModalidadesBolsa = this.vinculosModalidadesBolsa.filter((vmb => {
                const modalidadeAno = parametroBolsaAnoModalidade.filter(pbam => pbam.descricao === this.bolsa.ano);
                return modalidadeAno.filter(mb => mb.parametro_bolsa.modalidade.id === vmb.modalidade.id).length > 0;
            }));
        }

    }

    public sortModalidade(paramModaldiadeBolsa, tipo: string) {
        let modalidades = []

        if (tipo === 'BS') {
            modalidades = [...paramModaldiadeBolsa.sort((a, b) => {
                if (a.modalidade.descricao > b.modalidade.descricao) return 1;
                if (a.modalidade.descricao < b.modalidade.descricao) return -1;
                return 0;
            })];
        }

        if (tipo === 'BSA') {
            modalidades = [...paramModaldiadeBolsa.sort((a, b) => {
                if (a.parametro_bolsa.modalidade.descricao > b.parametro_bolsa.modalidade.descricao) return 1;
                if (a.parametro_bolsa.modalidade.descricao < b.parametro_bolsa.modalidade.descricao) return -1;
                return 0;
            })];
        }

        return modalidades;
    }
}
