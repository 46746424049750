import {Component, Input, OnInit} from '@angular/core';
import {AuthService} from '@sicapes/auth';
import {PessoaService} from '../../shared/service/corporativo/pessoa.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
    @Input() userData: any;
    visibleSidebar;
    constructor(private authService: AuthService, public pessoaService: PessoaService) {
    }
    ngOnInit(): void {
    }

    isGestorInscricao(): boolean {
        return this.userData.roles !== null && this.userData.roles.includes('GESTOR SICAPES 3');
    }

    logout(): void {
        this.authService.logout();
    }

}
