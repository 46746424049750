import { Injectable, Injector } from '@angular/core';
import {Pais} from '../../models/corporativo/pais.model';
import {BaseResourceService} from '@sicapes/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaisService extends BaseResourceService<Pais> {

  constructor(protected injector: Injector) {
    super('rest/paises', injector, Pais.fromJson);
  }

  obterPaises(): Observable<any[]> {
    return this.http.get(this.apiPath + '?page_size=-1').pipe(
        map(this.jsonDataToResources.bind(this)),
        catchError(this.handleError)
    );
  }
}
