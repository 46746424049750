<p-dialog [(visible)]="display" [modal]="true" [style]="{width: '35vw'}" [maximizable]="true">
    <div class="modal-body">
        <fieldset>
            <legend>{{tituloModal}}</legend>

            <form [formGroup]="resourceForm">

                <div class="row">
                    <div class="col-md-6 form-group required">
                        <label for="cnpj">Empregador (CNPJ)</label>
                        <p-inputMask (change)="carregarPessoaJuridica(resourceForm.value)" styleClass="form-control" formControlName="cnpj" placeholder="99.999.999/9999-9" mask="99.999.999/9999-99"></p-inputMask>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 form-group required">
                        <label for="razaoSocial">Razão Social</label>
                        <input type="text" formControlName="razaoSocial" class="form-control" />
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 form-group">
                        <label for="nomeFantasia">Nome Fantasia</label>
                        <input type="text" formControlName="nomeFantasia" maxlength="100" class="form-control" />
                        <span class="help-block" >Máximo de 100 caracteres</span>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 form-group">
                        <label for="site">Website</label>
                        <input type="text" formControlName="site" class="form-control" />
                        <span class="help-block" >Máximo de 100 caracteres</span>
                    </div>
                </div>

                <fieldset formGroupName="endereco">
                    <legend>Endereço</legend>

                    <div class="row">
                        <div class="col-md-6 form-group required">
                            <label for="finalidadeEndereco">Finalidade</label>
                            <p-dropdown styleClass="form-control" formControlName="finalidadeEndereco"
                                        [options]="finalidadesEndereco" placeholder="Selecione"
                                        optionLabel="descricaoFinalidadeEndereco" [showClear]="true"></p-dropdown>
                        </div>

                        <div class="col-md-6 form-group required">
                            <label for="cep">CEP/ZIP code</label>
                            <input type="text" formControlName="cep" class="form-control" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 form-group required">
                            <label for="logradouro">Logradouro</label>
                            <input type="text" formControlName="logradouro" class="form-control" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-6 form-group required">
                            <label for="complemento">Complemento</label>
                            <input type="text" formControlName="complemento" class="form-control" />
                        </div>

                        <div class="col-md-6 form-group required">
                            <label for="numero">Número</label>
                            <input type="text" formControlName="numero" class="form-control" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12 form-group required">
                            <label for="bairro">Bairro</label>
                            <input type="text" formControlName="bairro" class="form-control" />
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-4 form-group required">
                            <label for="pais">País</label>
                            <p-dropdown styleClass="form-control" formControlName="pais"
                                        [options]="paises" placeholder="Selecione"
                                        optionLabel="nome" [showClear]="true"></p-dropdown>
                        </div>

                        <div class="col-md-4 form-group required">
                            <label for="uf">UF</label>
                            <p-dropdown styleClass="form-control" formControlName="uf"
                                        [options]="ufs" placeholder="Selecione"
                                        optionLabel="nome" [showClear]="true"></p-dropdown>
                        </div>

                        <div class="col-md-4 form-group required">
                            <label for="municipio">Município</label>
                            <p-dropdown styleClass="form-control" formControlName="municipio"
                                        [options]="municipios" placeholder="Selecione"
                                        optionLabel="nome" [showClear]="true"></p-dropdown>
                        </div>
                    </div>

                </fieldset>

                <div class="modal-footer">
                    <button class="btn btn-primary" value="Salvar" title="Salvar" [disabled]="submittingForm || resourceForm.invalid" (click)="submitForm()">
                        <span><i class="glyphicon glyphicon-floppy-disk"></i> Salvar</span>
                    </button>
                    <button type="button" class="btn btn-danger" value="Cancelar" title="Cancelar" (click)="display=false">
                        <span><i class="glyphicon glyphicon-alert"></i> Cancelar</span>
                    </button>
                </div>

            </form>
        </fieldset>
    </div>

</p-dialog>
