import {Component, EventEmitter, Injector, OnInit, Output} from '@angular/core';
import {BaseResourceFormComponent} from '@sicapes/core';
import {ItemProjetoModel} from '../../../models/inscricao/item-projeto.model';
import {InscricaoService} from '../../../service/inscricao/inscricao.service';
import {ParametrizacaoService} from '../../../service/parametrizacao/parametrizacao.service';
import {ToastrService} from 'ngx-toastr';
import {Validators} from '@angular/forms';
import {
    CaracteristicaProjetoService
} from '../../../../pages/formularios/inscricao-dados-gerais-projeto/shared/service/caracteristica.projeto.service';

@Component({
    selector: 'app-inscricao-objetivo-meta-indicadores-form-modal',
    templateUrl: './inscricao-objetivo-meta-indicadores-form-modal.component.html',
    styleUrls: ['./inscricao-objetivo-meta-indicadores-form-modal.component.less']
})
export class InscricaoObjetivoMetaIndicadoresFormModalComponent extends BaseResourceFormComponent<ItemProjetoModel> implements OnInit {

    @Output() eventPai = new EventEmitter();
    display = false;
    tituloModal: string;

    constructor(protected injector: Injector,
                public inscricaoService: InscricaoService,
                public parametrizacaoService: ParametrizacaoService,
                private toastr: ToastrService,
                protected caracteristicaProjetoService: CaracteristicaProjetoService) {
        super(injector, parametrizacaoService, inscricaoService);
    }

    ngOnInit(): void {
        this.buildResourceForm();
    }

    protected loadFormulario(): void {}

    public openModalForm(row: any): void {
        this.resourceForm.reset();
        this.tituloModal = 'Cadastrar Item';

        if (row.id) {
            this.tituloModal = 'Editar Item';
            this.resourceForm.patchValue(row);
        }

        this.display = true;
    }

    protected buildResourceForm(): void {
        this.resourceForm = this.formBuilder.group({
            id: [null],
            tipo: [null],
            indicador: [{value: null, disabled: false}, [Validators.required]],
            meta: [{value: null, disabled: false}, [Validators.required]],
            objetivo: [{value: null, disabled: false}, [Validators.required]],
        });
    }

    public submitForm(): void {
        this.submittingForm = true;
        const itemProjeto = this.resourceForm.getRawValue();
        itemProjeto.tipo = 1;

        this.caracteristicaProjetoService.salvarItemProjeto(this.proponente, this.codigo, itemProjeto).subscribe(
            (p: any) => {
                this.toastr.success(this.MSG_ACTIONS_FOR_SUCCESS);
                this.submittingForm = false;
                this.display = false;
                this.eventPai.emit();
            },
            error => {
                this.toastr.error(error.error.detail);
                this.submittingForm = false;
            }
        );

    }

}
