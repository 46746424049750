import { Finalidade } from './finalidade.model';
import {BaseResourceModel} from '@sicapes/core';
import {InscricaoCorreioEletronico} from '../../../pages/formularios/inscricao-correio-eletronico/shared/models/inscricao-correio-eletronico.model';

export class CorreioEletronico extends BaseResourceModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public principal?: boolean,
        public principal_pessoa?: boolean,
        public finalidade?: Finalidade,
        public dt_desativacao?: any,
        public inscricaoCorreioEletronico?: InscricaoCorreioEletronico,
        public checkUtilizado?: any
    ) {
        super();
    }

    static fromJson(jsonData: any): CorreioEletronico {
        return Object.assign(new CorreioEletronico(), jsonData);
    }
}
