import { Component, Input, DoCheck } from '@angular/core';
import { ChildActivationEnd, Event, Router } from '@angular/router';

@Component({
    selector: 'app-subnavigation',
    templateUrl: './subnavigation.component.html',
    styleUrls: ['./subnavigation.component.less']
})
export class SubnavigationComponent implements DoCheck {

    @Input() subMenu: any = [];
    index: number = 0;
    proponente: any;
    codigo: any;
    isRecurso: boolean = false;

    constructor(private router: Router) {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof ChildActivationEnd) {
                if (event.snapshot.params.proponente !== undefined && event.snapshot.params.codigo !== undefined) {
                    this.proponente = event.snapshot.params.proponente;
                    this.codigo = event.snapshot.params.codigo;
                    this.isRecurso = (event.snapshot.data.siglaForm === 'recurso') ? true : false;
                }
            }
        });
    }

    public handleChange(e: any): void {
        this.index = e.index;
        this.router.navigate(['/inscricao/' + this.proponente + '/' + this.codigo + '/' + this.subMenu[this.index].sg_tipo_formulario]);
    }

    ngDoCheck(): void {
        const arrUrl = this.router.url.split('/');
        const rotaAtual: string = arrUrl[arrUrl.length - 1];
        if (Array.isArray(this.subMenu)) {
            this.subMenu.forEach((formulario: any, index: any) => {
                if (rotaAtual == formulario.sg_tipo_formulario) {
                    this.index = index;
                }
            });
        }
    }
}
