import {BaseResourceModel} from '@sicapes/core';
import {Pais} from '../corporativo/pais.model';
import {ModalidadeBolsa} from '../edital/modalidade-bolsa.model';

export class ItemBolsaModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public ano?: string,
        public mes?: string,
        public quantidade?: number,
        public modalidade_bolsa?: ModalidadeBolsa,
        public pais?: Pais,
        public ultima_alteracao?: string
    ) {
        super();
    }

    static fromJson(jsonData: any): ItemBolsaModel {

        if (jsonData.hasOwnProperty('vinculoModalidadeBolsa')) {
            jsonData.modalidade_bolsa = jsonData.vinculoModalidadeBolsa.modalidadeBolsa;
            delete jsonData.vinculoModalidadeBolsa;
        }

        return Object.assign(new ItemBolsaModel(), jsonData);
    }
}
