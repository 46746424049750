<div class="rodape">
  <div class="container">
    <div class="row">
      <div class="col-md-3">
        <ul class="browsers">
          <li class="chrome ligado"></li>
          <li class="firefox ligado"></li>
          <li class="safari ligado"></li>
          <!--li class="internetExplorer ligado"></li-->
        </ul>
        <span *ngIf="sistema" class="versao">Versão: {{sistema.projeto.version}} </span><br>
        <span class="versao">Dúvidas? ligue 0800 616161</span>
      </div>
      <div class="col-md-4">
        <div class="responsive">
          Interface preparada para responder corretamente a maioria dos dispositivos m&oacute;veis.
        </div>
      </div>
    </div>
  </div>
</div>
