import { Component, Input } from '@angular/core';
import { InformacaoDocumentoModel } from 'src/app/shared/models/inscricao/informacao-documento.model';
import { ParticipanteService } from 'src/app/shared/service/inscricao/participante.service';
import { BaseDashboardComponent } from '../base-dashboard.component';

@Component({
  selector: 'app-dashboard-aba-em-recurso',
  templateUrl: './dashboard-aba-em-recurso.component.html',
  styleUrls: ['./dashboard-aba-em-recurso.component.less']
})
export class DashboardAbaEmRecursoComponent extends BaseDashboardComponent {
  // constructor
  // (){
  //   super()
  // }

  @Input() rows: any;

  public preencherRecurso(inscricao: any, event: MouseEvent) {
    event.preventDefault();
  }

  /**
   * Download dos pareceres emitidos para Reconsideração/Recurso
   *
   * @param {*} inscricao
   * @param {MouseEvent} event
   * @memberof DashboardAbaEmRecursoComponent
   */
  public downlodPareceresRecurso(inscricao: any, event: MouseEvent) {
    event.preventDefault();
    let pareceres: any;
    this.participanteService.recuperarInformacaoDocumento(inscricao.ds_identificador_registrado, inscricao.cd_inscricao).subscribe((documentos: InformacaoDocumentoModel[]) => {
      pareceres = documentos.filter(d => {
        return d.documento_exigido.tipo.id === ParticipanteService.ID_PARECER_ANALISE;
      });

      if (pareceres.length > 0) {
        pareceres.forEach((p: InformacaoDocumentoModel) => {
          this.participanteService.downloadDocumento(inscricao.ds_identificador_registrado, inscricao.cd_inscricao, p.id);
        })
      }
    })
  }

  /**
   * Método para chamada do backend realizar o download do comprovante de inscrição
   *
   * @param {*} inscricao
   * @param {MouseEvent} event
   * @memberof DashboardAbaEmRecursoComponent
   */
  public downlodComprovanteInscricao(inscricao: any, event: MouseEvent) {
    event.preventDefault();
    let comprovanteInscricao: any;
    this.participanteService.recuperarInformacaoDocumento(inscricao.ds_identificador_registrado, inscricao.cd_inscricao).subscribe((documentos: InformacaoDocumentoModel[]) => {
      comprovanteInscricao = documentos.filter(d => {
        return d.documento_exigido.tipo.id === ParticipanteService.ID_COMPROVANTE_INSCRICAO;
      });

      if (comprovanteInscricao.length > 0) {
        this.participanteService.downloadDocumento(inscricao.ds_identificador_registrado, inscricao.cd_inscricao, comprovanteInscricao[0].id);
      }
    })
  }


  public outrosPareceres(inscricao, event: MouseEvent): void {
    event.preventDefault();
    this.participanteService.obterPareceresEmitidos(inscricao.ds_identificador_registrado, inscricao.cd_inscricao).subscribe(
      p => {

      }, err => {
        if (err.status === 404) {
          this.toastr.warning("Não foram disponibilizados pareceres para esse processo.", "Não encontrado (404)");
        }
      })
  }

}
