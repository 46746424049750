import { BaseResourceModel } from '@sicapes/core';

export class ObjetivoModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public tipo?: string,
        public ultima_alteracao?: string
) {
        super();
    }

    static fromJson(jsonData: any): ObjetivoModel {
        return Object.assign(new ObjetivoModel(), jsonData);
    }
}
