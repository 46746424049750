import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService, Usuario} from '@sicapes/auth';


@Component({
    selector: 'app-register',
    template: ''
})
export class RegisterComponent implements OnInit {

    error = '';

    constructor(private router: Router, private route: ActivatedRoute, private authenticationService: AuthService) {
    }

    ngOnInit(): void {

        try {
            // recupera o accesstoken informado na url e armazena no service
            const querystring = this.route.snapshot.fragment;

            if (!querystring) {
                throw new Error('não foi possivel processar o login, dados de autenticação não informados');
            }

            // salva o access token
            const data: any = this.parseQuery(querystring);

            if (!data.access_token) {
                throw new Error('não foi possivel processar o login, dados de autenticação não encontrado');
            }

            this.authenticationService.register(data.access_token)
                .pipe()
                .subscribe(() => {
                        this.router.navigate(['/dashboard']);
                    },
                    error => {
                        this.error = error;
                    }
                );

        } catch (error) {

        }

    }

    /**
     * função para fazer o parse do valor no querystring
     */
    private parseQuery(qstr: any): any {
        const query = {};
        const a = (qstr[0] === '?' ? qstr.substr(1) : qstr).split('&');
        for (let i = 0; i < a.length; i++) {
            const b = a[i].split('=');
            query[decodeURIComponent(b[0])] = decodeURIComponent(b[1] || '');
        }
        return query;
    }

}
