import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environmentHom } from '../../../environments/environment.hom';
import { environmentDes } from '../../../environments/environment.des';
import { environment } from '../../../environments/environment';
import { environmentReview } from 'src/environments/environment.review';
import { Env } from '@sicapes/core';

@Injectable({
    providedIn: 'root',
})
export class InitAppService {

    constructor(private http: HttpClient) {
    }

    initializeApp(): Promise<any> {
        return new Promise((resolve, reject) => {
            let sistema = "sicapes_individual";

            this.http.get('/rest/params-front/' + sistema).subscribe((result: Env) => {

                let env = environment;
                const hostname = window && window.location && window.location.hostname;
                if (/^sicapes-individual-des.apps-dht.capesocp.capes.gov.br/.test(hostname)) {
                    env = environmentDes;
                } else if (/^sicapes-individual-hom.apps-dht.capesocp.capes.gov.br/.test(hostname)) {
                    env = environmentHom;
                } else if (/^(sicapes-individual-)(.*)(feature-\d*-.*)(.apps-dht.capesocp.capes.gov.br)/.test(hostname)) {
                    env = environmentReview;
                }


                environment.SSO_OAUTH2_LOGOUT_ENDPOINT = env.SSO_OAUTH2_LOGOUT_ENDPOINT;
                environment.SSO_OAUTH2_AUTHORIZE_ENDPOINT = env.SSO_OAUTH2_AUTHORIZE_ENDPOINT;
                environment.SSO_OAUTH2_REDIRECT_URI = env.SSO_OAUTH2_REDIRECT_URI;
                environment.SSO_OAUTH2_CLIENT_ID = env.SSO_OAUTH2_CLIENT_ID;
                environment.SSO_OAUTH2_STATE = env.SSO_OAUTH2_STATE;
                environment.SSO_OAUTH2_REALM = env.SSO_OAUTH2_REALM;
                environment.ambiente = env.ambiente;
                environment.SSO_OAUTH2_SCOPE = '';


                if (result !== null && result.itens.length != 0 && env.ambiente == 'review') {
                    let envParamsFront = result.itens;
                    if (envParamsFront.hasOwnProperty(sistema + '_ambiente'))
                        environment.ambiente = envParamsFront[sistema + '_ambiente'];
                    if (envParamsFront.hasOwnProperty(sistema + '_sso_oauth2_redirect_uri'))
                        environment.SSO_OAUTH2_REDIRECT_URI = envParamsFront[sistema + '_sso_oauth2_redirect_uri'];
                    if (envParamsFront.hasOwnProperty(sistema + '_sso_oauth2_logout_endpoint'))
                        environment.SSO_OAUTH2_LOGOUT_ENDPOINT = envParamsFront[sistema + '_sso_oauth2_logout_endpoint'];
                    if (envParamsFront.hasOwnProperty(sistema + '_sso_oauth2_authorize_endpoint'))
                        environment.SSO_OAUTH2_AUTHORIZE_ENDPOINT = envParamsFront[sistema + '_sso_oauth2_authorize_endpoint'];

                }

                resolve(true);
            }, (error) => {
                reject('Não foi possível acessar o REST params-front');
            });
            // reject('Não foi possível acessar o REST params-front');
            //resolve(true);
        });
    }
}
