<p-confirmDialog [style]="{width: '25vw'}"></p-confirmDialog>
    <div class="card card-finalizado">
        <p class="card-header text-success">Finalizadas ({{rows.finalizada?.length}})</p>
        <div class="card-body">
            <div class="card card-finalizado" 
            *ngFor="let inscricaoFinalizada of rows.finalizada; let i = index">                
                <p class="card-header text-success">
                    <em class="{{ inscricaoFinalizada.tp_parametro_edital == 'I' ? 'pi pi-user' : inscricaoFinalizada.tp_parametro_edital == 'P' ? 'pi pi-book' : 'pi pi-money-bill' }}" pTooltip="{{ inscricaoFinalizada.tp_parametro_edital == 'I' ? 'Bolsa Individual' : inscricaoFinalizada.tp_parametro_edital == 'P' ? 'Projeto' : 'Auxílio' }}"></em>
                    {{inscricaoFinalizada.nm_edital}} ({{inscricaoFinalizada.sg_edital}})
                </p>
                <div class="card-body">
                    <div class="row">
                        <div class="col text-left ms-3">
                            <div class="btn-group-vertical w-100" role="group" aria-label="Vertical button group">
                                <a id="cancelar" class="btn btn-default text-danger" (click)="cancelarInscricao(inscricaoFinalizada);">
                                Cancelar Inscrição
                                <i class="glyphicon glyphicon-remove"></i>
                                </a>
                                <a [routerLink]="" class="btn btn-default text-default" (click)="emitirComprovante(inscricaoFinalizada)"><i class="glyphicon glyphicon-save"></i>
                                    Comprovante de Inscrição</a>
                            </div>
                        </div>
                        <div class="col-9">
                            <em class="pi pi-book"></em> Código da Inscrição: <span class="fw-semibold">{{inscricaoFinalizada.cd_inscricao}}</span>
                            <br>
                            <em class="pi pi-calendar"></em> Data Submissão<span class="fw-semibold">{{ formataData(inscricaoFinalizada.dt_operacao) }}</span> horas
                            <br>
                            <em class="pi pi-search"></em> Situação: <span class="fw-semibold">{{inscricaoFinalizada.ds_tipo_situacao_inscricao}}</span>
                            <br>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>