import {BaseResourceModel} from '@sicapes/core';

export class ParametrizacaoAtivaViewModel extends BaseResourceModel {
    constructor(
        public id?: number,
    ) {
        super();
    }

    static fromJson(jsonData: any): ParametrizacaoAtivaViewModel {
        return Object.assign(new ParametrizacaoAtivaViewModel(), jsonData);
    }

}
