import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NavigationComponent } from './navigation/navigation.component';
import {RouterModule} from '@angular/router';
import { SubnavigationComponent } from './subnavigation/subnavigation.component';
import {TabViewModule} from 'primeng/tabview';
import {SidebarModule} from 'primeng/sidebar';
import {SharedModule} from '../shared/shared.module';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {DashboardModule} from '../pages/dashboard/dashboard.module';


@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    NavigationComponent,
    SubnavigationComponent
  ],
    exports: [
        HeaderComponent,
        FooterComponent,
        NavigationComponent,
        SubnavigationComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        TabViewModule,
        SidebarModule,
        SharedModule,
        OverlayPanelModule,
        DashboardModule
    ]
})
export class TemplateModule { }
