<header>

  <p-overlayPanel #op>
    <ng-template pTemplate>
      <app-dashboard-simulator></app-dashboard-simulator>
    </ng-template>
  </p-overlayPanel>

  <div class="container">
    <div class="row">
      <div class="col-md-2 page-logo"></div>

      <div class="col-md-6 page-title">
        <h3>INSCRIÇÕES</h3>
      </div>

      <div class="col-md-4 user-data">
        <ul>
          <li class="dropdown">
            <a href="javascript:void(0);" class="dropdown-toggle text-reset" data-bs-toggle="dropdown" aria-expanded="false"><!--span class="badge">4</span-->
              {{userData.corporativo.nome}}
              &nbsp;
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-circle" viewBox="0 0 16 16">
                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z"/>
                <path fill-rule="evenodd" d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"/>
              </svg>
            </a>
            <ul class="dropdown-menu pull-right" id="menu-user-data">
              <li><a href="javascript:void(0);" (click)="pessoaService.redirectMeusDados()"><em class="bi bi-person-vcard"></em> Meus Dados</a></li>
              <li *ngIf="isGestorInscricao()" ><a href="javascript:void(0);" (click)="op.toggle($event)"><em class="bi bi-exclamation-triangle"></em> Simular Acesso</a></li>
              <li class="divider"></li>
              <li class="text-bg-light"><a href="javascript:void(0);" (click)="logout()"><em class="glyphicon glyphicon-log-out"></em> Sair</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>