import {Injectable, Injector} from '@angular/core';
import {BaseResourceService} from '@sicapes/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {InscricaoCaracteristicaProjetoModel} from '../../../../../shared/models/inscricao/inscricao-caracteristica-projeto.model';
import {InscricaoContextoProjetoModel} from '../../../../../shared/models/inscricao/inscricao-contexto-projeto.model';
import {
    InscricaoContextoProjetoUabModel
} from '../../../../../shared/models/inscricao/inscricao-contexto-projeto-uab.model';
import {
    InscricaoCaracteristicaProjetoParforModel
} from '../../../../../shared/models/inscricao/inscricao-caracteristica-projeto-parfor.model';
import {IdiomaModel} from '../../../../../shared/models/inscricao/idioma.model';
import {InscricaoIdiomaModel} from '../../../../../shared/models/inscricao/inscricao-idioma.model';
import {ItemProjetoModel} from '../../../../../shared/models/inscricao/item-projeto.model';
import {InscricaoEsferaSecretariaModel} from "../../../../../shared/models/inscricao/inscricao-esfera-secretaria.model";
import {SubprojetoModel} from "../../../../../shared/models/inscricao/subprojeto.model";

@Injectable({
    providedIn: 'root'
})
export class CaracteristicaProjetoService extends BaseResourceService<InscricaoCaracteristicaProjetoModel> {

    constructor(protected injector: Injector) {
        super('rest/inscricoes', injector, InscricaoCaracteristicaProjetoModel.fromJson);
    }

    public obterProjeto(proponente: string, codigo: string): Observable<InscricaoCaracteristicaProjetoModel> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/caracteristica-projeto';
        return this.http.get(url).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public salvarProjeto(proponente: string, codigo: string, CaracteristicaProjeto: any): Observable<InscricaoCaracteristicaProjetoModel> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/caracteristica-projeto';
        return this.http.put(url, CaracteristicaProjeto, {observe: 'response'}).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public obterContextoProjeto(proponente: string, codigo: string): Observable<InscricaoContextoProjetoModel> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/contexto-projeto';
        return this.http.get(url).pipe(
            map(this.jsonDataToResourceContextoProjeto.bind(this)),
            catchError(this.handleError)
        );
    }

    public salvarContextoProjeto(proponente: string, codigo: string, contextoProjeto: any): Observable<InscricaoCaracteristicaProjetoModel> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/contexto-projeto';
        return this.http.put(url, contextoProjeto, {observe: 'response'}).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public obterContextoProjetoUab(proponente: string, codigo: string): Observable<InscricaoContextoProjetoUabModel> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/caracteristica-projeto/uab';
        return this.http.get(url).pipe(
            map(this.jsonDataToResourceContextoProjetoUab.bind(this)),
            catchError(this.handleError)
        );
    }

    public salvarContextoProjetoUab(proponente: string, codigo: string, contextoProjetoUab: any): Observable<InscricaoContextoProjetoUabModel> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/caracteristica-projeto/uab';

        if (contextoProjetoUab.id) {
            return this.http.put(url, contextoProjetoUab, {observe: 'response'}).pipe(
                map(this.jsonDataToResourceContextoProjetoUab.bind(this)),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, contextoProjetoUab, {observe: 'response'}).pipe(
            map(this.jsonDataToResourceContextoProjetoUab.bind(this)),
            catchError(this.handleError)
        );
    }

    public obterContextoProjetoParfor(proponente: string, codigo: string): Observable<InscricaoCaracteristicaProjetoParforModel> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/caracteristica-projeto/parfor';
        return this.http.get(url).pipe(
            map(this.jsonDataToResourceContextoProjetoUab.bind(this)),
            catchError(this.handleError)
        );
    }

    public salvarContextoParfor(proponente: string, codigo: string, contextoProjetoParfor: any): Observable<InscricaoCaracteristicaProjetoParforModel> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/caracteristica-projeto/parfor';

        if (contextoProjetoParfor.id) {
            return this.http.put(url, contextoProjetoParfor, {observe: 'response'}).pipe(
                map(this.jsonDataToResourceContextoProjetoUab.bind(this)),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, contextoProjetoParfor, {observe: 'response'}).pipe(
            map(this.jsonDataToResourceContextoProjetoUab.bind(this)),
            catchError(this.handleError)
        );
    }

    public salvarIdiomas(proponente: string, codigo: string, idioma: IdiomaModel): Observable<IdiomaModel> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/caracteristica-idioma';
        return this.http.post(url, {'idioma' : idioma}, {observe: 'response'}).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public obterIdiomas(proponente: string, codigo: string): Observable<InscricaoIdiomaModel[]> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/caracteristica-idioma';
        return this.http.get(url).pipe(map((end: any) => {
            return end._embedded.items.map((r: any) => {
                return Object.assign(new InscricaoIdiomaModel(), r);
            });
        }));
    }

    public excluirIdiomas(proponente: string, codigo: string, id: number): Observable<any> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/caracteristica-idioma';
        return this.http.delete(url + '/' + id, {observe: 'response'}).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    public obterProjetoInstitucional(proponente: string, codigo: string): Observable<InscricaoCaracteristicaProjetoModel> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/caracteristica-projeto/institucional';
        return this.http.get(url).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public salvarProjetoInstitucional(proponente: string, codigo: string, caracteristicaProjetoInternacional: any): Observable<InscricaoCaracteristicaProjetoModel> {
        let url = '/rest/inscricoes/' + proponente + '/' + codigo + '/caracteristica-projeto/institucional';

        if (caracteristicaProjetoInternacional.indicador_institucional.id) {
            return this.http.put(url, caracteristicaProjetoInternacional, {observe: 'response'}).pipe(
                map(this.jsonDataToResource.bind(this)),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, caracteristicaProjetoInternacional, {observe: 'response'}).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public obterItemProjeto(proponente: string, codigo: string): Observable<ItemProjetoModel[]> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/item-projeto';
        return this.http.get(url).pipe(
            map(this.jsonDataToResources.bind(this)),
            catchError(this.handleError)
        );
    }

    public salvarItemProjeto(proponente: string, codigo: string, itemProjeto: any): Observable<ItemProjetoModel> {
        let url = '/rest/inscricoes/' + proponente + '/' + codigo + '/item-projeto';

        if (itemProjeto.id) {
            url = url + '/' + itemProjeto.id;
            return this.http.put(url, itemProjeto, {observe: 'response'}).pipe(
                map(this.jsonDataToResource.bind(this)),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, itemProjeto, {observe: 'response'}).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public excluirItemProjeto(proponente: string, codigo: string, id: number): Observable<any> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/item-projeto';
        return this.http.delete(url + '/' + id, {observe: 'response'}).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    public obterSubprojeto(proponente: string, codigo: string): Observable<SubprojetoModel[]> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/caracteristica-projeto/institucional/subprojetos';
        return this.http.get(url).pipe(
            map(this.jsonDataToResourcesSubprojeto.bind(this)),
            catchError(this.handleError)
        );
    }

    public salvarSubprojeto(proponente: string, codigo: string, subprojeto: any): Observable<SubprojetoModel> {
        let url = '/rest/inscricoes/' + proponente + '/' + codigo + '/caracteristica-projeto/institucional/subprojetos';

        if (subprojeto.id) {
            url = url + '/' + subprojeto.id;
            return this.http.put(url, subprojeto, {observe: 'response'}).pipe(
                map(this.jsonDataToResourceSubprojeto.bind(this)),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, subprojeto, {observe: 'response'}).pipe(
            map(this.jsonDataToResourceSubprojeto.bind(this)),
            catchError(this.handleError)
        );
    }

    public excluirSubprojeto(proponente: string, codigo: string, codigoSubprojeto: number): Observable<any> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/caracteristica-projeto/institucional/subprojetos/' + codigoSubprojeto;
        return this.http.delete(url, {observe: 'response'}).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    public salvarSubprojetoContexto(proponente: string, codigo: string, codigoSubprojeto: number, subprojetoContexto): Observable<SubprojetoModel> {
        let url = '/rest/inscricoes/' + proponente + '/' + codigo + '/caracteristica-projeto/institucional/subprojetos/' + codigoSubprojeto + '/projetos';

        if (subprojetoContexto.id) {
            url = url + '/' + subprojetoContexto.id;
            return this.http.put(url, subprojetoContexto, {observe: 'response'}).pipe(
                map(this.jsonDataToResource.bind(this)),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, subprojetoContexto, {observe: 'response'}).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    protected jsonDataToResourceSubprojeto(jsonData: any): SubprojetoModel {
        return SubprojetoModel.fromJson(jsonData.body);
    }

    protected jsonDataToResourcesSubprojeto(jsonData: any): SubprojetoModel[] {
        const resources: SubprojetoModel[] = [];
        jsonData._embedded.items.forEach((element: any) => resources.push(SubprojetoModel.fromJson(element)));
        return resources;
    }

    protected jsonDataToResourceContextoProjeto(jsonData: any): InscricaoContextoProjetoModel {
        return InscricaoContextoProjetoModel.fromJson(jsonData);
    }

    protected jsonDataToResourceContextoProjetoUab(jsonData: any): InscricaoContextoProjetoUabModel {
        return InscricaoContextoProjetoUabModel.fromJson(jsonData);
    }

    protected jsonDataToResourceContextoProjetoParfor(jsonData: any): InscricaoCaracteristicaProjetoParforModel {
        return InscricaoCaracteristicaProjetoParforModel.fromJson(jsonData);
    }

}
