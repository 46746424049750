import { Component, Input, OnInit } from '@angular/core';
import { ParametroSituacao } from '../../models/parametrizacao/parametro-situacao.model';
import { Situacao } from '../../models/inscricao/situacao.model';
import { ParametroPendencia } from '../../models/parametrizacao/parametro-pendencia.model';
import { ParametroFormulario } from '../../models/parametrizacao/parametro-formulario.model';

@Component({
  selector: 'mensagens-parametrizadas',
  templateUrl: './mensagens-parametrizadas.component.html',
  styleUrls: ['./mensagens-parametrizadas.component.less']
})
export class MensagensParametrizadasComponent implements OnInit {


  /**
   * Situação atual da inscrição
   *
   * @type {Situacao}
   * @memberof MensagensParametrizadasComponent
   */
  @Input() situacao: Situacao;

  /**
   * Situações parametrizadas
   *
   * @type {ParametroSituacao[]}
   * @memberof MensagensParametrizadasComponent
   */
  @Input() parametrosSituacoes: ParametroSituacao[];

  /**
   * Sigla do formulário
   *
   * @type {string}
   * @memberof MensagensParametrizadasComponent
   */
  @Input() formulario: string;

  mensagens: ParametroPendencia[];

  constructor() { }

  ngOnInit(): void {
    this.obterSituacaoAtual();
  }

  private obterSituacaoAtual() {
    const situacaoAtual: ParametroSituacao = this.parametrosSituacoes.filter(ps => ps.tipo_situacao.id === this.situacao.tipo_situacao.id)[0];
    const dadosFormulario: ParametroFormulario = situacaoAtual.parametros_formularios.filter(pf => pf.tipo.sigla === this.formulario)[0]
    this.mensagens = dadosFormulario.pendencias
  }

}
