<ng-container *ngFor="let pendencia of arrPendencia">
    <div class="alert alert-{{pendencia.class_level}} text-justify" role="alert">
    <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
    <p>
      <i class="glyphicon glyphicon-info-sign" *ngIf="pendencia.class_level == 'info'"></i>
      <i class="glyphicon glyphicon-ok-sign" *ngIf="pendencia.class_level == 'success'"></i>
      <i class="glyphicon glyphicon-minus-sign" *ngIf="pendencia.class_level == 'warning'"></i>
      <i class="glyphicon glyphicon-exclamation-sign" *ngIf="pendencia.class_level == 'danger'"></i>
      <span class="alert-text">{{pendencia.descricao}}</span>
    </p>
  </div>
</ng-container>
