<nav class="navbar navbar-expand bg-light" id="navbarFiltroEdital">
  <div class="container-fluid">
      <div class="row">
          <div class="dropdown col">
              <p-dropdown [options]="tiposParametrizacaoAbertas" [(ngModel)]="filter.tipo" (onChange)="filterLista('tipo')" styleClass="form-control" placeholder="Filtrar pelo Tipo de Inscrição" optionLabel="descricao" [showClear]="true"></p-dropdown>
          </div>

          <div class="dropdown col">
              <p-dropdown [options]="modalidadeBolsaAbertas" [(ngModel)]="filter.modalidade" (onChange)="filterLista('modalidade')" styleClass="form-control" placeholder="Filtrar por Modalidade de Bolsa" optionLabel="modalidade" [showClear]="true"></p-dropdown>
          </div>

          <div class="dropdown col">
              <p-dropdown [options]="programaCapesAbertas" [(ngModel)]="filter.programa" (onChange)="filterLista('programa')" styleClass="form-control" placeholder="Filtrar por Programa Capes" optionLabel="nm_programa_capes" [showClear]="true"></p-dropdown>
          </div>
      </div>
  </div>
</nav>

<div class="inner-panel enrollment aba-aberta">
  <div class="row-edt" *ngFor="let parametrizacao of rows; let i = index" [ngClass]="{'item-individual': isParametrizacaoIndividual(parametrizacao.nm_tp_parametro_edital), 'item-projeto': isParametrizacaoProjeto(parametrizacao.nm_tp_parametro_edital), 'item-inscricao': isParametrizacaoPrivada(parametrizacao), 'disabled': isInscricaoEmPreenchimento(parametrizacao) }">
    <div class="card card-1 card-edt {{ parametrizacao.edital_fechado >= '1' && !parametrizacao.dt_inicio_acesso ? 'card-edt-f' : '' }} {{parametrizacao.tp_parametro_edital == 'I' ? 'card-edt-i' : parametrizacao.tp_parametro_edital == 'P' ? 'card-edt-p' : 'card-edt-a' }}" [ngClass]="{ 'text-secondary bg-secondary bg-gradient bg-opacity-25': isInscricaoEmPreenchimento(parametrizacao) }">
      <div class="row">
        <div class="col-10">
          <h3>
            <em *ngIf="parametrizacao.edital_fechado >= '1'" class="pi pi-lock" pTooltip="Acesso restrito." tooltipPosition="top">&nbsp;</em>
            {{ parametrizacao.tp_parametro_edital == 'I' ? 'Bolsa Individual' : parametrizacao.tp_parametro_edital == 'P' ? 'Projeto' : 'Auxílio' }} - {{ parametrizacao.sg_programa_capes }}
            <em *ngIf="isInscricaoEmPreenchimento(parametrizacao)" class="pi pi-exclamation-circle text-danger fw-bolder" style="font-size: 1.5rem" pTooltip="Já exite uma inscrição em preenchimento. Finalize a inscrição ou cancele para iniciar uma nova inscrição." tooltipPosition="top"></em>
          </h3>
          <p class="lb-edital">
            {{ parametrizacao.nm_edital }} ({{ parametrizacao.sg_edital }})
          </p>

          <div class="text-dark" *ngIf="parametrizacao.dt_inicio_acesso">
            <em class="pi pi-calendar text-danger"></em> Período de solicitação de acesso: {{formataData(parametrizacao.dt_inicio_acesso)}} até {{formataData(parametrizacao.dt_fim_acesso)}}
          </div>
          <div class="text-dark">
            <em class="pi pi-calendar"></em> Período de inscrição: {{formataData(parametrizacao.dt_inicio_fase)}} até {{formataData(parametrizacao.dt_fim_fase)}}
          </div>

          <a 
            *ngIf="parametrizacao.ds_url_edital != ''" 
            href="{{ parametrizacao.ds_url_edital }}" 
            target="_blank">
              <em class="pi pi-link"></em> Acesse a página Programa
          </a>
          <p *ngIf="parametrizacao.nr_max_inscricao_permitida > 1">
            Máximo de inscrições permitidas: {{ parametrizacao.nr_max_inscricao_permitida }}
          </p>
        </div>
        
        <div class="col-2 col-btn">
          <!-- <span *ngIf="parametrizacao.sg_edital == 'MOVE-2024'; else elseBlock">
            <button href="javascript:void(0);" (click)="iniciarInscricao(parametrizacao)"  class="inner-panel-toggle btn btn-primary btn-sm" [ngClass]="{'disabled': isInscricaoEmPreenchimento(parametrizacao) }" type="button" class="btn btn-light btn-iniciar-inscricao" alt="Preencher formulários de inscrição" title="Preencher formulários de inscrição">Indicar Vagas<em class="bi bi-arrow-right"></em></button>
          </span>
          <ng-template #elseBlock> -->
            <button *ngIf="permiteAcesso(parametrizacao)" href="javascript:void(0);" (click)="openModalSolicitarAcesso(parametrizacao)" [ngClass]="{'disabled': isInscricaoEmPreenchimento(parametrizacao) }" type="button" class="btn btn-danger btn-solicitar-acesso" pTooltip="Solicitar acesso à inscrição" tooltipPosition="top">Solicitar Acesso <em class="bi bi-lock-fill"></em></button>
            <button *ngIf="permiteIniciarInscricao(parametrizacao) && !isInscricaoEmPreenchimento(parametrizacao)" href="javascript:void(0);" (click)="iniciarInscricao(parametrizacao)" [ngClass]="{'disabled': isInscricaoEmPreenchimento(parametrizacao) }" type="button" class="btn btn-light btn-iniciar-inscricao" alt="Preencher formulários de inscrição" pTooltip="Preencher formulários de inscrição" title="{{isInscricaoEmPreenchimento(parametrizacao) ? 'Já existe uma inscrição em preenchimento. Finalize ou cancele para iniciar uma nova inscrição.' : 'Preencher formulários de inscrição'}}">Iniciar Inscrição <em class="bi bi-arrow-right"></em></button>
          <!-- </ng-template> -->
        </div>

        <div *ngIf="parametrizacao.cs_situacao_acesso == 'P'" class="col alert alert-info" role="alert">
          <p><strong>Acesso solicitado!</strong> Sua solicitação de acesso ao edital está pendende de autorização.</p>
          <p><strong>Data da solicitação:</strong> {{formataData(parametrizacao.dt_solicitacao_acesso)}}</p>
        </div>
        <div *ngIf="parametrizacao.cs_situacao_acesso == 'I'" class="col alert alert-warning" role="alert">
          <p><strong>Sua solicitação de acesso ao edital foi indeferida!</strong> {{parametrizacao.ds_observacao}}</p>
        </div>
        <div *ngIf="parametrizacao.cs_situacao_acesso == 'A' && dataMaiorQueHoje(parametrizacao.dt_inicio_fase)" class="col alert alert-success" role="alert">
          <p><strong>Sua solicitação de acesso ao edital foi deferida!</strong> Aguarde o período de inscrição para iniciar o preenchimento.</p>
        </div>
      </div>
    </div>
  </div>
</div>


<p-dialog header="Solicitar Acesso para Preencher Formulários da Inscrição" [(visible)]="visible" [modal]="true" [style]="{ width: '50vw' }" [draggable]="false" [resizable]="false">
  <form [formGroup]="solicitacaoAcessoForm" class="p-2" (ngSubmit)="onSubmit()">
    <div class="row mb-3 required">
      <label for="inputNome" class="col-sm-3 col-form-label">Nome</label>
      <div class="col-sm-9">
        <input type="text" class="form-control" id="inputNome" aria-label="input nome" formControlName="nome" readonly>
      </div>
    </div>
    <div class="row mb-3 required" [ngClass]="{'has-error': solicitacaoAcessoForm.get('ies').invalid && solicitacaoAcessoForm.get('ies')?.touched}">
      <label for="inputIES" class="col-sm-3 col-form-label">IES de vinculação</label>
      <div class="col-sm-9">
        <p-autoComplete
                  formControlName="ies"
                  placeholder="Informe 4 caracteres para pesquisar a instituição."
                  field="nome"
                  inputStyleClass="form-control text-uppercase"
                  [suggestions]="intituicoesEnsino"
                  (completeMethod)="filterInstituicoesEnsino($event)"
                  [minLength]="4"
                  [style]="{'width':'100%'}"
                  styleClass="myClass"
                  focus="true"
          ></p-autoComplete>
      </div>
    </div>
    <div class="row mb-3 required" >
      <label for="inputFuncao" class="col-sm-3 col-form-label">Função</label>
      <div class="col-sm-9">
        <p-dropdown styleClass="form-control" [showClear]="false" placeholder="Selecione"
                formControlName="funcao" optionLabel="descricao" [options]="tiposFuncaoSolicitarAcesso">
          <ng-template let-funcao pTemplate="item">
              <div class="p-d-flex p-ai-center">
                  <span>{{funcao.descricao}}</span>
              </div>
          </ng-template>
        </p-dropdown>
      </div>
    </div>
    <div class="row mb-3">
      <label for="inputFile" class="col-sm-3 col-form-label">Selecione o arquivo <span class="pi pi-info-circle text-info fw-bolder" style="font-size: 1.5rem" pTooltip="Se necessário, anexe o documento comprobatório da função na Instituição." tooltipPosition="top"></span></label> 
      <div class="col-sm-9">
        <!-- <input class="form-control" type="file" id="formFile"> -->
        <input type="file" class="file-input form-control" formControlName="contentFile" (change)="onFileSelected($event)" #fileUpload />
      </div>
    </div>
    <div class="flex flex-wrap gap-3 align-items-center gap-3 text-center mt-3 mb-3">
      <button pButton pRipple label="Enviar Solicitação de Acesso" [style]="solicitacaoAcessoForm.invalid ? 'cursor: not-allowed;': ''" icon="pi pi-check" class="p-button-lg p-button-raised p-button-info p-2" [disabled]="solicitacaoAcessoForm.invalid" (click)="enviarSolicitacaoAcesso(parametrizacao)"></button>
    </div>
  </form>
</p-dialog>