<div class="form-nav" *ngIf="(menu && codigo && proponente)">
    <div class="container">
        <div class="form-nav-control">
            <span></span>
            <span></span>
            <span></span>
        </div>
        <ul class="clearfix">
            <li>
                <a href="{{dashboardRedirect()}}" title="Retornar ao Painel Principal" data-toggle="tooltip"
                    style="color: yellow" data-placement="bottom">
                    <i class="glyphicon glyphicon-home"></i>
                </a>
            </li>

            <ng-container *ngIf="!isRecurso">
                <li *ngFor="let me of menu; let i = index" ng-class="{'active':item.check_contains_tab(state_name)}"
                    ui-sref="">
                    <a class="btn-link text-decoration-none" ng-class="{'active':item.check_contains_tab(state_name)}"
                        [routerLink]="['/inscricao', proponente, codigo, me.sg_tipo_formulario]">
                        {{me.nm_formulario_pai}}
                    </a>
                </li>
            </ng-container>
        </ul>

    </div>
</div>
