import { Injectable, Injector } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import { BaseResourceService } from '@sicapes/core';
import { AuthService } from '@sicapes/auth';

import { Inscricao } from '../../models/inscricao/inscricao.model';
import { TipoSituacao } from '../../models/inscricao/tipo-situacao.model';
import { Formulario } from '../../models/formulario/formulario.model';

@Injectable({
    providedIn: 'root'
})
export class InscricaoService extends BaseResourceService<Inscricao> {

    constructor(protected injector: Injector, private authService: AuthService) {
        super('rest/inscricoes-lote', injector, Inscricao.fromJson);
        this.authService.currentUser.subscribe((x: any) => this.currentUser = x);
    }

    currentUser: any;

    // DADOS PARA SEREM USADOS DURANTE O PREENCHIMENTO DA INSCRICAO
    public dadosInscricaoAtiva?: DadosInscricaoAtiva;
    protected inscricaoAtiva = new BehaviorSubject<DadosInscricaoAtiva>({});
    public inscricaoAtivabOservable: Observable<DadosInscricaoAtiva> = this.inscricaoAtiva.asObservable();
    protected inscricaoMemory = new BehaviorSubject<Inscricao>(new Inscricao);
    public inscricaoUpdated: Observable<Inscricao> = this.inscricaoMemory.asObservable();


    public updateNumeroProcesso(resource: Inscricao, proponente: string, codigo: string): Observable<Inscricao> {
        const url = 'rest/inscricoes/' + proponente + '/' + codigo;
        return this.http.put(url, resource).pipe(
            map(() => resource),
            catchError(this.handleError)
        );
    }

    public submeterInscricaoSAP(proponente: string, codigo: string): any {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/submeter';
        return this.http.post(url, {});
    }


    public confirmarDadosProponente(proponente: string, codigo: string, formulario: string): any {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/confirmar-dados-proponente/' + formulario;
        return this.http.post(url, {});
    }

    public getExportarInscricoes(params: any): any {
        params.sigla = 'export-basico-gestores';
        return this.http.post('/rest/inscricoes-lote/export', params, { responseType: 'text' });
    }

    public submeterInscricaoSapEmLote(inscricoes: Inscricao[]): any {
        const url = '/rest/inscricoes/analise';
        return this.http.post(url, { inscricoes: inscricoes });
    }

    public getQtdeInscricoesSituacao(): any {
        const url = '/rest/inscricoes/dashboard/' + this.currentUser.id;
        return this.http.get(url);
    }

    public getInscricoesSituacao(situacao: TipoSituacao): any {
        const url = '/rest/inscricoes/' + this.currentUser.id + '/situacao/' + situacao.descricao + '?page_size=-1';
        return this.http.get(url).pipe(
            map(this.jsonDataToResources.bind(this)),
            catchError(this.handleError)
        );
    }

    public iniciarInscricao(parametrizacao: any): any {
        // ajustes variaveis para ficar compativel com o backend
        parametrizacao.sigla_edital = parametrizacao.sg_edital;
        parametrizacao.sigla_modalidade_bolsa = parametrizacao.sg_modalidade_bolsa;

        let url = '/rest/inscricoes/' + this.currentUser.id;
        if (parametrizacao.id) {
            url = '/rest/inscricoes/' + this.currentUser.id + '/' + parametrizacao.id;
            return this.http.put(url, parametrizacao);
        }
        let dados = {sigla_edital: parametrizacao.sg_edital, sigla_modalidade_bolsa: parametrizacao.sg_modalidade_bolsa};
        return this.http.post(url, dados);

    }

    public finalizarInscricao(codigo: string): any {
        const url = '/rest/inscricoes/' + this.currentUser.id + '/' + codigo + '/finalizar';
        return this.http.post(url, []);
    }

    public cancelarInscricao = function (codigo: string): any {
        const url = '/rest/inscricoes/' + this.currentUser.id + '/' + codigo + '/cancelar';
        return this.http.post(url, []).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    };

    public obterInformacoesComplementaresInscricao(codigo: any, siglaEdital: any): any {
        const url = '/rest/inscricoes/' + this.currentUser.id + '/' + codigo + '/' + siglaEdital + '/informacoes-complementares';
        return this.http.get(url);
    }

    public obterFormulariosInscricao(codigo: any): any {
        const url = '/rest/inscricoes/' + this.currentUser.id + '/' + codigo + '/formulario';
        return this.http.get(url);
    }

    public setInscricaoAtiva(propostaAtiva: DadosInscricaoAtiva): void {
        this.inscricaoAtiva.next({ ...propostaAtiva });
        this.dadosInscricaoAtiva = { ...propostaAtiva };
    }
    public setNovaInscricao(inscricao: Inscricao): void {
        if (typeof this.dadosInscricaoAtiva?.i !== 'undefined') {
            this.dadosInscricaoAtiva.i = inscricao;
        }
        this.inscricaoMemory.next(inscricao);
    }
    public possuiInscricao(): boolean {
        if ((typeof this.dadosInscricaoAtiva?.c === 'string' &&
            typeof this.dadosInscricaoAtiva?.p === 'string' &&
            typeof this.dadosInscricaoAtiva?.i === 'undefined')) {

            this.obterInscricaoProponente(
                this.dadosInscricaoAtiva.p,
                this.dadosInscricaoAtiva?.c
            ).subscribe((i: Inscricao) => {
                if (typeof this.dadosInscricaoAtiva !== 'undefined') {
                    this.dadosInscricaoAtiva.i = i;
                    this.inscricaoMemory.next(i);
                }
            });

        }
        return (typeof this.dadosInscricaoAtiva?.c === 'string' &&
            typeof this.dadosInscricaoAtiva?.p === 'string' &&
            typeof this.dadosInscricaoAtiva?.i !== 'undefined');
    }


    public obterInscricaoProponente(proponente: string, codigo: string): any {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo;
        return this.http.get(url).pipe(
            map(this.jsonDataToResource.bind(this)), catchError(this.handleError)
        );
    }


    // Implementação para pegar o dado da inscrição somente uma vez.
    /**
     * Observable para passar a inscrição a frente
     *
     * @type {BehaviorSubject<Inscricao>}
     * @memberof InscricaoService
     */
    public inscricao: BehaviorSubject<Inscricao> = new BehaviorSubject<Inscricao>(new Inscricao);
    /**
     * Cache da inscrição ativa no momento
     *
     * @private
     * @type {(Inscricao | undefined)}
     * @memberof InscricaoService
     */
    private __inscricao: Inscricao | undefined;
    /**
     *
     *
     * @param {string} proponente
     * @param {string} codigo
     * @returns {*}
     * @memberof InscricaoService
     */
    public obterInscricao(proponente: string, codigo: string): any {
        this.inscricao.subscribe((inscricao: Inscricao) => {
            if (!this.cachedInscricaoExists() || (this.cachedInscricaoExists() && !this.cachedInscricaoIsSame(codigo))) {
                this.obterInscricaoProponente(proponente, codigo).subscribe((response: Inscricao) => {
                    // setup da inscricao no service;
                    this.__inscricao = response;
                    // next value da inscrição
                    this.inscricao.next(response);
                }, reject => {

                });
            }
        });
    }
    /**
     *  Verifica se existe Inscricao em cache
     *
     * @private
     * @returns {boolean}
     * @memberof InscricaoService
     */
    private cachedInscricaoExists(): boolean {
        return !(typeof this.__inscricao === "undefined" || (this.__inscricao !== null && typeof this.__inscricao.id === 'undefined'));
    }
    /**
     * Verifica se o código da inscrição fornecido é o mesmo da inscrição em cache (this.__inscricao)
     *
     * @private
     * @param {string} codigo
     * @returns {boolean}
     * @memberof InscricaoService
     */
    private cachedInscricaoIsSame(codigo: string): boolean {
        if (typeof this.__inscricao === 'undefined' || (this.__inscricao !== null && typeof this.__inscricao.id === 'undefined'))
            return false;
        if (codigo === this.__inscricao.codigo)
            return true;
        return false;
    }
    /**
     *
     *
     * @returns {(Inscricao | null)}
     * @memberof InscricaoService
     */
    public getInscricao(): Inscricao | null {
        if (typeof this.__inscricao.id === 'undefined') return null;
        return this.__inscricao;
    }

    public getInscricoesParaRecurso(proponente){
        const url = `rest/inscricoes/${proponente}/obter-recursos-sap`;
        return this.http.get(url);
    }

}
export interface DadosInscricaoAtiva {
    /**
     * Código da inscricao
     *
     * @type {string}
     * @memberof DadosInscricaoAtiva
     */
    c?: string;
    /**
     * CPF do proponente
     *
     * @type {string}
     * @memberof DadosInscricaoAtiva
     */
    p?: string;
    /**
     * Lista de Formularios da inscrição
     *
     * @type {Formulario[]}
     * @memberof DadosInscricaoAtiva
     */
    f?: Formulario[];
    /**
     * Inscrição ativa
     *
     * @type {Inscricao}
     * @memberof DadosInscricaoAtiva
     */
    i?: Inscricao;
}
