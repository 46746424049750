<ng-container *ngFor="let pendencia of arrPendencia">
    <div class="alert alert-{{pendencia.class_level}} text-justify" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
        <p>
            <i class="glyphicon glyphicon-info-sign" *ngIf="pendencia.class_level == 'info'"></i>
            <i class="glyphicon glyphicon-ok-sign" *ngIf="pendencia.class_level == 'success'"></i>
            <i class="glyphicon glyphicon-minus-sign" *ngIf="pendencia.class_level == 'warning'"></i>
            <i class="glyphicon glyphicon-exclamation-sign" *ngIf="pendencia.class_level == 'danger'"></i>
            <span>&nbsp;&nbsp;<span [innerHTML]="pendencia.descricao"></span></span>
        </p>
    </div>
</ng-container>


<ng-container *ngFor="let msg of arrMsg">
    <div class="alert alert-{{msg.nivel_pendencia.descricao}} text-justify" role="alert">
        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
        <span>
            <i *ngIf="msg.nivel_pendencia.descricao == 'info'" class="glyphicon glyphicon-info-sign"></i>
            <i *ngIf="msg.nivel_pendencia.descricao == 'success'" class="glyphicon glyphicon-ok-sign"></i>
            <i *ngIf="msg.nivel_pendencia.descricao == 'warning'" class="glyphicon glyphicon-minus-sign"></i>
            <i  *ngIf="msg.nivel_pendencia.descricao == 'danger'" class="glyphicon glyphicon-exclamation-sign"></i>
            &nbsp;<span [innerHTML]="msg.descricao"></span>
        </span>
    </div>
</ng-container>