export const environment = {
    SSO_OAUTH2_LOGOUT_ENDPOINT: 'http://hom.capes.gov.br/sso/slo?RelayState=https://sicapes-individual-hom.dht.ocp.capes.gov.br',
    SSO_OAUTH2_AUTHORIZE_ENDPOINT: 'http://hom.capes.gov.br/sso/oauth',
    SSO_OAUTH2_REDIRECT_URI: 'https://sicapes-individual-hom.apps-dht.capesocp.capes.gov.br/auth',
    SSO_OAUTH2_REALM: '2',
    SSO_OAUTH2_CLIENT_ID: 'individual.capes.gov.br',
    SSO_OAUTH2_SCOPE: '',
    SSO_OAUTH2_STATE: 'capes_oauth',
    LOG_LEVEL: null,
    URL_API_CSAB: 'https://csab-bolsistas-servicos-hom.apps-dht.capesocp.capes.gov.br/',
    production: false,
    ambiente: 'review'
};
export const environmentReview = environment;