import { Component, Input, OnInit } from '@angular/core';
import { DashboardService } from 'src/app/shared/service/dashboard/dashboard.service';
import { RecursoService } from 'src/app/shared/service/inscricao/recurso.service';


@Component({
  selector: 'dashboard-recurso-modal',
  templateUrl: './dashboard-recurso-modal.component.html',
  styleUrls: ['./dashboard-recurso-modal.component.less'],

})
export class DashboardRecursoModalComponent implements OnInit {

  @Input() inscricao: any = {};
  @Input() trigger: boolean = false;
  @Input() target: boolean;

  public title: string = 'Título do modal';
  public recursos: any[] = [];

  constructor(
    protected recursoService: RecursoService,
    protected dashboardService: DashboardService
  ) {
    this.recursoService = recursoService;
  }

  ngOnInit(): void {
    this.dashboardService.closeModal();
    this.dashboardService.openedModalEvent.subscribe((isOpened) => {
      this.trigger = isOpened;

      this.dashboardService.inscricaoEvent.subscribe(i => {
        this.inscricao = i;

        if (this.trigger && i.cd_inscricao === this.target) {
          this.title = `Recursos ${i.cd_inscricao}`;
          this.recursoService.obterRecursos(this.inscricao.ds_identificador_registrado, this.inscricao.cd_inscricao).subscribe(r => {
            this.recursos = r._embedded.itens;
          })
        }
      })
    })
  }

  public recursoEnviado(recurso) {
    if (recurso.tipo_recurso === 'POR_ETAPA' && recurso.informacoes_analise[0].documentos.filter(d => d.tipo_documento === 'J').length > 0) {
      return true;
    }
    return false;
  }

  public closeModal() {
    this.dashboardService.closeModal();
  }



}
