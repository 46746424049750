import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { InscricaoService } from '../../../shared/service/inscricao/inscricao.service';
import * as _ from 'lodash';
import { TipoSituacao } from '../../../shared/models/inscricao/tipo-situacao.model';
import { AuthService } from '@sicapes/auth';
import { ParametrizacaoService } from '../../../shared/service/parametrizacao/parametrizacao.service';
import { Message } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from 'src/app/shared/service/dashboard/dashboard.service';
@Component({
    selector: 'app-dashboard-painel',
    templateUrl: './dashboard-painel.component.html',
    styleUrls: ['./dashboard-painel.component.css']
})
export class DashboardPainelComponent implements OnInit {


    tipo = '';
    qtdeAberta: number = 0;
    qtdeEmPreenchimento: number = 0;
    qtdeFinalizada: number = 0;
    qtdeCancelada: number = 0;
    qtdeCanceladaExpirado: number = 0;
    qtdeEmRecurso: number = 0;
    qtdeEmAnalise: number = 0;
    qtdeAnalisada: number = 0;
    qtdeRecursoExpirado: number = 0;
    qtdeRecursoEncaminhado: number = 0;
    qtdeMinhasInscricoes: number = 0;
    tabInscricoesAbertas: boolean = true;
    tabMinhasInscricoes: boolean = false;

    parametrizacoes: any = [];
    dashboardMessages: Message[] = [];
    dashboardNavExecution: boolean = false;

    inscricoes: any = {
        emPreenchimento: [],
        finalizada: [],
        emAnalise: [],
        analisada: [],
        cancelada: [],
        canceladaExpirada: [],
        recurso: [],
    };

    arrTipoSituacao: any[] = [];

    cdInscricaoExpandir = '';

    constructor(private authService: AuthService,
        private inscricaoService: InscricaoService,
        private parametrizacaoService: ParametrizacaoService,
        protected toastr: ToastrService,
        protected route: ActivatedRoute,
        protected router: Router,
        private dashboardService: DashboardService,
        private elRef: ElementRef) {
        this.arrTipoSituacao['recurso'] = [{ descricao: 'recurso' }];
        this.arrTipoSituacao['em-preenchimento'] = [{ descricao: 'em-preenchimento' }];
        this.arrTipoSituacao['finalizada'] = [{ descricao: 'finalizada' }];
        this.arrTipoSituacao['em-analise'] = [{ descricao: 'em-analise' }, { descricao: 'analisada' }];
        this.arrTipoSituacao['cancelada'] = [{ descricao: 'cancelada' }, { descricao: 'cancelada-expirada' }];

    }

    ngOnInit(): void {
        this.inscricaoService.getQtdeInscricoesSituacao().subscribe(
            (resources: any) => {
                this.bindDataResourcesQtde(resources);
                this.obterParametrizacoesAtiva();
            },
            (error: any) => this.toastr.error('Não foi possivel recuperar informações das inscrições.')
        );
    }

    public obterParametrizacoesAtiva(): void {
        const proponente = this.authService.getUsuario();
        this.parametrizacaoService.obterParametrizacoesAtiva(proponente.id).subscribe(
            (r: any) => {
                this.parametrizacoes = r;
                this.qtdeAberta = r.length;
            },
            (e: any) => this.toastr.error('Não foi possivel recuperar as parametrizações ativas.')
        );
    }

    public reciverInscricao(cdInscricao: string): void {
        this.cdInscricaoExpandir = cdInscricao;
        this.ngOnInit();
    }

    public handleChange(tipo: any): void {

        this.tipo = tipo;

        if (this.tipo !== '') {

            for (const situacao of this.arrTipoSituacao[tipo]) {

                // se a variavel existir, desnecessario executar novamente o rest
                if (this.inscricoes[_.camelCase(situacao.descricao)] !== undefined && this.inscricoes[_.camelCase(situacao.descricao)].length > 0) {
                    this.inscricoes[_.camelCase(situacao.descricao)] = undefined;
                    return;
                }

                this.getInscricoesPorSituacoes(situacao);

            }
        }

    }

    public getInscricoesPorSituacoes(situacao: TipoSituacao): void {

        this.inscricaoService.getInscricoesSituacao(situacao).subscribe(
            (resources: any) => {
                if (typeof this.inscricoes === 'undefined') {
                    this.inscricoes = {};
                }
                this.inscricoes[_.camelCase(situacao.descricao)] = resources;
            },
            (error: any) => this.toastr.error('Não foi possivel recuperar as situações da inscrição.')
        );
    }

    public bindDataResourcesQtde(resources: any): void {
        const rows: any[] = resources._embedded.items;

        this.qtdeEmPreenchimento = (rows.filter(this.generateSituacaoComparator('Em preenchimento')).length > 0) ? parseInt(rows.filter(this.generateSituacaoComparator('Em preenchimento'))[0].qtde) : 0;
        this.qtdeFinalizada = (rows.filter(this.generateSituacaoComparator('Finalizada')).length > 0) ? parseInt(rows.filter(this.generateSituacaoComparator('Finalizada'))[0].qtde) : 0;
        this.qtdeCancelada = (rows.filter(this.generateSituacaoComparator('Cancelada')).length > 0) ? parseInt(rows.filter(this.generateSituacaoComparator('Cancelada'))[0].qtde) : 0;
        this.qtdeCanceladaExpirado = (rows.filter(this.generateSituacaoComparator('Cancelada - Prazo de Inscrição Expirado')).length > 0) ? parseInt(rows.filter(this.generateSituacaoComparator('Cancelada - Prazo de Inscrição Expirado'))[0].qtde) : 0;
        this.qtdeCancelada = this.qtdeCancelada + this.qtdeCanceladaExpirado;
        this.qtdeEmRecurso = (rows.filter(this.generateSituacaoComparator('Recurso')).length > 0) ? parseInt(rows.filter(this.generateSituacaoComparator('Recurso'))[0].qtde) : 0;
        this.qtdeEmAnalise = (rows.filter(this.generateSituacaoComparator('Em Análise')).length > 0) ? parseInt(rows.filter(this.generateSituacaoComparator('Em Análise'))[0].qtde) : 0;
        this.qtdeRecursoExpirado = (rows.filter(this.generateSituacaoComparator('Recurso - Prazo Expirado')).length > 0) ? parseInt(rows.filter(this.generateSituacaoComparator('Recurso - Prazo Expirado'))[0].qtde) : 0;
        this.qtdeRecursoEncaminhado = (rows.filter(this.generateSituacaoComparator('Recurso - Submetido')).length > 0) ? parseInt(rows.filter(this.generateSituacaoComparator('Recurso - Submetido'))[0].qtde) : 0;
        this.qtdeMinhasInscricoes = this.qtdeEmPreenchimento + this.qtdeFinalizada + this.qtdeCancelada + this.qtdeEmRecurso + this.qtdeEmAnalise + this.qtdeAnalisada;

        // Verifica se alguam inscricao está em recurso ou passou dela
        if (this.qtdeEmAnalise > 0 || this.qtdeEmRecurso > 0) {
            this.getInscricoesEmRecurso();
        }
        // active a aba em preenchimento e executa o rest para trazer as inscriçoes em preenchimento.
        if (this.qtdeEmRecurso > 0) {
            this.getInscricoesPorSituacoes({ descricao: 'recurso' });
            this.dashboardMessages.push({ closable: true, severity: "warn", summary: "RECURSO", detail: "Há Proposta aguardando recurso", styleClass: 'aumenta-fonte' })
        }

        if (this.qtdeEmPreenchimento > 0) {
            this.getInscricoesPorSituacoes({ descricao: 'em-preenchimento' });
        }

        if (this.qtdeRecursoExpirado > 0) {
            this.getInscricoesPorSituacoes({ descricao: 'recurso-prazo-expirado' });
        }
        if (this.qtdeRecursoEncaminhado > 0) {
            this.getInscricoesPorSituacoes({ descricao: 'recurso-submetido' });
        } 

        this.dashboardService.dadosCarregadosEvent.subscribe(e => {
            this.dashboardNavigation();
        })
    }

    private generateSituacaoComparator(situacao: string): any {
        return (inscricao: any) => inscricao.descricao === situacao;
    }
    private getInscricoesEmRecurso(): any {
        let proponente = this.authService.getUsuario()
        this.inscricaoService.getInscricoesParaRecurso(proponente.id).subscribe((resource: any) => {
            if (resource._embedded.items.length > 0) {
                this.ngOnInit();
            }
        }, () => { })
    }
    private dashboardNavigation(): void {
        this.route.params.subscribe(params => {
            if (('aba' in params)) {
                this.tabMinhasInscricoes = (params.aba === 'minhas-inscricoes') ? true : false;
                this.tabInscricoesAbertas = !this.tabMinhasInscricoes;
            }

            if (('situacao' in params)) {
                if (this.inscricoes[_.camelCase(params.situacao)].length == 0 && !this.dashboardNavExecution) {
                    this.getInscricoesPorSituacoes({ descricao: params.situacao });
                    this.dashboardNavExecution = true;
                }

                
                this.elRef.nativeElement.querySelector('a#inscricoes-' + params.situacao).scrollIntoView();
            }
           
        });
    }

}
