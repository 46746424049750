import {Injectable, Injector} from '@angular/core';
import {BaseResourceService} from '@sicapes/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

export class ICsab {
    id?: number;
    podeSolicitarNovacao?: boolean;
    tempoMaximoNovacao?: any;

    static fromJson(jsonData: any): ICsab {
        return Object.assign(new ICsab(), jsonData);
    }
}

@Injectable({
    providedIn: 'root'
})
export class CsabService extends BaseResourceService<ICsab> {

    constructor(protected injector: Injector) {
        super('rest/inscricoes', injector, ICsab.fromJson);
    }

    public obterNovacaoPessoa(idPessoa: number): Observable<ICsab[]> {
        return this.http.get(this.apiPath + '/csab-solicitacao?idPessoa='  + idPessoa, {})
            .pipe(map(this.jsonDataToResources.bind(this)),
            catchError(this.handleError)
        );
    }

    protected jsonDataToResources(jsonData: any): ICsab[] {
        const resources: ICsab[] = [];
        jsonData._embedded.items.forEach(
            (element: any) => {
                resources.push(this.jsonDataToResourceFn(element));
            }
        );
        return resources;
    }
}
