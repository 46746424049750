import {Component, OnInit} from '@angular/core';
import {AuthService} from '@sicapes/auth';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

    sistema: any;

    constructor(protected authService: AuthService) {
    }

    ngOnInit(): void {
        this.authService.getInformacaoSistema().subscribe((r) => {
            this.sistema = r;
        });
    }

}
