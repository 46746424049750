<div class="card card-analise" *ngFor="let inscricao of rows.emAnalise; let i = index">
    <pareceres-emitidos-analise *ngIf="inscricao.cd_inscricao === pareceresAnalise.inscricao"
        [pareceresAnalise]="pareceresAnalise"
        (parecerModalClose)="resetParecerModal()"></pareceres-emitidos-analise>
    <p class="card-header text-purple">
        <em class="{{ inscricao.tp_parametro_edital == 'I' ? 'pi pi-user' : inscricao.tp_parametro_edital == 'P' ? 'pi pi-book' : 'pi pi-money-bill' }}"
            pTooltip="{{ inscricao.tp_parametro_edital == 'I' ? 'Bolsa Individual' : inscricao.tp_parametro_edital == 'P' ? 'Projeto' : 'Auxílio' }}"></em>
        {{inscricao.nm_edital}} ({{inscricao.sg_edital}})
        <a id="inscricoes-em-analise-{{inscricao.cd_inscricao}}"></a>
    </p>
    <div class="card-body">
        <div class="row">
            <div class="col text-left ms-3">
                <a id="inscricoes-em-analise-{{inscricao.cd_inscricao}}"></a>
                <div class="btn-group-vertical w-100" role="group" aria-label="Vertical button group">
                    <a class="btn btn-default text-default" [routerLink]="" (click)="emitirComprovante(inscricao)"><i
                            class="glyphicon glyphicon-save"></i>
                        Comprovante de Inscrição</a>
                    <a class="btn btn-default text-primary" [routerLink]="" (click)="outrosPareceres(inscricao)"><i
                            class="glyphicon glyphicon-save"></i>
                        Pareceres emitidos na Análise</a>
                    <a class="btn btn-default" *ngIf="inscricao.in_possui_recurso" (click)="abrirModal(inscricao)"> <i
                            class="glyphicon glyphicon-file"></i> Histórico de reconsideração</a>
                </div>
            </div>
            <div class="col-lg-9">
                <p><b>Código da inscrição:</b> {{inscricao.cd_inscricao}}</p>
                <p><b>Número do processo:</b> {{inscricao.nr_processo}}</p>
                <p><b>Última atualização:</b> {{ formataData(inscricao.dt_operacao) }}</p>
            </div>
        </div>
    </div>
    <dashboard-analise-modal *ngIf="inscricao.in_possui_recurso" [target]="inscricao.cd_inscricao"
        [trigger]="inscricao.recurso_modal"></dashboard-analise-modal>
</div>