import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { Pessoa } from '../../models/corporativo/pessoa.model';
import { Telefone } from '../../models/corporativo/telefone.model';
import { CorreioEletronico } from '../../models/corporativo/correio-eletronico.model';
import { VinculoEmpregaticioModel } from '../../models/corporativo/vinculo-empregaticio.model';
import { EnderecoModel } from '../../models/corporativo/endereco.model';

@Injectable({
    providedIn: 'root'
})
export class CorporativoService {

    constructor(protected http: HttpClient) { }

    public savePessoa(pessoa: Pessoa): any {

        const url = 'rest/pessoas-fisicas/' + pessoa.codigo;

        if (pessoa.id) {
            return this.http.put(url, pessoa).pipe(map((p: Pessoa) => {
                return p;
            }));
        }

        return this.http.post(url, pessoa).pipe(map((p: Pessoa) => {
            return p;
        }));
    }

    public obterPessoa(cpf: string, proponente: string = null): any {
        let url = 'rest/pessoas-fisicas/' + cpf;
        if (proponente) {
            url = url + '?proponente=' + proponente
        }
        return this.http.get(url).pipe(map((p: Pessoa) => {
            return p;
        }));
    }

    public obterTelefones(cpf: string): any {
        const url = '/rest/pessoas-fisicas/' + cpf + '/telefones';
        return this.http.get(url).pipe(map((e: any) => {
            return e._embedded.items.map((t: any) => {
                t.usar = false;
                t.principal = false;
                return Object.assign(new Telefone(), t);
            });
        }));
    }

    public obterEnderecos(cpf: string): any {
        const url = '/rest/pessoas-fisicas/' + cpf + '/enderecos';
        return this.http.get(url).pipe(map((end: any) => {
            return end._embedded.items.map((e: any) => {
                return Object.assign(new EnderecoModel(), e);
            });
        }));
    }

    public obterCorreiosEletronicos(cpf: string): any {
        const url = '/rest/pessoas-fisicas/' + cpf + '/correios-eletronicos';
        return this.http.get(url).pipe(map((end: any) => {
            return end._embedded.items.map((e: any) => {
                return Object.assign(new CorreioEletronico(), e);
            });
        }));
    }

    public obterVinculosEmpregaticio(cpf: string): any {
        const url = '/rest/pessoas-fisicas/' + cpf + '/vinculos-empregaticios';
        return this.http.get(url).pipe(map((end: any) => {
            return end._embedded.items.map((e: any) => {
                return Object.assign(new VinculoEmpregaticioModel(), e);
            });
        }));
    }

}
