import {Component, Injector} from '@angular/core';
import {BaseResourceFormComponent} from '@sicapes/core';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';

import {ParametrizacaoService} from '../../service/parametrizacao/parametrizacao.service';
import {InscricaoService} from '../../service/inscricao/inscricao.service';
import {InscricaoReferenciaBibliograficaModel} from '../../models/inscricao/inscricao-referencia-bibliografica.model';
import {CaracteristicaInscricaoService} from '../../service/inscricao/caracteristica.inscricao.service';
import {Validators} from '@angular/forms';

@Component({
    selector: 'app-referencia-bibliografica',
    templateUrl: './referencia-bibliografica.component.html',
    styleUrls: ['./referencia-bibliografica.component.less']
})
export class ReferenciaBibliograficaComponent extends BaseResourceFormComponent<InscricaoReferenciaBibliograficaModel> {

    public display = false;
    public tituloModalEndereco: string;
    public referenciasBibliograficas: InscricaoReferenciaBibliograficaModel[] = [];

    public maxCaracteres: any = 1200;

    constructor(protected injector: Injector,
                private toastr: ToastrService,
                public parametrizacaoService: ParametrizacaoService,
                public inscricaoService: InscricaoService,
                public caracteristicaInscricaoService: CaracteristicaInscricaoService,
                protected confirmationService: ConfirmationService) {
        super(injector, parametrizacaoService, inscricaoService);
    }

    protected buildResourceForm(): void {
        this.resourceForm = this.formBuilder.group({
            id: [null],
            descricao: [null, [Validators.required]],
        });
    }

    protected loadFormulario(): void {
        this.initInscricaoReferenciasBibliograficas();
        this.setMaxlength();
    }

    private setMaxlength(): void {
        if( this.parametrizacao.sigla_edital === 'EI-UAB012022' ) {
            this.maxCaracteres = 300;
        }
    }

    public initInscricaoReferenciasBibliograficas(): void {
        this.caracteristicaInscricaoService.obterInscricaoReferenciasBibliograficas(this.proponente, this.codigo).subscribe(
            (irb) => {
                this.referenciasBibliograficas = irb;
            }
        );
    }

    public excluirReferenciaBibliografica(referenciaBibliografica: InscricaoReferenciaBibliograficaModel): void {
        this.confirmationService.confirm({
            message: this.MSG_CONFIRM,
            accept: () => {
                this.caracteristicaInscricaoService.excluirInscricaoReferenciaBibliografica(this.proponente, this.codigo, referenciaBibliografica).subscribe(
                    (r) => {
                        this.toastr.success(this.MSG_DELETE);
                        this.ngOnInit();
                    },
                    err => {
                        this.toastr.error('A Referência Bibliográfica não pode ser removido.');
                        this.ngOnInit();
                    }
                );
            }
        });
    }

    public openModal(referenciaBibliografica: any): void {

        this.resourceForm.reset();
        this.tituloModalEndereco = 'Inclusão de Referência Bibliográfica';

        if (referenciaBibliografica.id) {
            this.tituloModalEndereco = 'Alteração de Referência Bibliográfica';
            this.resourceForm.patchValue(referenciaBibliografica);
        }

        this.display = true;
    }

    public submitForm(): void {
        this.submittingForm = true;

        const obj = this.resourceForm.getRawValue();

        this.caracteristicaInscricaoService.salvarInscricaoReferenciaBibliografica(this.proponente, this.codigo, obj).subscribe(
            (projeto: any) => {
                this.toastr.success(this.MSG_ACTIONS_FOR_SUCCESS);
                this.submittingForm = false;
                this.display = false;
                this.ngOnInit();
            },
            error => {
                this.toastr.error(error.error.detail);
            }
        );
    }

}
