import {BaseResourceModel} from '@sicapes/core';
import {Pessoa} from './pessoa.model';

export class VinculoEmpregaticioModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public empregado?: Pessoa,
        public empregador?: Pessoa,
        public tipo_vinculo?: string,
        public carga_horaria?: string,
        public data_admissao?: string,
        public data_desligamento?: string,
        public profissao?: string,
        public nome_empregador?: string,
        public ppg?: string,
        public tipo_regime_trabalho?: string,
        public servidor_ativo?: string,
        public servidor_publico?: string,
        public esfera_vinculo?: string,
        public inscricaoVinculoEmpregaticio?: any,
        public checkUtilizado?: any
    ) {
        super();
    }

    static fromJson(jsonData: VinculoEmpregaticioModel): VinculoEmpregaticioModel {
        return Object.assign(new VinculoEmpregaticioModel(), jsonData);
    }
}
