import {BaseResourceModel} from '@sicapes/core';

export class Pais extends BaseResourceModel {
    constructor(
        public id?: number,
        public nome?: string,
        public sigla?: string,
    ) {
        super();
    }

    static fromJson(jsonData: Pais): Pais {
        return Object.assign(new Pais(), jsonData);
    }
}
