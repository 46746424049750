import { Injectable, Injector } from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {BaseResourceService} from '@sicapes/core';
import {InstituicaoEnsinoModel} from '../../models/corporativo/instituicao-ensino.model';
import {Pais} from '../../models/corporativo/pais.model';

@Injectable({
  providedIn: 'root'
})
export class InstituicaoEnsinoService extends BaseResourceService<InstituicaoEnsinoModel> {

  constructor(protected injector: Injector) {
    super('rest/instituicoes-ensino', injector, InstituicaoEnsinoModel.fromJson);
  }

  getAllSearchNome(nome: string, tipo: string = 'PAIS', pais: Pais = null): Observable<any[]> {

    let url = this.apiPath + '?nome=' + nome + '&page_size=-1' + '&sigla=' + nome + '&tipo=' + tipo;

    if (pais) { url = url + '&nacionalidade=' + pais.id; }

    return this.http.get(url).pipe(
        map(this.jsonDataToResources.bind(this)),
        catchError(this.handleError)
    );
  }

  getInstituicoesBrasileiras(nome: string): Observable<any[]> {
    let url = this.apiPath + '?nome=' + nome + '&nacionalidade=BRASIL' + '&page_size=-1';

    return this.http.get(url).pipe(
        map(this.jsonDataToResources.bind(this)),
        catchError(this.handleError)
    );
  }
  
}
