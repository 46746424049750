import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BaseResourceModel } from '@sicapes/core';
import { Inscricao } from './inscricao.model';
import { InformacaoAnaliseModel } from './informacao-analise.model';

export class Recurso extends BaseResourceModel {
  constructor(
    public id?: number,
    public inscricao?: Inscricao,
    public documento_justificativa?: any,
    public documento_anexo?: any,
    public tipo_recurso?: string,
    public situacao_analise?: string,
    public dt_consulta_processo?: string,
    public informacoes_analise?: InformacaoAnaliseModel[],
    public ultima_alteracao?: string,
  ) {
    super();
  }

  static fromJson(jsonData: any): Recurso {
    if(jsonData.total_items> 0) {
      jsonData = Object.assign(jsonData._embedded.itens[0]);
    }
    return Object.assign(new Recurso(), jsonData);
  }
}
