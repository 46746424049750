<p-confirmDialog [style]="{width: '25vw'}"></p-confirmDialog>

<div *ngFor="let inscricao of rows.recurso; let i = index" class="dashboard-item recurso">

  <div class="card card-finalizado">
    <p class="card-header"><i class="pi pi-file"></i> {{ inscricao.sg_edital }} - {{ inscricao.nm_edital }}</p>
    <div class="card-body">
      <div class="row">
        <div class="col text-left ms-3">
          <div class="btn-group-vertical w-100" role="group" aria-label="Vertical button group">
            <a class="btn btn-default text-default"
              [routerLink]="['/inscricao',inscricao.ds_identificador_registrado, inscricao.cd_inscricao, 'recurso']"
              (click)="preencherRecurso(inscricao, $event)">
              <i class="glyphicon glyphicon-file"></i> Preencher dados do recurso
            </a>
            <a *ngIf="false" class="btn btn-default text-default" [routerLink]=""
              (click)="downlodPareceresRecurso(inscricao, $event)">
              <i class="glyphicon glyphicon-save"></i> Download dos pareceres
            </a>
            <a *ngIf="false" class="btn btn-default text-default" [routerLink]=""
              (click)="outrosPareceres(inscricao, $event)">
              <i class="glyphicon glyphicon-save"></i> Pareceres emitidos na Análise
            </a>
            <a *ngIf="false" class="btn btn-default text-primary" [routerLink]=""
              (click)="downlodComprovanteInscricao(inscricao, $event)">
              <i class="glyphicon glyphicon-save"></i> comprovante de inscrição
            </a>
          </div>
        </div>
        <div class="col-lg-9">
          <p><b>Processo:</b>&nbsp;{{inscricao.nr_processo}}</p>
          <p><b>Código da inscrição:</b>&nbsp;{{ inscricao.cd_inscricao }}</p>
          <!--p><b>Tipo do recurso:</b>&nbsp;<a>{{ inscricao.tp_recurso_esperado }}</a></p>
          <p><b>Total de pareceres disponíveis:</b>&nbsp;{{inscricao.nr_pareceres}} </p-->
        </div>
      </div>
    </div>
  </div>