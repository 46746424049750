import { BaseResourceModel } from '@sicapes/core';
import {AreaAvaliacaoModel} from '../corporativo/area-avaliacao.model';
import {AreaConhecimentoModel} from '../corporativo/area-conhecimento.model';
import {Inscricao} from './inscricao.model';

export class InscricaoCaracteristicaProjetoModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public titulo?: string,
        public descricao?: string,
        public inicio?: string,
        public termino?: string,
        public area_avaliacao?: AreaAvaliacaoModel,
        public area_conhecimento?: AreaConhecimentoModel,
        public area_tematica?: any,
        public indicador_institucional?: any,
        public parametro_area_tematica?: any,
        public tipo_projeto?: string,
        public inscricao?: Inscricao,
        public ultima_alteracao?: string
) {
        super();
    }

    static fromJson(jsonData: any): InscricaoCaracteristicaProjetoModel {
        return Object.assign(new InscricaoCaracteristicaProjetoModel(), jsonData);
    }
}
