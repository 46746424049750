import {Component, EventEmitter, Injector, OnInit, Output} from '@angular/core';
import {Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {BaseResourceFormComponent} from '@sicapes/core';
import {VinculoEmpregaticioModel} from '../../../models/corporativo/vinculo-empregaticio.model';
import {InscricaoService} from '../../../service/inscricao/inscricao.service';
import {ParametrizacaoService} from '../../../service/parametrizacao/parametrizacao.service';
import {PessoaService} from '../../../service/corporativo/pessoa.service';

@Component({
    selector: 'app-inscricao-cadastrar-dados-pessoa-juridica-form-modal',
    templateUrl: './inscricao-cadastrar-dados-pessoa-juridica-form-modal.component.html',
    styleUrls: ['./inscricao-cadastrar-dados-pessoa-juridica-form-modal.component.less']
})
export class InscricaoCadastrarDadosPessoaJuridicaFormModalComponent extends BaseResourceFormComponent<VinculoEmpregaticioModel> implements OnInit {

    @Output() eventPai = new EventEmitter();
    display = false;
    tituloModal: string;
    pessoaJuridica: any;

    finalidadesEndereco = [{
        codigoFinalidadeEndereco: 'CT',
        descricaoFinalidadeEndereco: 'Contato',
        id: 5
    }];

    paises = [];
    municipios = [];
    ufs = [];

    constructor(protected injector: Injector,
                public inscricaoService: InscricaoService,
                public parametrizacaoService: ParametrizacaoService,
                private toastr: ToastrService,
                private pessoaService: PessoaService) {
        // @ts-ignore
        super(injector, parametrizacaoService, inscricaoService);
    }

    ngOnInit(): void {
        this.buildResourceForm();
    }

    protected loadFormulario(): void {}

    protected buildResourceForm(): void {
        this.resourceForm = this.formBuilder.group({
            id: [null],
            cnpj: [null, [Validators.required]],
            razaoSocial: [{value: null, disabled: true}, [Validators.required]],
            nomeFantasia: [{value: null, disabled: false}],
            site: [{value: null, disabled: false}],
            endereco: this.formBuilder.group({
                id: [null],
                finalidadeEndereco: [{value: null, disabled: true}, [Validators.required]],
                cep: [{value: null, disabled: true}, [Validators.required]],
                logradouro: [{value: null, disabled: true}, [Validators.required]],
                complemento: [{value: null, disabled: true}, [Validators.required]],
                numero: [{value: null, disabled: true}, [Validators.required]],
                bairro: [{value: null, disabled: true}, [Validators.required]],
                pais: [{value: null, disabled: true}, [Validators.required]],
                uf: [{value: null, disabled: true}, [Validators.required]],
                municipio: [{value: null, disabled: true}, [Validators.required]],
            })
        });
    }

    public carregarPessoaJuridica(objForm): void {

        const cnpj: string =  objForm.cnpj.replace(/[^0-9]/g, '');

        if (cnpj && cnpj.length === 14) {
            this.resourceForm.reset();
            this.pessoaService.obterDadosPessoaJuridicaRF(this.proponente, cnpj).subscribe((p: any) =>
                {
                    if ( p.id ) {
                        this.toastr.warning('Pessoa Jurídica já cadastrada na CAPES. (' + p.nome + ').');
                        return;
                    }
                    const configuracao = { convert_dates: true };
                    this.pessoaJuridica = this.pessoaService.adaptPessoaRFPost(p, configuracao);
                    this.resourceForm.patchValue(this.adapterJsonToForm(p));

                },
                error => this.toastr.error(error.error.mensagem)
            );
            return;
        }

    }

    public openModalForm(row: any): void {
        this.resourceForm.reset();
        this.tituloModal = 'Cadastrar Nova Instituição Promotora';
        this.display = true;
    }

    private adapterJsonToForm(objForm: any): any {
        const objHydrate: any = JSON.parse(JSON.stringify(objForm));
        objHydrate.cnpj = objForm.identificadorRegistrado;
        objHydrate.razaoSocial = objForm.nome;

        this.paises.push(objForm.enderecos[0].pais);
        this.municipios.push(objForm.enderecos[0].municipio);
        this.ufs.push(objForm.enderecos[0].municipio.uf);

        objHydrate.endereco = objForm.enderecos[0];
        objHydrate.endereco.finalidadeEndereco = this.finalidadesEndereco[0];
        objHydrate.endereco.uf = objForm.enderecos[0].municipio.uf;

        return objHydrate;
    }

    private adapterJsonToBack(objForm: any): any {
        const objHydrate: any = JSON.parse(JSON.stringify(objForm));
        this.pessoaJuridica.statusJuridico = 'NENHUM';

        this.pessoaJuridica.nomeFantasia = objHydrate.nomeFantasia;
        this.pessoaJuridica.cnpj = this.pessoaJuridica.identificadorRegistrado;
        if (this.resourceForm.get('site').value) {
            const url = {
                id: null,
                url: this.resourceForm.get('site').value,
                dataUltimaAlteracao: null,
                usuarioUltimaAlteracao: null,
                finalidadePrincipal: true,
                finalidadeEndereco: {
                    id: 5,
                    codigoFinalidadeEndereco: 'CT',
                    descricaoFinalidadeEndereco: 'Contato'
                }
            };
            this.pessoaJuridica.urls = [url];
        }


        return objHydrate;
    }

    public submitForm(): void {
        this.submittingForm = true;
        this.adapterJsonToBack(this.resourceForm.getRawValue());
        this.pessoaService.salvarDadosPessoaJuridicaRF(this.proponente, this.pessoaJuridica).subscribe(
            (p: any) => {
                this.toastr.success(this.MSG_ACTIONS_FOR_SUCCESS);
                this.submittingForm = false;
                this.display = false;
                this.eventPai.emit();
            },
            error => {
                this.toastr.error(error.error.detail);
                this.submittingForm = false;
            }
        );

    }

}
