import { BaseResourceModel } from '@sicapes/core';

export class InscricaoContextoProjetoUabModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public alunos_professores?: string,
        public link_ferramenta?: string,
        public motivacao_ferramenta?: string,
        public ultima_alteracao?: string
) {
        super();
    }

    static fromJson(jsonData: any): InscricaoContextoProjetoUabModel {
        return Object.assign(new InscricaoContextoProjetoUabModel(), jsonData);
    }
}
