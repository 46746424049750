<p-dialog [(visible)]="display" [modal]="true" [style]="{width: '35vw'}" [maximizable]="true">
    <div class="modal-body">
        <fieldset>
            <legend>{{tituloModal}}</legend>

            <form [formGroup]="resourceForm">

                <div class="row">
                    <div class="col-md-12 form-group required">
                        <label for="objetivo">Objetivos <span class="badge">{{objetivo.value.length||0}} / 1000</span></label>
                        <textarea class="form-control" [maxlength]="1000" rows="5" name="objetivo" id="objetivo" formControlName="objetivo" #objetivo></textarea>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 form-group required">
                        <label for="objetivo">Metas <span class="badge">{{meta.value.length||0}} / 1000</span></label>
                        <textarea class="form-control" [maxlength]="1000" rows="5" name="meta" id="meta" formControlName="meta" #meta></textarea>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 form-group required">
                        <label for="objetivo">Indicadores <span class="badge">{{indicador.value.length||0}} / 1000</span></label>
                        <textarea class="form-control" [maxlength]="1000" rows="5" name="indicador" id="indicador" formControlName="indicador" #indicador></textarea>
                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-primary" value="Salvar" title="Salvar" [disabled]="submittingForm || resourceForm.invalid" (click)="submitForm()">
                        <span><i class="glyphicon glyphicon-floppy-disk"></i> Salvar</span>
                    </button>
                    <button type="button" class="btn btn-danger" value="Cancelar" title="Cancelar" (click)="display=false">
                        <span><i class="glyphicon glyphicon-alert"></i> Cancelar</span>
                    </button>
                </div>

            </form>
        </fieldset>
    </div>

</p-dialog>
