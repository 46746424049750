import { BaseResourceModel } from '@sicapes/core';
export class SubprojetoModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public componentes_curriculares_subprojetos?: any[],
        public cursos?: any[],
        public etapas?: any[],
        public modalidades?: any[],
        public tematicas?: any[],
        public projetos?: any[],
        public ultima_alteracao?: string
) {
        super();
    }

    static fromJson(jsonData: any): SubprojetoModel {
        return Object.assign(new SubprojetoModel(), jsonData);
    }
}
