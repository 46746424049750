import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-form-field-error',
    template: `
        <p class="text-danger">
            {{errorMessage}}
        </p>
    `,
    styleUrls: ['./form-field-error.component.css']
})
export class FormFieldErrorComponent implements OnInit {

    @Input('form-control') formControl: any;

    constructor() { }

    ngOnInit(): void {
    }

    public get errorMessage(): string | null {
        if ( this.mustShowErrorMessage() ) {
            return this.getErrorMessage();
        } else {
            return null;
        }
    }


    private mustShowErrorMessage(): boolean {
        return this.formControl.invalid && this.formControl.touched;
    }

    private getErrorMessage(): any {

        if ( this.formControl.errors.required ) {
            return 'Informação obrigatória';
        }
        else if ( this.formControl.errors.email) {
            return 'Formato de email inválido';
        }
        else if ( this.formControl.errors.minlength){
            const requiredLength = this.formControl.errors.minlength.requiredLength;
            return `Deve ter no mínimo ${requiredLength} caracteres`;
        }

        else if( this.formControl.errors.maxlength){
            const requiredLength = this.formControl.errors.maxlength.requiredLength;
            return `Deve ter no máximo ${requiredLength} caracteres`;
        }
    }
}
