import { BaseResourceModel } from '@sicapes/core';
import {TipoItemCronograma} from './tipo-item-cronograma.model';

export class InscricaoObjetivoResultadoModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public tipo?: TipoItemCronograma,
        public ultima_alteracao?: string
) {
        super();
    }

    static fromJson(jsonData: any): InscricaoObjetivoResultadoModel {
        return Object.assign(new InscricaoObjetivoResultadoModel(), jsonData);
    }
}
