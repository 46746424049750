import { Injectable, Injector } from '@angular/core';
import { Participante } from '../../models/inscricao/participante.model';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BaseResourceService } from '@sicapes/core';
import { InscricaoEndereco } from '../../../pages/formularios/inscricao-endereco/shared/models/inscricao-endereco.model';
import { InformacaoDocumentoModel } from '../../models/inscricao/informacao-documento.model';
import { NucleoPpgModel } from '../../models/curso/nucleo-ppg.model';
import { InscricaoPessoaPpgModel } from '../../models/inscricao/inscricao-pessoa-ppg.model';
import { TipoInscricaoPessoa } from '../../models/inscricao/tipo-inscricao-pessoa.model';
import { Parametrizacao } from '../../models/parametrizacao/parametrizacao.model';
import {
    InscricaoContextoEstudoPretendidoModel
} from "../../models/inscricao/inscricao-contexto-estudo-pretendido.model";

class IParticipante {
    id?: number;
}

@Injectable({
    providedIn: 'root'
})
export class ParticipanteService extends BaseResourceService<Participante> {

    static readonly ID_TIPO_COORDENADOR_PRINCIPAL = 21;
    static readonly ID_TIPO_COORDENADOR_PRINCIPAL_ESTRANGEIRO = 61;
    static readonly ID_TIPO_COORDENADOR = 3;
    static readonly ID_TIPO_COORDENADOR_ESTRANGEIRO = 62;
    static readonly ID_TIPO_PESQUISADOR = 41;
    static readonly ID_TIPO_IES = 11;
    static readonly ID_TIPO_PARTICIPANTE_PROJETO = 12;
    static readonly ID_TIPO_COORDENADOR_PROJETO = 4;
    static readonly ID_TIPO_DISCENTE = 6;
    static readonly ID_TIPO_DOCENTE = 7;
    static readonly ID_TIPO_ORGANIZADOR = 63;
    static readonly ID_TIPO_PALESTRANTE = 64;
    static readonly ID_TIPO_PARTICIPANTE_GERAL = 65;
    static readonly ID_TIPO_TECNICO = 66;
    static readonly ID_TIPO_AUXILIAR = 67;
    static readonly ID_TIPO_ANALISTA = 68;
    static readonly ID_TIPO_COORDENADOR_UAB_ADJUNTO = 69;
    static readonly ID_TIPO_COORDENADOR_UAB = 70;
    static readonly ID_TIPO_UNIDADE_EMBRAPII = 74;
    static readonly ID_TIPO_COORDENADOR_CURSO = 80;
    static readonly ID_TIPO_ASSISTENTE_PROJETO = 147;
    static readonly ID_TIPO_RESPONSAVEL_FINANCEIRO = 148;

    static readonly ID_TIPO_VINCULO_SERVIDOR_DOCENTE = 1;
    static readonly ID_TIPO_VINCULO_DISCENTE = 2;
    static readonly ID_TIPO_VINCULO_TERCEIRIZADO = 3;
    static readonly ID_TIPO_VINCULO_CONSULTOR = 4;
    static readonly ID_TIPO_VINCULO_SERVIDOR = 5;

    static readonly ID_TIPO_COORDENADOR_INSTITUCIONAL = 2;
    static readonly ID_TIPO_COORDENADOR_ASSOCIADO = 121;
    static readonly ID_TIPO_COORDENADOR_ADJUNTO = 122;
    static readonly ID_TIPO_IES_PRINCIPAL_BRASIL = 78;
    static readonly ID_TIPO_IES_ASSOCIADA_BRASIL = 76;
    static readonly ID_TIPO_IES_PRINCIPAL_EXTERIOR = 79;
    static readonly ID_TIPO_IES_ASSOCIADA_EXTERIOR = 77;
    static readonly ID_TIPO_INSTITUICAO_PARCEIRA = 123;
    static readonly ID_TIPO_SECRETARIA_EDUCACAO = 125;
    static readonly ID_TIPO_UNIDADE_ESCOLAR = 132;
    static readonly ID_TIPO_INSTITUICAO_ENSINO_APOIO_FINANCEIRO = 133;
    static readonly ID_TIPO_INSTITUICAO_ENSINO_APOIO_TECNICO_CIENTIFICO = 134;
    static readonly ID_TIPO_INSTITUICAO_ENSINO_APOIO_FINANCEIRO_APOIO_TECNICO_CIENTIFICO = 135;
    static readonly ID_TIPO_INSTITUICAO_PARCEIRA_APOIO_FINANCEIRO = 136;
    static readonly ID_TIPO_INSTITUICAO_PARCEIRA_APOIO_TECNICO_CIENTIFICO = 137;
    static readonly ID_TIPO_INSTITUICAO_PARCEIRA_APOIO_FINANCEIRO_APOIO_TECNICO_CIENTIFICO = 138;

    static readonly ID_TIPO_PROPONENTE = 13;

    static readonly ID_COMPROVANTE_INSCRICAO = 555;
    static readonly ID_PARECER_ANALISE = 171;

    constructor(protected injector: Injector) {
        super('rest/inscricoes', injector, Participante.fromJson);
    }

    public salvarParticipante(proponente: string, codigo: string, participante: any): Observable<Participante> {

        let url = '/rest/inscricoes/' + proponente + '/' + codigo + '/participantes';

        if (participante.id) {
            url += '/' + participante.id;
            return this.http.put(url, participante, { observe: 'response' }).pipe(
                map(() => participante),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, participante, { observe: 'response' }).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );

    }

    public salvarParticipanteEvento(proponente: string, codigo: string, participante: Participante): Observable<Participante> {

        let url = '/rest/inscricoes/' + proponente + '/' + codigo + '/participantes/' + participante.id + '/cargos-evento';

        if (participante.participantes_evento.length > 0 && participante.participantes_evento[0].id) {
            url += '/' + participante.participantes_evento[0].id;
            return this.http.put(url, participante, { observe: 'response' }).pipe(
                map(() => participante),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, participante, { observe: 'response' }).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );

    }

    public salvarVinculoPPGParticipante(proponente: string, codigo: string, objParticipante: Participante, idInscricaoPessoaPPG: number, nucleoPPG: NucleoPpgModel, isPrincipal: any): Observable<NucleoPpgModel> {

        let url = '/rest/inscricoes/' + proponente + '/' + codigo + '/participantes/' + objParticipante.id + '/informacoes-ppgs';
        let boolPrincipal = false;
        if (typeof isPrincipal !== 'undefined') {
            boolPrincipal = isPrincipal;
        }
        const postData = { participante: objParticipante, nucleo_ppg: nucleoPPG, principal: boolPrincipal };

        if (idInscricaoPessoaPPG) {
            url += '/' + idInscricaoPessoaPPG;
            return this.http.put(url, postData, { observe: 'response' }).pipe(
                map(this.jsonDataToResource.bind(this)),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, postData, { observe: 'response' }).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public excluirParticipante(proponente: string, codigo: string, participante: Participante): Observable<any> {

        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/participantes/' + participante.id;

        return this.http.delete(url, { observe: 'response' }).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    public obterParticipantes(proponente: string, codigo: string): Observable<Participante[]> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/participantes';
        return this.http.get(url).pipe(map((end: any) => {
            return end._embedded.items.map((r: any) => {
                return Object.assign(new Participante(), r);
            });
        }));
    }

    public obterInformacoesParticipanteInscricao(proponente: string, codigo: string, idParticipante: number, contexto: string): Observable<any[]> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/participantes/' + idParticipante + '/' + contexto;
        return this.http.get(url).pipe(map((end: any) => {
            return end._embedded.items.map((r: any) => {
                return Object.assign(new Object(), r);
            });
        }));
    }

    public excluirInformacaoParticipante(proponente: string, codigo: string, idParticipante: number, idContexto: number, contexto: string): Observable<any> {

        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/participantes/'
            + idParticipante + '/' + contexto + '/' + idContexto;

        return this.http.delete(url, { observe: 'response' }).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    public salvarInformacaoParticipante(proponente: string, codigo: string, idParticipante: number, inscricaoInformacao: any, contexto: string): Observable<any> {

        let url = '/rest/inscricoes/' + proponente + '/' + codigo + '/participantes/'
            + idParticipante + '/' + contexto;

        if (inscricaoInformacao.id) {
            url += '/' + inscricaoInformacao.id;
            return this.http.put(url, inscricaoInformacao, { observe: 'response' }).pipe(
                map(this.jsonDataToResource.bind(this)),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, inscricaoInformacao, { observe: 'response' }).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public removerDocumento(proponente: string, codigo: string, codigoDocumento: number): Observable<any> {
        const url = this.apiPath = 'rest/inscricoes/' + proponente + '/' + codigo
            + '/participantes/proponente/informacoes-documentos/' + codigoDocumento;
        return this.http.delete(url, { observe: 'response' });
    }

    public verDocumento(proponente: string, codigo: string, codigoDocumento: number): void {
        window.open('rest/inscricoes/' + proponente + '/' + codigo + '/participantes/' +
            'proponente' + '/informacoes-documentos/' + codigoDocumento + '/download', '_blank');
    }

    public recuperarInformacaoDocumento(proponente: string, codigo: string): Observable<any> {
        const url = this.apiPath = 'rest/inscricoes/' + proponente + '/' + codigo + '/participantes/proponente/informacoes-documentos';
        return this.http.get(this.apiPath).pipe(
            map((result: any) => {
                return result._embedded.items.map((informacaoDocumento: InformacaoDocumentoModel) => {
                    return Object.assign(new InformacaoDocumentoModel(), informacaoDocumento);
                });
            }),
            catchError(this.handleError)
        );
    }
    public downloadDocumento(proponente: string, codigo: string, idinscricaoDocumento: number): void {
        const url = this.apiPath = 'rest/inscricoes/' + proponente + '/' + codigo + '/participantes/proponente/informacoes-documentos/' + idinscricaoDocumento + "/download";
        window.open(this.apiPath)
    }

    public obterPareceresEmitidos(proponente: string, codigo: string): Observable<any> {
        const url = this.apiPath = 'rest/inscricoes/' + proponente + '/' + codigo + '/pareceres-emitidos';
        return this.http.get(this.apiPath);
    }

    public downloadParecerAnalise(proponente: string, codigo: string, codigoDocumento: number): void {
        window.open('rest/inscricoes/' + proponente + '/' + codigo + '/pareceres-emitidos/' + codigoDocumento, '_blank');
    }

    public salvarEstudoPretendido(proponente: string, codigo: string, inscricaoInformacao: any, contexto: string): Observable<InscricaoEndereco> {

        let url = '/rest/inscricoes/' + proponente + '/' + codigo + '/participantes/proponente/' + contexto;

        if (inscricaoInformacao.id) {
            url += '/' + inscricaoInformacao.id;
            return this.http.put(url, inscricaoInformacao, { observe: 'response' }).pipe(
                map(this.jsonDataToResource.bind(this)),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, inscricaoInformacao, { observe: 'response' }).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    // @TODO: É necessário arrumar o resource InformacaoContextoEstudoPretendidoResource estar passando o ID coom o CD_INSCRICAO
    // @TODO: não é necessário pq já enviamos via parameter router
    public salvarContextoEstudoPretendido(proponente: string, codigo: string, contexto): Observable<InscricaoContextoEstudoPretendidoModel> {

        let url = '/rest/inscricoes/' + proponente + '/' + codigo + '/contexto-estudo-pretendido';

        if (contexto.id) {
            url += '/' + codigo;
            return this.http.put(url, contexto, { observe: 'response' }).pipe(
                map(this.jsonDataToResource.bind(this)),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, contexto, { observe: 'response' }).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public obterContextoEstudoPretendido(proponente: string, codigo: string): Observable<any[]> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/contexto-estudo-pretendido/' + proponente;
        return this.http.get(url).pipe(map((end: any) => {
            return Object.assign(new Object(), end);
        }));
    }

    public listaTipoIes(tiposParticipante: any[]): any {

        if (typeof tiposParticipante === undefined) {
            return [TipoInscricaoPessoa.ID_TIPO_IES_BRASILEIRA_PRINCIPAL,
            TipoInscricaoPessoa.ID_TIPO_IES_BRASILEIRA_ASSOCIADA,
            TipoInscricaoPessoa.ID_TIPO_IES_EXTERIOR_PRINCIPAL,
            TipoInscricaoPessoa.ID_TIPO_IES_EXTERIOR_ASSOCIADA,
            TipoInscricaoPessoa.ID_TIPO_INSTITUICAO_PARCEIRA];
        }

        if (typeof tiposParticipante !== undefined) {
            const tipos: any[] = [];
            tiposParticipante.forEach(element => {
                tipos.push(element.id);
            });
            return tipos;
        }
    }

    public obterListaTiposParticipantesIes(parametrizacao: Parametrizacao): any[] {

        if (typeof parametrizacao === 'undefined' || typeof parametrizacao.parametros_tipos_participante === 'undefined') { return []; }

        const parametroParticipante = parametrizacao.parametros_tipos_participante.filter((a) => {
            return a.tipo === 'B' || a.tipo === 'E';
        });

        const tiposIes = [];
        parametroParticipante.forEach(element => {
            tiposIes.push(element.tipo_participante.id);
        });

        return tiposIes;
    }

    public obterListaParametroParticipantes(parametrizacao: Parametrizacao, tipos: any[]): any[] {

        if (typeof parametrizacao === 'undefined' || typeof parametrizacao.parametros_tipos_participante === 'undefined') { return []; }

        return parametrizacao.parametros_tipos_participante.filter((a) => {
            return (tipos.indexOf(a.tipo) > -1);
        });
    }
}
