import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-dashboard-aba-em-preenchimento-search',
    templateUrl: './dashboard-aba-em-preenchimento-search.component.html',
    styleUrls: ['./dashboard-aba-em-preenchimento-search.component.css']
})
export class DashboardAbaEmPreenchimentoSearchComponent implements OnInit {

    @Input() rows: any;

    cloneRows: any;
    modalidadeBolsa: any;
    programaCapes: any;
    tiposParametrizacao: any[] = [
        {descricao: 'Auxílio', tipo: 'A'},
        {descricao: 'Bolsa', tipo: 'I'},
        {descricao: 'Projeto', tipo: 'P'},
    ];

    ngOnInit(): void {
        this.cloneRows = this.rows.emPreenchimento;
        this.obterListaProgramas();
        this.obterModalidadeBolsa();
    }

    public obterListaProgramas(): void {
        this.programaCapes = this.rows.emPreenchimento.map(function (inscricao: any) {
            return {id_programa_capes: inscricao.id_programa_capes, nm_programa_capes: inscricao.nm_programa_capes};
        });
    }

    public obterModalidadeBolsa(): any {
        // Remove os elementos nulos e cria o array apenas com as modalidades bolsa
        const arrModalidadeBolsa = this.rows.emPreenchimento.filter(function (inscricao: any) {
            return inscricao.ds_modalidade_bolsa !== null;
        }).map(function (inscricao: any) {
            return inscricao.ds_modalidade_bolsa;
        });

        // Verifica se alguma inscricao possui mais de uma modalidade, caso positivo, adiciona todas no array
        arrModalidadeBolsa.forEach(function (element: any, i: number) {
            if (element.indexOf(',') !== -1) {
                const arrTemp = element.split(',');
                arrTemp.forEach(function (elementIntern: any) {
                    arrModalidadeBolsa.push(elementIntern);
                });
                arrModalidadeBolsa.splice(i, 1);
            }
        });

        // Remove os elementos repetidos e popula o array do filtro
        this.modalidadeBolsa = [...new Set(arrModalidadeBolsa.sort())].map(function (modalidadeBolsa: any) {
            return {ds_modalidade_bolsa: modalidadeBolsa};
        });
    }

    public alterarBusca(e: any, tipoPesquisa: string): any {
        this.rows.emPreenchimento = this.cloneRows;
        const selectTipo = document.getElementById('tipo') as HTMLInputElement;
        const selectModalidade = document.getElementById('modalidade_bolsa') as HTMLInputElement;
        const selectPrograma = document.getElementById('programa_capes') as HTMLInputElement;
        if (tipoPesquisa === 'TIPO_PARAM_INSCRICAO' && e.target.value !== '') {
            this.rows.emPreenchimento = this.rows.emPreenchimento.filter(function (inscricao: any) {
                return inscricao.tp_parametro_edital === e.target.value;
            });
            selectModalidade.value = '';
            selectPrograma.value = '';
        }
        if (tipoPesquisa === 'MODALIDADE' && e.target.value !== '') {
            this.rows.emPreenchimento = this.rows.emPreenchimento.filter(function (inscricao: any) {
                if (inscricao.ds_modalidade_bolsa !== null && inscricao.ds_modalidade_bolsa.indexOf(',') !== -1) {
                    return inscricao.ds_modalidade_bolsa.indexOf(e.target.value) !== -1;
                } else {
                    return inscricao.ds_modalidade_bolsa === e.target.value;
                }
            });
            selectTipo.value = '';
            selectPrograma.value = '';
        }
        if (tipoPesquisa === 'PROGRAMA' && e.target.value !== '') {
            this.rows.emPreenchimento = this.rows.emPreenchimento.filter(function (inscricao: any) {
                return inscricao.id_programa_capes === e.target.value;
            });
            selectTipo.value = '';
            selectModalidade.value = '';
        }
        if (tipoPesquisa === 'limpar') {
            selectTipo.value = '';
            selectModalidade.value = '';
            selectPrograma.value = '';
        }
    }

}
