<div>
    <div class="container-fluid" *ngIf="orcamento">
        <fieldset>
            <legend>Orçamento</legend>
            <app-message-system [mensagens]="messagesForm" [formulario]="siglaForm"></app-message-system>

            <div *ngIf="orcamento.itens_orcamento.length > 0">
                <h4>Orçamento</h4>
                <div class="alert alert-info">
                    <p><i class="glyphicon glyphicon-info-sign"></i> Estabeleça os gastos do projeto, de acordo com as
                        diretrizes do edital.</p>
                </div>


                <div class="table-responsive">
                    <table class="table table-bordered table-hover table-striped">
                        <thead>
                            <tr>
                                <th class="col-md-5">Descrição</th>
                                <th scope="col">Tiposss</th>
                                <th scope="col">Valor Unitário</th>
                                <th scope="col">Valor Total / Limite Máximo</th>
                                <th *ngIf="false" width="1%">Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of orcamento.itens_orcamento; let i = index">
                                <td><input [readOnly]="!this.parametrizacao.pode_adicionar_item_despesa" type="text"
                                        name="item-desc-{{i}}" [(ngModel)]="item.descricao" class="form-control"></td>
                                <td style="text-align: right;">{{item.sub_tipo_despesa.tipo.descricao}}</td>
                                <td>
                                    <p-inputNumber (ngModelChange)="verificaValorTotal(item)" [min]="0"
                                        name="item-valor-{{i}}" [(ngModel)]="item.valor_unitario" mode="decimal"
                                        prefix="R$ " inputStyleClass="form-control"></p-inputNumber>
                                </td>
                                <td style="text-align: right;">
                                    {{(item.quantidade * item.valor_unitario | currency : 'BRL')}}/
                                    <span
                                        *ngIf="item.parametro_item_despesa.valor_maximo_item_despesa != null">{{item.parametro_item_despesa.valor_maximo_item_despesa
                                        | currency : 'BRL'}}</span>
                                    <span *ngIf="item.parametro_item_despesa.valor_maximo_item_despesa == null">sem
                                        limite</span>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="2"><strong>Total Solicitado</strong></td>
                                <td><strong class="pull-right">{{contaTotal(orcamento)| currency : 'BRL'}} /
                                        {{orcamento.valor_limite | currency : 'BRL'}}</strong></td>
                                <td><button (click)="salvarItemOrcamentoLote(orcamento)" class="btn btn-primary"><i
                                            class="glyphicon glyphicon-save"></i> Salvar</button></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <hr />


            <div *ngIf="parametrizacao.parametros_projetos_bolsas.length > 0">
                <h4>Bolsas</h4>

                <div class="alert alert-info">
                    <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a>
                    <strong>O projeto deve contemplar a(s) bolsa(s) {{parametrizacao.quantidade_maxima_bolsas > 0 ?
                        '(total de ' + parametrizacao.quantidade_maxima_bolsas + ' bolsa(s))' : ''}}:</strong>
                    <ul>
                        <li *ngFor="let bolsa of sortModalidade(parametrizacao.parametros_projetos_bolsas, 'BS') ; let i = index">

                            <strong *ngIf="!bolsa.pais?.sigla">{{bolsa.modalidade.descricao}}:</strong>
                            <strong *ngIf="bolsa.pais?.sigla">{{bolsa.modalidade.descricao}} ({{bolsa.pais.sigla === 'BRA'?'Pais':'Exterior'}}):</strong>

                            {{bolsa.minimo > 0 ? 'mínimo ' + bolsa.minimo : ''}}
                            {{bolsa.maximo > 0 && bolsa.minimo > 0 ? 'e máximo ' + bolsa.maximo : ''}} {{bolsa.maximo >
                        0 && bolsa.minimo == 0 ? 'máximo ' + bolsa.maximo : ''}}
                        </li>
                    </ul>
                </div>

                <div class="alert alert-info" *ngIf="parametrizacao.parametros_projetos_bolsas_anos.length > 0">
                    Os limites para cada ano são:
                    <ul>
                        <li
                            *ngFor="let ano of organizarListaBolsaAno(parametrizacao.parametros_projetos_bolsas_anos); let i = index">
                            <strong>{{ano.descricao}}</strong>: Minimo <strong>{{ano.quantidade_minima}}</strong>.
                            Máximo
                            <strong>{{ano.quantidade_maxima}}</strong>.
                            <div *ngIf="ano.possui_filho">
                                <ul>
                                    <li *ngFor="let m of sortModalidade(possuiModalidadeFilho(ano),'BSA') ; let i = index">
                                        {{m.parametro_bolsa.modalidade.descricao}} {{m.parametro_bolsa.pais.sigla ? m.parametro_bolsa.pais.sigla === 'BRA'?'(Pais)':'(Exterior)' : ''}} :
                                        Mínimo <strong>{{m.quantidade_minima}}</strong>.
                                        Máximo <strong>{{m.quantidade_maxima}}</strong>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>

                <div *ngIf="orcamento.bolsas_orcamento.length <= 0" class="alert alert-warning text-center">
                    Não existem bolsas cadastradas nesse projeto
                </div>
                <div class="row">
                    <div class="col-md-2">
                        <div class="form-group">
                            <label>Ano</label>
                            <p-dropdown styleClass="form-control" [showClear]="true"
                                (onChange)="validarAnoSelecionado(bolsa.ano)" [(ngModel)]="bolsa.ano"
                                placeholder="Selecione o Ano" [options]="anos">
                                <ng-template let-ano pTemplate="item">
                                    <div class="p-d-flex p-ai-center">
                                        <span>{{ano}}</span>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="destino">Tipo de Bolsa</label>
                            <p-dropdown styleClass="form-control" [showClear]="true" [disabled]="!bolsa.ano"
                                [(ngModel)]="bolsa.pais" (onChange)="filterModalidadeBolsa(bolsa.pais)"
                                placeholder="País de Destino" optionLabel="nome" [options]="paises">
                                <ng-template let-pais pTemplate="item">
                                    <div class="p-d-flex p-ai-center">
                                        <span>{{pais.nome}} ( {{ pais.sigla }} )</span>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="{{parametrizacao.tipo_integracao_bolsa === 'P' ? 'col-md-2' : 'col-md-4'}}">
                        <div class="form-group">
                            <label for="modalidade">Modalidade</label>
                            <p-dropdown styleClass="form-control" [showClear]="true"
                                [(ngModel)]="bolsa.modalidade_bolsa" placeholder="Selecione a Modalidade"
                                [options]="vinculosModalidadesBolsa" optionValue="modalidade"
                                optionLabel="modalidade.descricao" [disabled]="!bolsa.pais">
                                <ng-template let-modalidade pTemplate="item">
                                    <div class="p-d-flex p-ai-center">
                                        <span>{{modalidade.modalidade.descricao}}</span>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="qtd_bolsistas">N&ordm; de beneficiários</label>
                            <input class="form-control" [(ngModel)]="bolsa.quantidade" type="number" [min]="0"
                                [disabled]="!bolsa.pais && !bolsa.modalidade_bolsa" (change)="validarBolsas()" />

                        </div>
                    </div>
                    <div class="col-md-2" *ngIf="parametrizacao.tipo_integracao_bolsa === 'P'">
                        <div class="form-group">
                            <label for="qtd_bolsistas">N&ordm; de mensalidades</label>
                            <input class="form-control" [(ngModel)]="bolsa.mes" type="number" />
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="form-group">
                            <label for="qtd_bolsistas" style="opacity:0;">Adicionar</label>
                            <button [disabled]="validarIncluirBolsa()" (click)="salvarItemBolsa()"
                                pTooltip="Adicionar Bolsa" class="btn btn-primary">
                                <i class="glyphicon glyphicon-save"></i> Adicionar Bolsa
                            </button>
                        </div>
                    </div>
                </div>


                <div class="table-responsive" *ngIf="orcamento.bolsas_orcamento.length > 0">
                    <table class="table table-bordered table-hover table-striped">
                        <thead>
                            <tr>
                                <th scope="col">Ano</th>
                                <th scope="col">Destino</th>
                                <th scope="col">Modalidade</th>
                                <th scope="col">Qtd. de beneficiários</th>
                                <th width="1%">Ação</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let itemBolsa of orcamento.bolsas_orcamento; let i = index">
                                <td>{{itemBolsa.ano}}</td>
                                <td>
                                    <span *ngIf="itemBolsa.pais.sigla !== 'NIF'">{{itemBolsa.pais.nome}}</span>
                                    <span *ngIf="itemBolsa.pais.sigla === 'NIF'">Exterior</span>
                                </td>
                                <td>{{itemBolsa.modalidade_bolsa.descricao}}</td>
                                <td>{{itemBolsa.quantidade}}</td>
                                <td>
                                    <button class="btn btn-danger" type="button" tooltip="Excluir"
                                        (click)="excluirItemBolsa(itemBolsa)">
                                        <i class="glyphicon glyphicon-trash"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </fieldset>
    </div>
</div>

<p-confirmDialog #cd [style]="{width: '20vw'}">
    <ng-template pTemplate="header">
        <h3>Confirmação</h3>
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" label="Não" (click)="cd.reject()"></button>
        <button type="button" pButton icon="pi pi-check" label="Sim" (click)="cd.accept()"></button>
    </ng-template>
</p-confirmDialog>