import { BaseResourceModel } from '@sicapes/core';
import {Inscricao} from './inscricao.model';

export class InscricaoReferenciaBibliograficaModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public inscricao?: Inscricao,
        public ultima_alteracao?: string
) {
        super();
    }

    static fromJson(jsonData: any): InscricaoReferenciaBibliograficaModel {
        return Object.assign(new InscricaoReferenciaBibliograficaModel(), jsonData);
    }
}
