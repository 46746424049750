import {Component, Input, OnInit} from '@angular/core';
import {Dashboard} from '../../../shared/models/dashboard/dashboard.model';
import {InscricaoService} from '../../../shared/service/inscricao/inscricao.service';
import {ParametrizacaoService} from '../../../shared/service/parametrizacao/parametrizacao.service';
import {ConfirmationService} from 'primeng/api';
import {ToastrService} from 'ngx-toastr';
import {BaseDashboardComponent} from '../base-dashboard.component';
import {ParticipanteService} from '../../../shared/service/inscricao/participante.service';

import * as moment from 'moment';

@Component({
    selector: 'app-dashboard-aba-em-preenchimento',
    templateUrl: './dashboard-aba-em-preenchimento.component.html',
    styleUrls: ['./dashboard-aba-em-preenchimento.component.less'],
    providers: [ConfirmationService]
})
export class DashboardAbaEmPreenchimentoComponent extends BaseDashboardComponent implements OnInit {

    @Input() rows: any;
    @Input() expandirInscricao = '';

    rowsMemory: any[];
    modalidadeBolsaAbertas: any[];
    programaCapesAbertas: any[];
    tiposParametrizacaoAbertas: any[] = [
        {descricao: 'Auxílio', tipo: 'A'},
        {descricao: 'Bolsa', tipo: 'I'},
        {descricao: 'Projeto', tipo: 'P'},
    ];
    filter: any = {
        tipo: null,
        modalidade: null,
        programa: null
    };

    currentDate: Date = new Date();

    public isDataMaior(data: string): boolean {
        const dataFimFase: Date = new Date(data);
        return dataFimFase > this.currentDate;
    }

    informacoesComplementares: any[] = [];
    activeState: boolean[] = [true, false, false];
    preenchimento: any[] = [];

    constructor(
        protected parametrizacaoService: ParametrizacaoService,
        protected participanteService: ParticipanteService,
        protected inscricaoService: InscricaoService,
        protected confirmationService: ConfirmationService,
        protected toastr: ToastrService
    ) {
        super(parametrizacaoService, participanteService, inscricaoService, confirmationService, toastr);
    }

    ngOnInit(): void {
        this.rowsMemory = { ...this.rows };
        this.obterListaFiltro();
    }

    toggle(index: number): void {
        this.activeState[index] = !this.activeState[index];
    }

    public expandir(inscricao: Dashboard): void {
        if (inscricao) {
            if (inscricao.hide) {
                const objInformacoesComplementares = this.informacoesComplementares.find(obj => obj.id === inscricao.cd_inscricao);
                if (!objInformacoesComplementares) {
                    this.inscricaoService.obterInformacoesComplementaresInscricao(inscricao.cd_inscricao, inscricao.sg_edital).subscribe(
                        (result: any) => {
                            inscricao.informacoes = {};
                            inscricao.informacoes.informacao = result.informacoes.Informações;
                            inscricao.informacoes.data = result.informacoes.Datas;
                            inscricao.informacoes.resumo = result.informacoes.Resumo;

                            this.informacoesComplementares.push(result);
                        },
                        (error: any) => this.toastr.error('Não foi possivel recuperar as informações complementares da inscrição.')
                    );
                    this.inscricaoService.obterFormulariosInscricao(inscricao.cd_inscricao).subscribe(
                        (result: any) => {
                            let count = 0;
                            inscricao.formularios = {};
                            inscricao.formularios = result._embedded.items;
                            inscricao.formularios.forEach((f: any, i: any) => {
                                if (f.in_obrigatorio === 'S' && f.in_preenchido === 'N') {
                                    count += 1;
                                }
                            });
                            if (count > 0) {
                                inscricao.finalizavel = false;
                            } else {
                                inscricao.finalizavel = true;
                            }
                            this.inscricaoService.dadosInscricaoAtiva = {
                                c: inscricao.cd_inscricao,
                                p: inscricao.ds_identificador_registrado,
                                f: [...result._embedded.items]
                            };
                        },
                        (error: any) => this.toastr.error('Não foi possivel recuperar os formulários.')
                    );
                } else {
                    inscricao.informacoes = {};
                    inscricao.informacoes.informacao = objInformacoesComplementares.informacoes.Informações;
                    inscricao.informacoes.data = objInformacoesComplementares.informacoes.Datas;
                }
            }
            inscricao.hide = !inscricao.hide;
        }
    }

    public getListGroupClass(formulario: any): any {

        const validado: boolean = formulario.in_preenchido === 'S';

        return {
            'list-group-item-success': validado,
            'list-group-item-danger': (!validado && formulario.in_obrigatorio === 'S'),
            'list-group-item-warning': (!validado && formulario.in_obrigatorio === 'N')
        };
    }

    public obterListaFiltro(): void {


        this.modalidadeBolsaAbertas = Array.from(new Set(this.rows.emPreenchimento.map(a => a.ds_modalidade_bolsa)))
            .map(modalidade => {
                    return this.rows.emPreenchimento.find(a => a.ds_modalidade_bolsa === modalidade);
                }
            );

        this.programaCapesAbertas = Array.from(new Set(this.rows.emPreenchimento.map(a => a.nm_programa_capes)))
            .map(nmProgramaCapes => {
                    return this.rows.emPreenchimento.find(a => a.nm_programa_capes === nmProgramaCapes);
                }
            );

    }

    public filterLista(tipo): void {

        this.rows =  { ...this.rowsMemory };

        if (tipo === 'tipo') {
            this.filter.modalidade = null;
            this.filter.programa = null;
            if (this.filter.tipo !== null) {
                this.rows.emPreenchimento = this.rows.emPreenchimento.filter((row) => row.tp_parametro_edital === this.filter.tipo.tipo);
            }
        }

        if (tipo === 'modalidade') {
            this.filter.tipo = null;
            this.filter.programa = null;
            if (this.filter.modalidade !== null) {
                this.rows.emPreenchimento = this.rows.emPreenchimento.filter((row) => row.ds_modalidade_bolsa === this.filter.modalidade.ds_modalidade_bolsa);
            }
        }

        if (tipo === 'programa') {
            this.filter.modalidade = null;
            this.filter.tipo = null;
            if (this.filter.programa !== null) {
                this.rows.emPreenchimento = this.rows.emPreenchimento.filter((row) => row.nm_programa_capes === this.filter.programa.nm_programa_capes);
            }
        }

    }

    public checkExpired(inscricao): boolean {

        let isExpired = false;

        // tslint:disable-next-line:only-arrow-functions typedef
        const checkData = function() {
            const inicio = moment(inscricao.dt_inicio_fase, 'YYYY-MM-DD HH:mm:ss');
            const fim = moment(inscricao.dt_fim_fase, 'YYYY-MM-DD HH:mm:ss');
            const current = moment(inscricao.informacoes.data['Data/Hora do servidor'], 'DD/MM/YYYY HH:mm:ss');

            return current.isBetween(inicio, fim, undefined, '[]');
        };

        const ok = checkData();

        if (!ok) {
            isExpired = true;
        }

        return isExpired;
    }

    public obterPreenchimento(inscricao): void {
        let totalValidado = 0;
        let totalObrigatorio = 0;
        this.preenchimento = null;

        if(typeof inscricao.formularios === 'undefined')
            return ;
            
        
        inscricao.formularios.forEach(form => {
            if (form.in_obrigatorio == "S") {
                totalObrigatorio++;
            }
            if (form.in_preenchido == "S" && form.in_obrigatorio == "S") {
                totalValidado++;
            }

        });
        inscricao = null;
        this.preenchimento = [totalValidado, totalObrigatorio];
    }
}
