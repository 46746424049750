<p-confirmDialog [style]="{width: '25vw'}"></p-confirmDialog>

<div class="card card-preenchimento" *ngFor="let inscricao of rows.emPreenchimento; let i = index">
    {{obterPreenchimento(inscricao)}}
    <p class="card-header text-blue lb-edital">
        <em class="{{ inscricao.tp_parametro_edital == 'I' ? 'pi pi-user' : inscricao.tp_parametro_edital == 'P' ? 'pi pi-book' : 'pi pi-money-bill' }}"
            pTooltip="{{ inscricao.tp_parametro_edital == 'I' ? 'Bolsa Individual' : inscricao.tp_parametro_edital == 'P' ? 'Projeto' : 'Auxílio' }}"></em>
        {{inscricao.nm_edital}} ({{inscricao.sg_edital}})
    </p>
    <div class="card-body">
        <div class="row">
            <div class="col text-left ms-3">
                <div class="btn-group-vertical w-100" role="group" aria-label="Vertical button group">
                    <a id="cancelar" class="btn btn-default text-danger" (click)="cancelarInscricao(inscricao);">
                        Cancelar Inscrição
                        <i class="glyphicon glyphicon-remove"></i>
                    </a>
                    <a *ngIf="preenchimento[0] === preenchimento[1]" href="javascript:void(0)"
                        (click)="expandir(inscricao)" class="btn btn-default text-dark">
                        Finalizar Inscrição
                        <em class="glyphicon"
                            [ngClass]="{'glyphicon-chevron-down' : inscricao.hide, 'glyphicon-chevron-up' : !inscricao.hide}"></em>
                    </a>
                    <a *ngIf="preenchimento[0] != preenchimento[1]" href="javascript:void(0)"
                        (click)="expandir(inscricao)" class="btn btn-default text-dark">
                        <span *ngIf="inscricao.hide">
                            Exibir Preenchimento
                            <em class="glyphicon glyphicon-chevron-down"></em>
                        </span>
                        <span *ngIf="!inscricao.hide">
                            Ocultar Preenchimento
                            <em class="glyphicon glyphicon-chevron-up"></em>
                        </span>
                    </a>
                </div>
            </div>
            <div class="col-9">
                <div>
                    <em class="pi pi-book"></em> Código da Inscrição: <span
                        class="fw-semibold">{{inscricao.cd_inscricao}}</span>
                </div>

                <div style="color: red;" *ngIf="!isDataMaior(inscricao.dt_fim_fase); else elseBlock">
                    <em class="pi pi-calendar"></em> O período de inscrição expirou em {{
                    formataData(inscricao.dt_fim_fase) }}.
                </div>

                <ng-template #elseBlock>
                    <div *ngIf="inscricao.acesso_permitido">
                        <em class="pi pi-calendar"></em> Inscrição até o dia <span class="fw-semibold">{{
                            formataData(inscricao.dt_fim_fase) }}</span> horas
                    </div>
                </ng-template>

                <div>
                    <em class="pi pi-percentage"></em> Formulários obrigatórios preenchidos <span class="fw-semibold"
                        [ngClass]="{'text-success' : preenchimento[0] === preenchimento[1], 'text-orange' : preenchimento[0] != preenchimento[1]}">
                        {{preenchimento[0]|json}}/{{preenchimento[1]|json}}
                    </span>
                </div>
            </div>
        </div>

        <div class="inner-panel-content" [ngClass]="{'toggle-hide' : inscricao.hide}"
            style="background-color: #f7f7f7; border-radius: 8px; padding: 0px 20px 20px 20px; margin-top: 10px;">
            <!-- Dados da proposta -->
            <div class="row" *ngIf="inscricao.informacoes && inscricao.informacoes.data && inscricao.formularios">
                <div class="col-sm-5">
                    <h5>Formulários</h5>
                    <div *ngFor="let formulario of inscricao.formularios; let i = index">

                        <div class="wrapper col-md-9"
                            *ngIf="!checkExpired(inscricao) && formulario.nm_formulario != 'Finalização' && inscricao.acesso_permitido">
                            <a class=""
                                [routerLink]="['/inscricao', inscricao.ds_identificador_registrado, inscricao.cd_inscricao, formulario.sg_tipo_formulario]">
                                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" fill="currentColor"
                                    class="bi bi-suit-diamond-fill" viewBox="0 0 16 16">
                                    <path
                                        d="M2.45 7.4 7.2 1.067a1 1 0 0 1 1.6 0L13.55 7.4a1 1 0 0 1 0 1.2L8.8 14.933a1 1 0 0 1-1.6 0L2.45 8.6a1 1 0 0 1 0-1.2z" />
                                </svg> &nbsp; {{formulario.nm_formulario}}
                            </a>
                        </div>

                        <div class="wrapper col-md-9" *ngIf="checkExpired(inscricao) || !inscricao.acesso_permitido">
                            <svg *ngIf="formulario.nm_formulario != 'Finalização'" xmlns="http://www.w3.org/2000/svg"
                                width="8" height="8" fill="currentColor" class="bi bi-suit-diamond-fill"
                                viewBox="0 0 16 16">
                                <path
                                    d="M2.45 7.4 7.2 1.067a1 1 0 0 1 1.6 0L13.55 7.4a1 1 0 0 1 0 1.2L8.8 14.933a1 1 0 0 1-1.6 0L2.45 8.6a1 1 0 0 1 0-1.2z" />
                            </svg> &nbsp; <span *ngIf="formulario.nm_formulario != 'Finalização'"
                                class="text-secondary">{{formulario.nm_formulario}}</span>
                        </div>

                        <div class="col-md-3" *ngIf="formulario.nm_formulario != 'Finalização'"
                            pTooltip="{{formulario.in_preenchido == 'S' ? 'Preenchido' : formulario.in_preenchido == 'N' && formulario.in_obrigatorio =='S' ? 'Não preenchido' : formulario.in_preenchido == 'N' && formulario.in_obrigatorio =='N' ? 'Preenchimento opcional' : ''}}"
                            [class]="getListGroupClass(formulario)" style="border: none; background-color: #f7f7f7;">
                            <span class="glyphicon fw-bolder" [ngClass]="{'glyphicon-ok-sign': formulario.in_preenchido == 'S', 'glyphicon-minus-sign': formulario.in_preenchido == 'N'
                && formulario.in_obrigatorio =='N', 'glyphicon-exclamation-sign': formulario.in_preenchido == 'N' 
                && formulario.in_obrigatorio =='S'}">
                            </span>
                        </div>
                    </div>
                </div>

                <div class="col-sm-7">
                    <h5>Informações</h5>
                    <table class="table table-borderless">
                        <caption>Lista de formulários da inscrição</caption>
                        <tbody>
                            <tr *ngFor='let item of inscricao.informacoes.resumo | keyvalue'>
                                <ng-container *ngIf="item.value !== ''">
                                    <td style="border: none;" class="fw-semibold">{{item.key}}</td>
                                    <td style="border: none;" *ngIf="item.key != 'Página do programa'">{{item.value}}
                                    </td>
                                    <td style="border: none; fw-semibold" *ngIf="item.key == 'Página do programa'">
                                        <a href="{{item.value}}" target="_blank">
                                            <i class="glyphicon glyphicon-new-window"></i>
                                            Clique aqui para acessar
                                        </a>
                                    </td>
                                </ng-container>
                            </tr>
                            <tr *ngIf="inscricao.finalizavel">
                                <td class="col-md-12" style="border: none;" colspan="2">
                                    <a id="finalizar" class="btn btn-lg btn-success btn-block" type="button"
                                        [routerLink]="['/inscricao', inscricao.ds_identificador_registrado, inscricao.cd_inscricao, 'finalizacao']">
                                        Submeter Inscrição
                                        <i class="glyphicon glyphicon-export"></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div *ngIf="checkExpired(inscricao)"
                        class="card-text lb-edital alert alert-danger d-flex align-items-center" role="alert">
                        <i class="bi bi-exclamation-triangle-fill"></i>&nbsp;
                        <p>O período de inscrição expirou em <strong>{{ formataData(inscricao.dt_fim_fase) }}</strong>.
                        </p>
                    </div>
                    <div class="row p-4" *ngIf="!inscricao.acesso_permitido && !checkExpired(inscricao)">
                        <div class="alert alert-warning d-flex align-items-center rounded" role="alert"
                            style="border-left: 5px solid #8a6d3b;">
                            <i class="bi-exclamation-triangle-fill"></i>
                            <div>
                                &nbsp; Período de inscrição finalizado!
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>