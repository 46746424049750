import { BaseResourceModel } from '@sicapes/core';
import { GrauAcademicoModel } from './grau-academico.model';
import { AreaConhecimentoModel } from './area-conhecimento.model';
import { InstituicaoEnsinoModel } from './instituicao-ensino.model';
import { InscricaoTituloModel } from '../../../pages/formularios/inscricao-titulo/shared/models/inscricao-titulo.model';
import { Pessoa } from './pessoa.model';
import { InscricaoFormcaoAndamentoModel } from '../../../pages/formularios/inscricao-formacao/shared/models/inscricao-formcao-andamento.model';

export class FormacaoAndamentoModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public pessoa?: Pessoa,
        public discente?: any,
        public ies?: InstituicaoEnsinoModel,
        public endereco_ies?: any,
        public curso?: any,
        public nome_curso?: string,
        public grau_academico?: GrauAcademicoModel,
        public area_conhecimento?: AreaConhecimentoModel,
        public data_inicio?: string,
        public data_termino_previsto?: string,
        public nota_quadrienal?: any,
        public habilitado?: boolean,
        public inscricaoFormacao?: InscricaoFormcaoAndamentoModel,
        public checkUtilizado?: any
    ) {
        super();
    }

    static fromJson(jsonData: any): FormacaoAndamentoModel {
        // @ts-ignore
        return Object.assign(new FormacaoAndamentoModel(), jsonData);
    }
}
