import { Component, Input } from '@angular/core';
import { ChildActivationEnd, Event, Router } from '@angular/router';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.css']
})
export class NavigationComponent {

    @Input() menu: any;

    proponente: any;
    codigo: any;
    isRecurso: boolean = false;

    constructor(private router: Router) {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof ChildActivationEnd) {
                if (event.snapshot.params.proponente !== undefined && event.snapshot.params.codigo !== undefined) {
                    this.proponente = event.snapshot.params.proponente;
                    this.codigo = event.snapshot.params.codigo;
                    this.isRecurso = (event.snapshot.data.siglaForm === 'recurso') ? true : false;

                }
            }
        });
    }

    /**
     * Retorna o link dinamico para o dashboard
     *
     * @returns {string}
     * @memberof NavigationComponent
     */
    public dashboardRedirect(): string {
        let url = '';
        if (this.proponente && this.codigo && !this.isRecurso) {
            url = '/minhas-inscricoes/em-preenchimento/' + this.codigo;
        }
        if (this.proponente && this.codigo && this.isRecurso) {
            url = '/minhas-inscricoes/em-recurso/';
        }
        return '/dashboard' + url;
    }

}
