<p-autoComplete *ngIf="viewAutoComplete"
        inputStyleClass="form-control"
        [style]="{'width':'100%'}"
        [(ngModel)]="inseridos"
        [suggestions]="results"
        (completeMethod)="pesquisaPalavraChave($event)"
        (onUnselect)="excluirPalavraChave($event)"
        (onSelect)="inserirPalavraChave($event)"
        (onKeyUp)="onKeyUp($event)"
        [multiple]="true"
        [minLength]="3"
        [unique]="true"
        field="palavra_chave.descricao"
        [autofocus]="false">
    <ng-template let-country pTemplate="item">
        <div class="country-item">
            <div>{{country.palavra_chave.descricao}}</div>
        </div>
    </ng-template>
</p-autoComplete>