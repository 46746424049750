import {Injectable, Injector} from '@angular/core';
import {catchError, map} from 'rxjs/operators';
import {Observable} from 'rxjs';

import {BaseResourceService} from '@sicapes/core';
import {Pessoa} from '../../models/corporativo/pessoa.model';
import {Participante} from '../../models/inscricao/participante.model';
import {TituloModel} from '../../models/corporativo/titulo.model';
import {FormacaoAndamentoModel} from '../../models/corporativo/formacao-andamento.model';
import {IdentificadorRegistrado} from '../../models/corporativo/identificador-registrado.model';

@Injectable({
    providedIn: 'root'
})
export class PessoaService extends BaseResourceService<Pessoa> {

    constructor(protected injector: Injector) {
        super('rest/pessoas-fisicas', injector, Pessoa.fromJson);
    }

    obterPessoa(cpf): Observable<any[]> {
        return this.http.get(this.apiPath + '/' + cpf).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    getByCpf(cpf: string): Observable<any[]> {
        return this.http.get(this.apiPath + '/' + cpf).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    getById(id: number): Observable<any> {
        const url = '/gateway/cadastropessoas/rest/pessoas/' + id;
        return this.http.get(url).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    obterFormacoes(idPessoa: any): Observable<TituloModel[]> {
        return this.http.get(this.apiPath + '/' + idPessoa + '/registros/formacoes').pipe(map((titulos: any) => {
            return titulos._embedded.items.map((t: any) => {
                return Object.assign(new FormacaoAndamentoModel(), t);
            });
        }));
    }

    excluirFormacaoAndamento(pessoa: Pessoa, formacao: FormacaoAndamentoModel): Observable<any> {
        const url = '/rest/pessoas-fisicas/' + pessoa.id + '/registros/formacoes/' + formacao.id;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    salvarFormacaoAndamento(pessoa: Pessoa, formacao: FormacaoAndamentoModel): Observable<any> {
        let url = '/rest/pessoas-fisicas/' + pessoa.id + '/registros/formacoes';

        if (formacao.id) {
            url += '/' + formacao.id;

            return this.http.put(url, formacao).pipe(
                map(() => formacao),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, formacao).pipe(
            map(() => formacao),
            catchError(this.handleError)
        );
    }

    // @ts-ignore
    salvarPessoa(pessoa: any, contexto: string, dadosContexto): Observable<any> {
        let url = 'rest/pessoas-fisicas';
        if (pessoa.codigo) {
            if (contexto) {
                url += '/' + pessoa.codigo + '/' + contexto;
                if (dadosContexto.id) {
                    url += '/' + dadosContexto.id;
                    return this.http.put(url, dadosContexto).pipe(
                        map(() => dadosContexto),
                        catchError(this.handleError)
                    );
                } else {
                    return this.http.post(url, dadosContexto).pipe(
                        map(() => dadosContexto),
                        catchError(this.handleError)
                    );
                }
            }

        }
    }

    obterDadosPessoaJuridicaRF(proponente: string, cnpj: string): Observable<any> {
        const url = 'gateway/cadastropessoas/rest/pessoas/juridica/cnpj/' + cnpj + '?siglaAplicacaoResponsavelConsulta=SICAPES&loginResponsavelConsulta=' + proponente;
        return this.http.get(url).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    salvarDadosPessoaJuridicaRF(proponente: string, pessoaJuridica: any): Observable<any> {
        const url = 'gateway/cadastropessoas/rest/pessoas?siglaAplicacaoResponsavelConsulta=SICAPES&loginResponsavelConsulta=' + proponente;
        return this.http.post(url, pessoaJuridica).pipe(
            map(() => pessoaJuridica),
            catchError(this.handleError)
        );

    }

    obterPessoaPessoaJuridicaNome(nome: string): Observable<any> {
        const url = 'rest/pessoas-juridicas?nome=' + nome;
        return this.http.get(url).pipe(
            map(this.jsonDataToResources.bind(this)),
            catchError(this.handleError)
        );
    }

    obterLattes(cpf: string): Observable<any> {
        const url = 'rest/lattes/' + cpf;
        return this.http.get(url).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    salvarInformacaoDadosCorporativo(proponente: string, codigo: string, participante: Participante, pessoa: any): Observable<any> {

        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/participantes/' + participante.id + '/informacoes-dados-corporativos';

        return this.http.put(url, pessoa, {observe: 'response'}).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );

    }

    // tslint:disable-next-line:max-line-length
    salvarIdentificadorRegistrado(proponente: string, codigo: string, participante: Participante, identificadorRegistrado: any): Observable<any> {

        let url = '/rest/inscricoes/' + proponente + '/' + codigo + '/participantes/' + participante.id + '/informacoes-identificadores-registrados';

        if (identificadorRegistrado.id) {
            url += '/' + identificadorRegistrado.id;
            return this.http.put(url, identificadorRegistrado, {observe: 'response'}).pipe(
                map(this.jsonDataToResource.bind(this)),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, identificadorRegistrado, {observe: 'response'}).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );

    }

    obterIdentificadoresRegistrados(proponente: string, codigo: string, participante: Participante, identificadorRegistrado: any): Observable<any> {

        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/participantes/' + participante.id + '/informacoes-identificadores-registrados';

        return this.http.get(url).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );

    }

    obterIdentificadorRegistrado(proponente: string, tipoIdentificadorRegistrado: any, dsIdentificadorRegistrado: string): Observable<any> {

        let url = '/rest/pessoas-fisicas/' + proponente + '/identificadores-registrados/' + tipoIdentificadorRegistrado + '/descricao-identificador';

        if (dsIdentificadorRegistrado) {
            url += '/' + dsIdentificadorRegistrado;
        }

        return this.http.get(url).pipe(map((identificadores: any) => {
            return identificadores._embedded.items.map((i: any) => {
                return Object.assign(new IdentificadorRegistrado(), i);
            });
        }));

    }

    obterTitulos(cpf: string): Observable<TituloModel[]> {
        return this.http.get(this.apiPath + '/' + cpf + '/titulos').pipe(map((titulos: any) => {
            return titulos._embedded.items.map((t: any) => {
                return Object.assign(new TituloModel(), t);
            });
        }));
    }

    salvarTitulo(dadosContexto): Observable<any> {
        const url = '/gateway/cadastropessoas/rest/titulos';
        return this.http.post(url, dadosContexto).pipe(
            map(() => dadosContexto),
            catchError(this.handleError)
        );
    }

    excluirTitulo(titulo: TituloModel): Observable<any> {
        const url = '/gateway/cadastropessoas/rest/titulos/' + titulo.id;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    redirectMeusDados(): any {
        const urlRest = '/rest/params-front/seguranca_aplicacao_url_meusdados';
        return this.http.get(urlRest).subscribe((r: any) => {
            if ((typeof r === 'object' && r.itens.hasOwnProperty('seguranca_aplicacao_url_meusdados'))){
                const urlMeusDados = r.itens.seguranca_aplicacao_url_meusdados;
                setTimeout(() => {
                    if (!window.open(urlMeusDados)) {
                        window.location.href = urlMeusDados;
                    }
                }, 0, false);
            }
        });
    }

    adaptPessoaRFPost(dadosRF, configuracao): any {

        if (!configuracao) { configuracao = {}; }
        if (!configuracao.usuario_ultima_alteracao) { configuracao.usuario_ultima_alteracao = 'WEBINSCRICAO'; }
        if (dadosRF.cpf) { delete dadosRF.cpf; }
        if (configuracao.convert_dates && dadosRF.dataNascimento && dadosRF.dataNascimento != "") {
            dadosRF.dataNascimento = dadosRF.dataNascimento.replace(/(\d{2})[-/](\d{2})[-/](\d+)/, "$3-$2-$1");
        }

        dadosRF.usuarioUltimaAlteracao = configuracao.usuario_ultima_alteracao;

        if (dadosRF.identificadoresRegistrados && dadosRF.identificadoresRegistrados.length > 0) {
            dadosRF.identificadoresRegistrados.forEach((identificador) => {
                identificador.usuarioUltimaAlteracao = configuracao.usuario_ultima_alteracao;
                if (configuracao.convert_dates && identificador.dataExpedicao) {
                    identificador.dataExpedicao = identificador.dataExpedicao.replace(/(\d{2})[-/](\d{2})[-/](\d+)/, '$3-$2-$1');
                }
            });
        }

        if (dadosRF.emails && dadosRF.emails.length > 0) {
            dadosRF.emails.forEach((email) => {
                email.usuarioUltimaAlteracao = configuracao.usuario_ultima_alteracao;
            });
        }

        if (dadosRF.telefones && dadosRF.telefones.length > 0) {
            dadosRF.telefones.forEach((telefone) => {
                telefone.usuarioUltimaAlteracao = configuracao.usuario_ultima_alteracao;
            });
        }

        return dadosRF;
    };

}
