import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardPainelComponent } from './dashboard-painel/dashboard-painel.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: DashboardPainelComponent,
  //   children: [
  //     {
  //       path: ':aba', 
  //       component: DashboardPainelComponent,
  //       data: { tab: 'minhas-inscricoes' },
  //       children: [
  //         {
  //           path: ':situacao',
  //           component: DashboardPainelComponent,
  //           data: { tab: 'minhas-inscricoes' },
  //           children: [
  //             {
  //               path: ':inscricao',
  //               component: DashboardPainelComponent,
  //               data: { tab: 'minhas-inscricoes' }
  //             },
  //           ]
  //         },
  //       ]
  //     }
  //   ]
  // },
  // mesma implementação
  { path: '', component: DashboardPainelComponent },
  { path: ':aba', component: DashboardPainelComponent, data: { tab: 'minhas-inscricoes' } },
  { path: ':aba/:situacao', component: DashboardPainelComponent, data: { tab: 'minhas-inscricoes' } },
  { path: ':aba/:situacao/:inscricao', component: DashboardPainelComponent, data: { tab: 'minhas-inscricoes' } },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
