import {BaseResourceModel} from '@sicapes/core';

export class ItemReceita extends BaseResourceModel {
    constructor(
        public id?: number,
        public descricao?: string,
        // tslint:disable-next-line:variable-name
        public item_valor?: any,
        public tipo?: any
    ) {
        super();
    }

    static fromJson(jsonData: any): ItemReceita {
        return Object.assign(new ItemReceita(), jsonData);
    }
}
