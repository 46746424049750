import {BaseResourceModel} from '@sicapes/core';
import {IdiomaModel} from "./idioma.model";

export class InscricaoIdiomaModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public idioma?: IdiomaModel[],
    ) {
        super();
    }

    static fromJson(jsonData: any): InscricaoIdiomaModel {
        return Object.assign(new InscricaoIdiomaModel(), jsonData);
    }
}
