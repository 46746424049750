<app-loading-screen></app-loading-screen>

<app-header *ngIf="currentUser" [userData]="currentUser"></app-header>

<app-navigation [menu]="menu"></app-navigation>



<div ui-view></div>

<div class="container page-content">
  <app-subnavigation [subMenu]="subMenu"></app-subnavigation>
  <router-outlet (activate)="changeOfRoutes()"></router-outlet>

  <div ui-view></div>

  <div class="row ng-scope">
    <div class="col-md-12">
      <div block-ui block-ui-pattern="/^\/(auth|rest|gateway)\/(rest|status|aplicativos)\/.*$/">
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
