import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
    selector: 'pareceres-emitidos-analise',
    templateUrl: './pareceresEmitidos.component.html',
    styleUrls: ['./pareceresEmitidos.component.less']
})
export class PareceresEmitidosComponent implements OnInit {

    @Input() pareceresAnalise: any;
    @Output() parecerModalClose = new EventEmitter<boolean>();

    ngOnInit(): void {
        // throw new Error('Method not implemented.');
    }

    public downloadParecerAnalise(idDocumento) {
        let url = '/rest/inscricoes/' + this.pareceresAnalise.proponente + "/" + this.pareceresAnalise.inscricao + '/pareceres-emitidos/' + idDocumento;
        //
        window.open(url, "_blank"); 
        //https://inscricao2.capes.gov.br/rest/inscricoes/85971944991/COFECUB-20232471551P/pareceres-emitidos/3296747
    }
    public fecharModal(){
        this.parecerModalClose.emit(true);
    }
}