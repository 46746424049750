import {Injectable, Injector} from '@angular/core';
import {BaseResourceService} from '@sicapes/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {InscricaoPalavraChaveModel} from '../../models/inscricao/inscricao-palavra-chave.model';
import {InscricaoReferenciaBibliograficaModel} from '../../models/inscricao/inscricao-referencia-bibliografica.model';
import {InscricaoObjetivoResultadoModel} from '../../models/inscricao/inscricao-objetivo-resultado.model';
import {ObjetivoModel} from '../../models/inscricao/objetivo.model';
import {ProdutoEsperadoModel} from '../../models/inscricao/produto-esperado.model';
import {InscricaoCaracteristicaNovacaoModel} from '../../models/inscricao/inscricao-caracteristica-novacao.model';

@Injectable({
    providedIn: 'root'
})
export class CaracteristicaInscricaoService extends BaseResourceService<InscricaoPalavraChaveModel> {

    constructor(protected injector: Injector) {
        super('rest/inscricoes', injector, InscricaoPalavraChaveModel.fromJson);
    }

    public obterInscricaoPalavrasChave(proponente: string, codigo: string): Observable<InscricaoPalavraChaveModel[]> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/palavras-chave';

        return this.http.get(url).pipe(map((end: any) => {
            return end._embedded.items.map((r: any) => {
                return Object.assign(new InscricaoPalavraChaveModel(), r);
            });
        }));
    }

    public salvarInscricaoPalavraChave(proponente: string, codigo: string, inscricaoPalavraChaveModel: InscricaoPalavraChaveModel): Observable<InscricaoPalavraChaveModel> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/palavras-chave';

        if (inscricaoPalavraChaveModel.id) {
            return this.http.put(url + '/' + inscricaoPalavraChaveModel.id, inscricaoPalavraChaveModel, { observe: 'response' }).pipe(
                map(this.jsonDataToResource.bind(this)),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, inscricaoPalavraChaveModel, { observe: 'response' }).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );

    }

    public excluirInscricaoPalavraChave(proponente: string, codigo: string, inscricaoPalavraChaveModel: InscricaoPalavraChaveModel): Observable<null> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/palavras-chave/' + inscricaoPalavraChaveModel.id;
        return this.http.delete(url, { observe: 'response' }).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    public obterInscricaoReferenciasBibliograficas(proponente: string, codigo: string): Observable<InscricaoReferenciaBibliograficaModel[]> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/referencias-bibliograficas';

        return this.http.get(url).pipe(map((end: any) => {
            return end._embedded.items.map((r: any) => {
                return Object.assign(new InscricaoReferenciaBibliograficaModel(), r);
            });
        }));
    }

    public excluirInscricaoReferenciaBibliografica(proponente: string, codigo: string, inscricaoReferenciaBibliografica: InscricaoReferenciaBibliograficaModel): Observable<null> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/referencias-bibliograficas/' + inscricaoReferenciaBibliografica.id;
        return this.http.delete(url, { observe: 'response' }).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    public salvarInscricaoReferenciaBibliografica(proponente: string, codigo: string, inscricaoReferenciaBibliografica: InscricaoReferenciaBibliograficaModel): Observable<InscricaoReferenciaBibliograficaModel> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/referencias-bibliograficas';

        if (inscricaoReferenciaBibliografica.id) {
            return this.http.put(url + '/' + inscricaoReferenciaBibliografica.id, inscricaoReferenciaBibliografica, { observe: 'response' }).pipe(
                map(this.jsonDataToResource.bind(this)),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, inscricaoReferenciaBibliografica, { observe: 'response' }).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );

    }

    public obterResultadoEsperado(proponente: string, codigo: string, param: string): Observable<InscricaoObjetivoResultadoModel[]> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/' + param;

        return this.http.get(url).pipe(map((end: any) => {
            return end._embedded.items.map((r: any) => {
                return Object.assign(new InscricaoObjetivoResultadoModel(), r);
            });
        }));
    }

    public salvarResultadoEsperado(proponente: string, codigo: string, param: string, inscricaoResultadoEsperado: any): Observable<InscricaoObjetivoResultadoModel[]> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/' + param;

        if (inscricaoResultadoEsperado.id) {
            return this.http.put(url + '/' + inscricaoResultadoEsperado.id, inscricaoResultadoEsperado, { observe: 'response' }).pipe(
                map(this.jsonDataToResource.bind(this)),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, inscricaoResultadoEsperado, { observe: 'response' }).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public excluirResultadoEsperado(proponente: string, codigo: string, param: string, objeto: any): Observable<any> {

        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/' + param + '/' + objeto.id;

        return this.http.delete(url, {observe: 'response'}).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    public obterObjetivos(proponente: string, codigo: string): Observable<InscricaoObjetivoResultadoModel[]> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/' + 'objetivos';

        return this.http.get(url).pipe(map((end: any) => {
            return end._embedded.items.map((r: any) => {
                return Object.assign(new ObjetivoModel(), r);
            });
        }));
    }

    public salvarObjetivo(proponente: string, codigo: string, objetivo: any): Observable<ObjetivoModel[]> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/' + 'objetivos';

        if (objetivo.id) {
            return this.http.put(url + '/' + objetivo.id, objetivo, { observe: 'response' }).pipe(
                map(this.jsonDataToResource.bind(this)),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, objetivo, { observe: 'response' }).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public excluirObjetivo(proponente: string, codigo: string, objetivo: any): Observable<any> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/' + 'objetivos' + '/' + objetivo.id;

        return this.http.delete(url, {observe: 'response'}).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    public obterProdutos(proponente: string, codigo: string): Observable<ProdutoEsperadoModel[]> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/' + 'produtos';

        return this.http.get(url).pipe(map((end: any) => {
            return end._embedded.items.map((r: any) => {
                return Object.assign(new ProdutoEsperadoModel(), r);
            });
        }));
    }

    public obterNovacao(proponente: string, codigo: string): Observable<InscricaoCaracteristicaNovacaoModel[]> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/' + 'novacao';
        return this.http.get(url).pipe(map((end: any) => {
            return Object.assign(new InscricaoCaracteristicaNovacaoModel(), end);
        }));
    }

    public salvarNovacao(proponente: string, codigo: string, novacao: any): Observable<InscricaoCaracteristicaNovacaoModel[]> {
        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/' + 'novacao';

        if (novacao.id) {
            return this.http.put(url + '/' + novacao.id, novacao, { observe: 'response' }).pipe(
                map(this.jsonDataToResource.bind(this)),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, novacao, { observe: 'response' }).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

}
