import {BaseResourceModel} from '@sicapes/core';

export class TipoInscricaoPessoa extends BaseResourceModel {

    static readonly ID_TIPO_PROPONENTE: number = 13;
    static readonly ID_TIPO_COORDENADOR_PRINCIPAL: number = 21;
    static readonly ID_TIPO_IES_BRASILEIRA_PRINCIPAL: number = 78;
    static readonly ID_TIPO_IES_BRASILEIRA_ASSOCIADA: number = 76;
    static readonly ID_TIPO_IES_EXTERIOR_PRINCIPAL: number = 79;
    static readonly ID_TIPO_IES_EXTERIOR_ASSOCIADA: number = 77;
    static readonly ID_TIPO_INSTITUICAO_PARCEIRA: number = 123;

    constructor(
        public id?: number,
        public nome?: string,
        public descricao?: string,
        public ultima_alteracao?: string,
    ) {
        super();
    }

    static fromJson(jsonData: any): TipoInscricaoPessoa {
        return Object.assign(new TipoInscricaoPessoa(), jsonData);
    }
}
