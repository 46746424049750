import { HttpClient, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InformacaoDocumentoModel } from '../../models/inscricao/informacao-documento.model';
import { ParametroDocumentosExigidos } from '../../models/parametrizacao/parametro-documentos-exigidos.model';
import { Buffer } from 'buffer';

@Component({
    selector: 'inscricao-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.less']
})
export class FileUploadComponent implements OnInit {

    constructor(private http: HttpClient) {
    }

    fileName: string = '';
    @Input()
    proponente: string;

    @Input()
    codigo: string;

    @Input()
    participante;

    @Input()
    documentoExigido;

    /**
     * Tipo do arquivo permitido (mimetype)
     *
     * @type {string}
     * @memberof FileUploadComponent
     */
    @Input()
    tipoArquivoPermitido: string;

    /**
     * O tamanho permitido do arquivo
     * esse valor é definido em bytes 
     * 
     * @example 5000000 - 5MB
     *
     * @type {string}
     * @memberof FileUploadComponent
     */
    @Input()
    maxFileSize: string | number = 5000000;

    @Output() eventoEnviado = new EventEmitter<HttpResponse<Object>>();



    ngOnInit(): void {
    }

    public onFileSelected(event): void {

        const file: File = event.target.files[0];
        const reader: FileReader = new FileReader();
        const url = '/rest/inscricoes/' + this.proponente + '/' + this.codigo + '/participantes/proponente/informacoes-documentos';
        const http = this.http;
        const documentoExigido = this.documentoExigido;
        const localEventEmitter = this.eventoEnviado;

        if (file) {

            if (typeof this.maxFileSize !== 'undefined' && (Number(this.maxFileSize) < file.size)) {
                alert('O arquivo é maior que o permitido.');
                return;
            }
            // remove espacos
            this.fileName = file.name;
            this.fileName = this.fileName.replace(/\s/gm, "_");
            this.fileName = this.fileName.replace(/((\W)(?!\w))/gm, "");
            const fileName = this.fileName;

            reader.readAsDataURL(file);
            reader.onload = function () {
                const rawData = reader.result;

                // verifica se é um PDF REAL
                if (typeof rawData === 'string') {
                    const pdfTest = rawData.substring(0, 20);
                    const pdfTestHeader = rawData.substring(28, 43);
                    const decode = (str: string): string => Buffer.from(str, 'base64').toString('binary');

                    if (pdfTest !== 'data:application/pdf' || (!/^%PDF-/.test(decode(pdfTestHeader)))) {
                        alert('Arquivo corrompido ou não é compativel');
                        event.target.files[0] = null;
                        return;
                    }
                }

                if (fileName.length > 100) {
                    alert('O nome do arquivo possui tamanho maior que 100 caracteres.');
                    event.target.files[0] = null;
                    return;
                }
                if (/(\½|\§|\£)/gm.test(fileName)) {
                    alert('O nome do arquivo possui caracteres não permitidos.');
                    event.target.files[0] = null;
                    return;
                }

                const documento: UploadInformacaoDocumento = {
                    file: {
                        content: reader.result,
                        nome: fileName,
                        type: file.type
                    },
                    documento_exigido: documentoExigido,
                    codigo: null,
                    id: null,
                };

                const upload$ = http.post(url, documento, { observe: 'response' });
                upload$.subscribe((d) => {
                    localEventEmitter.emit(d);
                });
            };
        }
    }

}


export interface UploadInformacaoDocumento {
    codigo?: string;
    file?: ArquivoPayload;
    id?: number;
    documento_exigido?: ParametroDocumentosExigidos;
    nome?: string;
    ultima_alteracao?: string;
}

export interface ArquivoPayload {
    content: string | ArrayBuffer;
    nome: string;
    type: string | "application/pdf";
}