import {BaseResourceModel} from '@sicapes/core';
import {Pessoa} from '../corporativo/pessoa.model';
import {Situacao} from './situacao.model';

export class Inscricao extends BaseResourceModel {
    constructor(
        public id?: number,
        public chave_parametrizacao?: string,
        public sigla_edital?: string,
        public sigla_modalidade_bolsa?: any,
        public codigo?: string,
        public ultima_alteracao?: string,
        public numero_processo?: string,
        public proponente?: Pessoa,
        public descricao?: string,
        public situacao_atual?: Situacao,
        public situacoes?: Situacao[],
        public formularios?: any[]

    ) {
        super();
    }

    static fromJson(jsonData: any): Inscricao {
        return Object.assign(new Inscricao(), jsonData);
    }
}
