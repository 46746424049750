import {Component} from '@angular/core';

import {AuthService} from '@sicapes/auth';

import {ToastrService} from 'ngx-toastr';

import {Pessoa} from '../../../shared/models/corporativo/pessoa.model';
import {CorporativoService} from '../../../shared/service/corporativo/corporativo.service';

@Component({
    selector: 'app-dashboard-simulator',
    templateUrl: './dashboard-simulator.component.html',
    styleUrls: ['./dashboard-simulator.component.css']
})
export class DashboardSimulatorComponent {

    pessoaSimulator: any;
    currentUser: any;

    constructor(private toastr: ToastrService, public authService: AuthService, private corporativoService: CorporativoService) {
        this.authService.currentUser.subscribe((x: any) => this.currentUser = x);
    }

    public obterDadosPessoa(event: any): void {
        const cpf = event.target.value;
        const tipoDocumento = {id: 1};

        // if (cpf && (cpf.length < 11 || cpf.length > 11) && tipoDocumento && tipoDocumento.id === 1) {
        //     this.pessoaSimulator = null;
        //     this.toastr.error('Número do CPF deve conter 11 caracteres');
        //     return;
        // }

        // if (typeof cpf !== 'undefined' && cpf.match(/[a-z]/i) && tipoDocumento && tipoDocumento.id === 1) {
        //     this.pessoaSimulator = null;
        //     this.toastr.error('CPF deve conter apenas números');
        //     return;
        // }

        if (cpf && (cpf.length < 5 || cpf.length > 20) && tipoDocumento && tipoDocumento.id === 1) {
            this.pessoaSimulator = null;
            this.toastr.error('Documento inválido...');
            return;
        }

        if (typeof cpf === 'undefined' || cpf === '') {
            this.pessoaSimulator = null;
            return;
        }

        this.corporativoService.obterPessoa(cpf).subscribe(
            (resource: Pessoa) => {
                this.pessoaSimulator = resource;
            },
            (error) => this.toastr.error('Erro ao obter a pessoa do corporativo')
        );

    }

    public simularAcesso(): void {
        if (this.pessoaSimulator) {
            const currentUser = JSON.parse(<string> localStorage.getItem('currentUser'));
            currentUser.pessoaLogada = currentUser.corporativo;
            currentUser.corporativo = this.pessoaSimulator;
            currentUser.id = this.pessoaSimulator.codigo;
            localStorage.setItem('currentUser', JSON.stringify(currentUser));
            window.location.href = '/dashboard';
        } else {
            this.toastr.error('Nenhuma pessoa selecionada para simular acesso.');
            return;
        }
    }

    public removerAcessoSimulado(): void {
        const currentUser = JSON.parse(<string> localStorage.getItem('currentUser'));
        currentUser.corporativo = currentUser.pessoaLogada;
        currentUser.id = currentUser.pessoaLogada.codigo;
        delete currentUser.pessoaLogada;
        localStorage.setItem('currentUser', JSON.stringify(currentUser));
        window.location.href = '/dashboard';
    }

}
