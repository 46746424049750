import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DashboardRoutingModule } from "./dashboard-routing.module";
import { DashboardPainelComponent } from "./dashboard-painel/dashboard-painel.component";
import { DashboardSimulatorComponent } from "./dashboard-simulator/dashboard-simulator.component";
import { DashboardAbaEmPreenchimentoComponent } from "./dashboard-aba-em-preenchimento/dashboard-aba-em-preenchimento.component";
import { DashboardAbaCanceladaComponent } from "./dashboard-aba-cancelada/dashboard-aba-cancelada.component";
import { DashboardAbaFinalizadaComponent } from "./dashboard-aba-finalizada/dashboard-aba-finalizada.component";
import { DashboardAbaAbertaComponent } from "./dashboard-aba-aberta/dashboard-aba-aberta.component";
import { DashboardAbaEmRecursoComponent } from "./dashboard-aba-em-recurso/dashboard-aba-em-recurso.component";
import { DashboardAbaEmPreenchimentoSearchComponent } from "./dashboard-aba-em-preenchimento-search/dashboard-aba-em-preenchimento-search.component";
import { DashboardAbaEmAnaliseComponent } from "./dashboard-aba-em-analise/dashboard-aba-em-analise.component";
import { SharedModule } from "../../shared/shared.module";
import { MessagesModule } from "primeng/messages";
import { MessageModule } from "primeng/message";
import { BadgeModule } from "primeng/badge";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { AccordionModule } from "primeng/accordion";
import { TooltipModule } from "primeng/tooltip";
import { TabViewModule } from "primeng/tabview";
import { ProgressBarModule } from "primeng/progressbar";
import { ToastModule } from "primeng/toast"; 
import { DashboardAnaliseModalComponent } from './dashboard-aba-em-analise/modal/dashboard-analise-modal.component';
import { DashboardRecursoModalComponent } from './dashboard-aba-em-recurso/modal/dashboard-recurso-modal.component';

@NgModule({
  declarations: [
    DashboardPainelComponent,
    DashboardSimulatorComponent,
    DashboardAbaEmPreenchimentoComponent,
    DashboardAbaCanceladaComponent,
    DashboardAbaFinalizadaComponent,
    DashboardAbaAbertaComponent,
    DashboardAbaEmRecursoComponent,
    DashboardAbaEmPreenchimentoSearchComponent,
    DashboardAbaEmAnaliseComponent,
    DashboardAnaliseModalComponent,
    DashboardRecursoModalComponent,
  ],
  exports: [DashboardSimulatorComponent, DashboardAnaliseModalComponent,DashboardRecursoModalComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    TabViewModule,
    TooltipModule,
    ConfirmDialogModule,
    AccordionModule,
    BadgeModule,
    SharedModule,
    MessagesModule,
    MessageModule,
  ],
})
export class DashboardModule {}
