import { BaseResourceModel } from '@sicapes/core';
import { IdentificadorRegistrado } from './identificador-registrado.model';
import { CorreioEletronico } from './correio-eletronico.model';
import { Pais } from './pais.model';
import { Telefone } from './telefone.model';

export class Pessoa extends BaseResourceModel {
    constructor(
        public id?: number,
        public nome?: string,
        public nome_completo?: string,
        public tipo?: string,
        public codigo?: string,
        public nacionalidade?: any,
        public identificador_principal?: IdentificadorRegistrado,
        public correio_eletronico_principal?: CorreioEletronico,
        public telefone_principal?: Telefone,
        public pais?: Pais,
    ) {
        super();
    }

    static fromJson(jsonData: any): Pessoa {
        return Object.assign(new Pessoa(), jsonData);
    }
}
