import { BaseResourceModel } from '@sicapes/core';

export class ProdutoEsperadoModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public tipo?: string,
        public quantidade?: string,
        public ultima_alteracao?: string
) {
        super();
    }

    static fromJson(jsonData: any): ProdutoEsperadoModel {
        return Object.assign(new ProdutoEsperadoModel(), jsonData);
    }
}
