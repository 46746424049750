import {BaseResourceModel} from "@sicapes/core";
import { Programa } from './programa.model';
import { VinculoModalidadeBolsa } from './vinculo-modalidade-bolsa.model';
import { EditalFase } from "./edital-fase.model";

export class Edital extends BaseResourceModel {
    constructor(
        public id?: number,
        public nome?: string,
        public nomeIntl?: string,
        public descricao?: string,
        public sigla?: string,
        public sigla_edital?: string,
        public numero?: string,
        public grupoFomento?: string,
        public url?: string,
        public emailContato?: string,
        public inicioVigencia?: Date,
        public terminoVigencia?: Date,
        public chave_parametrizacao?: any,
        public vinculosModalidadesBolsa?: VinculoModalidadeBolsa[],
        public programa?: Programa,
        public fases?: Array<EditalFase>
    ) {
        super();
    }

    static fromJson(jsonData: any): Edital {
        return Object.assign(new Edital(), jsonData);
    }
}
