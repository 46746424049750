import { BaseResourceModel } from '@sicapes/core';
import { GrauAcademicoModel } from './grau-academico.model';
import { AreaConhecimentoModel } from './area-conhecimento.model';
import { Pais } from './pais.model';
import { InstituicaoEnsinoModel } from './instituicao-ensino.model';
import { InscricaoTituloModel } from '../../../pages/formularios/inscricao-titulo/shared/models/inscricao-titulo.model';

export class TituloModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public inicio?: string,
        public termino?: string,
        public grau_academico?: GrauAcademicoModel,
        public area_conhecimento?: AreaConhecimentoModel,
        public ies?: InstituicaoEnsinoModel,
        public nome_ies_exterior?: string,
        public pais?: Pais,
        public inscricaoTitulo?: InscricaoTituloModel,
        public checkUtilizado?: any,
    ) {
        super();
    }

    static fromJson(jsonData: any): TituloModel {
        // @ts-ignore
        return Object.assign(new TituloModel(), jsonData);
    }
}
