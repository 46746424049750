import { ParametroSituacao } from "./parametro-situacao.model";
import { ParametroRecurso } from "./parametro-recurso.model";
import { ParametroIes } from "./parametro-ies.model";
import { ParametroItemDespesa } from "./parametro-item-despesa.model";
import { ParametroItemCronograma } from "./parametro-item-cronograma.model";
import { ParametroTipoParticipante } from "./parametro-tipo-participante.model";
import { ParametroProjetoBolsa } from "./parametro-projeto-bolsa.model";
import { ParametroProjetoBolsaAno } from "./parametro-projeto-bolsa-ano.model";
import { ParametroProjetoPais } from "./parametro-projeto-pais.model";
import { BaseResourceModel } from "@sicapes/core";
import { Edital } from "../edital/edital.model";
import { ParametroAreasTematica } from "./parametro-projeto-area-tematica.model";

export class Parametrizacao extends BaseResourceModel {
    constructor(
        public id?: number,
        public chave_parametrizacao?: string,
        public tipo_parametrizacao?: string,
        public quantidade_maxima_solicitacao?: number,
        public tipo_solicitacao_proposta?: string,
        public data_bloqueada_estudos_prentidods?: boolean,
        public data_limite_inicio_solicitacao?: any,
        public editar_acompanhamento?: boolean,
        public possui_parametro_item_despesa?: boolean,
        public possui_parametro_projeto_bolsa?: boolean,
        public possui_vinculo_item_cronograma?: boolean,
        public quantidade_maxima_meses?: any,
        public quantidade_maxima_bolsas?: any,
        public valor_maximo_orcamento?: any,
        public tipo_integracao_bolsa?: any,
        public solicita_taxa?: boolean,
        public valor_maximo_taxa?: any,
        public edital?: Edital,
        public parametros_ies?: Array<ParametroIes>,
        public parametros_situacoes?: Array<ParametroSituacao>,
        public parametro_recurso?: ParametroRecurso,
        public parametros_itens_despesas?: Array<ParametroItemDespesa>,
        public parametros_itens_cronogramas?: Array<ParametroItemCronograma>,
        public parametros_tipos_participante?: Array<ParametroTipoParticipante>,
        public parametros_projetos_bolsas?: Array<ParametroProjetoBolsa>,
        public parametros_projetos_bolsas_anos?: Array<ParametroProjetoBolsaAno>,
        public parametros_projetos_paises?: Array<ParametroProjetoPais>,
        public parametros_areas_tematicas?: ParametroAreasTematica[],
        public vinculosModalidadesBolsa?: any[]
    ) {
        super();
        this.vinculosModalidadesBolsa = vinculosModalidadesBolsa || [];
    }

    static fromJson(jsonData: any): Parametrizacao {
        const parametrizacao = new Parametrizacao();

        if (jsonData) {
            Object.assign(parametrizacao, jsonData);

            if (jsonData.edital) {
                parametrizacao.edital = Edital.fromJson(jsonData.edital);
            }

            if (jsonData.vinculosModalidadesBolsa) {
                parametrizacao.vinculosModalidadesBolsa = jsonData.vinculosModalidadesBolsa;
            } else {
                console.warn('Vinculos Modalidades Bolsa não encontrados no JSON ou estão indefinidos.');
            }
        }
        return parametrizacao;
    }
}
