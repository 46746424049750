import { BaseResourceModel } from '@sicapes/core';
import { Finalidade } from './finalidade.model';
import { InscricaoTelefone } from '../../../pages/formularios/inscricao-telefone/shared/models/inscricao-telefone.model';

export class Telefone extends BaseResourceModel {
    constructor(
        public id?: number,
        public codigo?: any,
        public codigo_area?: string,
        public finalidade?: Finalidade,
        public numero?: string,
        public tipo?: string | "C" | "X" | "F",

        public principal?: string,
        public principal_pessoa?: any,
        public inscricaoTelefone?: InscricaoTelefone,
        public checkUtilizado?: any
    ) {
        super();
    }

    static fromJson(jsonData: any): Telefone {
        return Object.assign(new Telefone(), jsonData);
    }
}
