import { Injectable, Injector } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { BaseResourceService } from '@sicapes/core';

import { Parametrizacao } from '../../models/parametrizacao/parametrizacao.model';
import { ParametroFormularioPendencia } from '../../models/parametrizacao/parametro-formulario-pendencia.model';
import { InscricaoService } from '../inscricao/inscricao.service';
import {
    ParametrizacaoAtivaViewModel
} from '../../models/view/parametrizacao-ativa-view.model';

@Injectable({
    providedIn: 'root'
})
export class ParametrizacaoService extends BaseResourceService<Parametrizacao> {

    tpVinculoPadrao = { sigla: 'IN', descricao: 'PROPOSTAS INDEPENDENTES ENTRE SI' };
    qtMaxPropostaPadrao: number = 1;


    readonly TIPO_PARAM_INDIVIDUAL: string = 'Individual';
    readonly TIPO_PARAM_PROJETO: string = 'Projeto';

    readonly TIPO_VINCULO_PROPOSTA_INDEPENDENTE: string = 'independente';
    readonly TIPO_VINCULO_PROPOSTA_DEPENDENTE: string = 'dependente';

    constructor(protected injector: Injector, protected inscricaoService: InscricaoService) {
        super('rest/parametrizacoes', injector, Parametrizacao.fromJson);
    }

    getAll(page: number): Observable<any> {
        return this.http.get(this.apiPath + '?page_size=' + page);
    }

    getAllSearch(page: number, resource: Parametrizacao): Observable<any> {
        let httpParams = new HttpParams();
        Object.keys(resource).forEach(function (key) {
            // @ts-ignore
            if (resource[key]) {
                // @ts-ignore
                httpParams = httpParams.append(key, resource[key]);
            }
        });

        if (httpParams.toString()) {
            return this.http.get(this.apiPath + '?page_size=' + page + '&' + httpParams.toString());
        }

        return this.http.get(this.apiPath + '?page_size=' + page);

    }

    update(resource: Parametrizacao): Observable<Parametrizacao> {
        // @ts-ignore
        const url = `${this.apiPath}/${resource.edital.sigla}`;
        return this.http.put(url, resource).pipe(
            map((result) => result),
            catchError(this.handleError)
        );
    }

    criarParametrizacao(chaveParametrizacao: any, tipoParametrizacao: any) {

        var parametrizacao = {
            sigla_edital: chaveParametrizacao.edital,
            sigla_modalidade_bolsa: chaveParametrizacao.modalidade_bolsa,
            quantidade_maxima_propostas: this.qtMaxPropostaPadrao,
            tipo_vinculo_propostas: this.tpVinculoPadrao,
            tipo_parametrizacao: tipoParametrizacao,
            parametro_recurso: {
                recurso_obrigatorio: false,
                exibir_campo: false,
                exibir_doc_analise: false,
                exibir_anexos: false,
                exibir_situacao: false,
                anexo_obrigatorio: false,
                situacao_recurso: null
            }
        };

        return this.http.post(this.apiPath, parametrizacao);
    }

    removerParametrizacaoIes(id: number): Observable<any> {
        const url = 'rest/parametrizacoes/ies/' + id;

        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }


    removerParametrizacaoItemCronograma(id: number): Observable<any> {
        const url = 'rest/parametrocronogramas/' + id;

        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    removerParametrizacaoItemOrcamento(id: number): Observable<any> {
        const url = 'rest/parametrodespesas/' + id;

        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    removerParametrizacaoTipoParticipante(id: number): Observable<any> {
        const url = 'rest/parametrizacoes/tipos-participante/' + id;

        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    incluirParametrizacaoPendenciaForm(pendencia: ParametroFormularioPendencia): any {
        const url = 'rest/parametroformulariopendencia';
        return this.http.post(url, pendencia).pipe(
            map(() => null),
            catchError(this.handleError)
        );

    }

    excluirParametroFormularioPendencia(pendencia: ParametroFormularioPendencia): any {
        const url = 'rest/parametroformulariopendencia/' + pendencia.id;
        return this.http.delete(url).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    checkParametrizacaoProjeto(tpParametrizacao: string): boolean {
        if (typeof tpParametrizacao === 'undefined') {
            return false;
        }

        return tpParametrizacao === this.TIPO_PARAM_PROJETO;
    };

    checkParametrizacaoIndividual(tpParametrizacao: string): boolean {
        if (typeof tpParametrizacao === 'undefined') {
            return false;
        }
        return tpParametrizacao === this.TIPO_PARAM_INDIVIDUAL;
    }

    checkParametrizacaoPrivado(parametrizacao: any): boolean {
        if (typeof parametrizacao === 'undefined') {
            return false;
        }
        return (parametrizacao.edital_fechado > 0);
    }

    obterParametrizacoesAtiva(proponente: string): any {
        const url = '/rest/parametrizacoes/ativa/' + proponente + '?page_size=-1';
        return this.http.get(url).pipe(
            map(this.jsonDataToResourcesParametrizacaoAtiva.bind(this)),
            catchError(this.handleError)
        );
    }

    /**
     * @TODO: Verificar a possibilidade se for o caso de criar models para todas as views do sistema
     * @param jsonData
     * @protected
     */
    protected jsonDataToResourcesParametrizacaoAtiva(jsonData: any): ParametrizacaoAtivaViewModel[] {
        const resources: ParametrizacaoAtivaViewModel[] = [];

        jsonData._embedded.items.forEach(
            (element: any) => {
                resources.push(ParametrizacaoAtivaViewModel.fromJson(element));
            }
        );
        return resources;
    }


    // Implementação para pegar parametrizacao somente uma vez quando estiver navengando na inscrição.
    public parametrizacao: BehaviorSubject<Parametrizacao> = new BehaviorSubject<Parametrizacao>(new Parametrizacao);
    private __parametrizacao: Parametrizacao | undefined;

    public obterParametrizacao(proponente: string, codigo: string): any {
        // 1. Chama get da inscricao para poder preparar o request da parametrização
        this.inscricaoService.obterInscricao(proponente, codigo);
        // 2. Espera a resposta do InscricaoService para inscrição.
        this.inscricaoService.inscricao.subscribe(inscricao => {
            // 3. Se possuir inscrição no InscricaoService chama a parametrização 
            if ((typeof inscricao.id !== 'undefined' && inscricao !== null) && (!this.cachedParametrizacaoExists() || (this.cachedParametrizacaoExists() && !this.cachedParametrizacaoIsSame(inscricao.chave_parametrizacao)))) {
                this.obterParametrizacaoInscricao(inscricao.chave_parametrizacao).subscribe(resolve => {
                    // 4. Set cached parametrizacao
                    this.__parametrizacao = resolve;
                    // 5. Emit signal de parametrizacao alterada
                    this.parametrizacao.next(this.__parametrizacao);
                }, reject => {});
            }
        });
    }
    /**
     *
     *
     * @param {*} chaveParametrizacao
     * @returns {Observable<Parametrizacao>}
     * @memberof ParametrizacaoService
     */
    public obterParametrizacaoInscricao(chaveParametrizacao: any): Observable<Parametrizacao> {
        const url = '/rest/parametrizacoes/' + chaveParametrizacao;
        return this.http.get(url).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError((error) => {
                return this.handleError(error);
            })
        );
    }
    /**
     * Verifica se existe parametrizacao cacheada
     *
     * @private
     * @returns boolean
     * @memberof ParametrizacaoService
     */
    private cachedParametrizacaoExists(): boolean {
        return !(typeof this.__parametrizacao === 'undefined');
    }
    /**
     *
     *
     * @private
     * @param {string} chave_parametrizacao
     * @returns boolean
     * @memberof ParametrizacaoService
     */
    private cachedParametrizacaoIsSame(chaveParametrizacao: string): boolean {
        if (typeof this.__parametrizacao === 'undefined')
            return false;
        if (chaveParametrizacao === this.__parametrizacao.chave_parametrizacao)
            return true;
        return false;
    }
    /**
     *
     *
     * @returns Parametrizacao | null
     * @memberof ParametrizacaoService
     */
    public getParametrizacao(): Parametrizacao | null {
        if (typeof this.__parametrizacao.id === 'undefined') return null;
        return this.__parametrizacao;
    }

    public checkTipoSolicitacaoDependente(parametrizacao: any): boolean {
        if (typeof parametrizacao === 'undefined') {
            return false;
        }

        return parametrizacao.tipo_solicitacao_proposta === this.TIPO_VINCULO_PROPOSTA_DEPENDENTE;
    }

    public salvarSolicitacaoAcesso(edital, pessoa, instituicao, funcao, situacao, documento): Observable<any> {
        let url = '/rest/acesso/' + edital;
        const postData = { edital: edital, pessoa: pessoa, instituicao: instituicao, funcao: funcao, situacao: situacao, documento: documento };
    
        return this.http.post(url, postData, { observe: 'response' });
    }

    public obterInstituicoesIndicacaoVagas(edital: string): any {
        const url = '/rest/parametrizacoes/'+ edital +'/indicacao-instituicao';
        return this.http.get(url).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public obterIndicacaoVagas(params): any {
        let edital = params.siglaEdital;
        let idIes = params.ies;
        const objparams = {
            modalidadeBolsa: params.modalidadeBolsa.id
        }
        const url = '/rest/parametrizacoes/'+ edital + '/' + idIes +'/indicacao-vaga';
        return this.http.get(url, { params: objparams }).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }
    
}