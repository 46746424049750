import { BaseResourceModel } from '@sicapes/core';
import {Inscricao} from './inscricao.model';
import {PalavraChaveModel} from './palavra-chave.model';

export class InscricaoPalavraChaveModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public inscricao?: Inscricao,
        public palavra_chave?: PalavraChaveModel
) {
        super();
    }

    static fromJson(jsonData: any): InscricaoPalavraChaveModel {
        return Object.assign(new InscricaoPalavraChaveModel(), jsonData);
    }
}
