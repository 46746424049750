import {BaseResourceModel} from '@sicapes/core';
import { ParametroDocumentosExigidos } from '../parametrizacao/parametro-documentos-exigidos.model';

export class InformacaoDocumentoModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public codigo?: string,
        public nome?: string,
        public documento_exigido?: ParametroDocumentosExigidos,
        public ultima_alteracao?: string,
        public file?: any
    ) {
        super();
    }

    static fromJson(jsonData: any): InformacaoDocumentoModel {
        return Object.assign(new InformacaoDocumentoModel(), jsonData);
    }
}