import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {GlobalConstants} from '../globals/global-constants';


@Injectable()
export class PedenciaInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(map((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                this.modifyConstantXPedencia(event);
            }
            return event;
        }));
    }

    private modifyConstantXPedencia(event: any): void {
        if (event.headers.get('x-pendencia-api') !== null && event.headers.get('x-pendencia-api') !== 'W10=') {
            GlobalConstants.xPedencia = event.headers.get('x-pendencia-api');
        }
    }
}