<div class="panel panel-default" *ngIf="authService.isGestorIndividual()">
  <div class="panel-heading"><i class="glyphicon glyphicon-user"></i> SIMULAR ACESSO</div>
  <div class="panel-body">
    <div class="row">
      <div class="col-md-12" *ngIf="!currentUser.pessoaLogada">
        <div class="col-md-6">
          <div class="form-group">
            <label>Informe o número do CPF</label>
            <input required class="form-control" type="text" (blur)="obterDadosPessoa($event)"/>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <button style="margin-top: 25px;" class="btn btn-primary" (click)="simularAcesso();" [disabled]="!pessoaSimulator">
              <i class="glyphicon glyphicon-user"></i> Simular acesso como {{pessoaSimulator?.nome}}
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="currentUser.pessoaLogada">
        <div class="col-md-3">
          <div class="form-group">
            <button style="margin-top: 25px;" class="btn btn-danger" (click)="removerAcessoSimulado();">
              <i class="glyphicon glyphicon-user"></i> Remover Acesso como {{currentUser.corporativo.nome}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
