import {Component, OnInit} from '@angular/core';
import {environment} from '../environments/environment';
import {AuthService} from '@sicapes/auth';
import {ChildActivationEnd, Event, Router} from '@angular/router';
import {InscricaoService} from './shared/service/inscricao/inscricao.service';
import {PrimeNGConfig} from 'primeng/api';

import {GlobalConstants} from './shared/globals/global-constants';
import {ToastrService} from 'ngx-toastr';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'frontend';
  currentUser: any;
  ambiente: string;
  proponente: any;
  codigo: any;
  menu: any;
  subMenu: any;
  constructor( private authService: AuthService,
               private router: Router,
               private inscricaoService: InscricaoService,
               private toastr: ToastrService,
               private config: PrimeNGConfig) {
    this.authService.currentUser.subscribe((x: any) => this.currentUser = x);
    this.ambiente = environment.ambiente;

    this.obterMenuInscricao();
  }

  ngOnInit(): void {
    this.config.setTranslation(GlobalConstants.ptBR);
  }

  public obterMenuInscricao(): void {

    this.router.events.subscribe((event: Event) => {
      if (event instanceof ChildActivationEnd) {
        if (event.snapshot.params.proponente !== undefined && event.snapshot.params.codigo !== undefined) {

          this.proponente = event.snapshot.params.proponente;
          this.codigo = event.snapshot.params.codigo;

          if (!this.menu) {
            this.inscricaoService.obterFormulariosInscricao(event.snapshot.params.codigo).subscribe(
                (result: any) => {
                  // Seta os dados da inscricao ativa
                  this.inscricaoService.setInscricaoAtiva({ c: this.codigo, p: this.proponente, f: [...result._embedded.items] });
                  this.montarMenu(result._embedded.items);
                },
                (error: any) => this.toastr.error('Não foi possivel recuperar os formulários.')
            );
          }
        }
      }
    });

  }

  private montarMenu(menu: any): any {

    const newMenu = [] as any;
    let subMenu = [] as any;
    let menuAtual: number = 0;
    const arrUrl = this.router.url.split('/');
    const rotaAtual: string = arrUrl[arrUrl.length - 1];
    menu.forEach((formulario: any, index: any) =>
        {
          const hasFormulario = newMenu.find( (m: any) => m.sg_tipo_formulario_pai === formulario.sg_tipo_formulario_pai );
          if (hasFormulario === undefined) {
            subMenu = [];
            newMenu.push({
              sg_tipo_formulario: formulario.sg_tipo_formulario,
              sg_tipo_formulario_pai: formulario.sg_tipo_formulario_pai,
              nm_formulario_pai: formulario.nm_formulario_pai,
              id_tipo_formulario_pai: formulario.id_tipo_formulario_pai
            });
          }
          if (formulario.sg_tipo_formulario == rotaAtual) {
            menuAtual = newMenu.length - 1;
          }
          subMenu.push(formulario);
          newMenu[newMenu.length - 1].subMenu = subMenu;
        }
    );
    this.subMenu = newMenu[menuAtual].subMenu;
    this.menu = newMenu;
  }

  public changeOfRoutes(): any {
    this.montarSubMenu();
  }

  private montarSubMenu(): any {
    if (this.menu) {
      const arrUrl = this.router.url.split('/');
      const rotaAtual: string = arrUrl[arrUrl.length - 1];
      let booHasSubMenu: boolean = false;
      this.menu.forEach((menu: any, i: any) => {
        if (!booHasSubMenu) {
          menu.subMenu.forEach((subMenu: any, i: any) => {
            if (subMenu.sg_tipo_formulario == rotaAtual) {
              this.subMenu = menu.subMenu;
              booHasSubMenu = true;
            }
          });
        }
      });
    }
  }
}
