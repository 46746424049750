import {BaseResourceModel} from '@sicapes/core';
import { Pessoa } from '../corporativo/pessoa.model';
import { TipoInscricaoPessoa } from './tipo-inscricao-pessoa.model';
import {ParticipanteEvento} from './participante-evento.model';

export class Participante extends BaseResourceModel {
    constructor(
        public id?: number,
        public tipo?: TipoInscricaoPessoa,
        public pai?: Participante,
        public participantes_relacionados?: Participante[],
        public pessoa?: Pessoa,
        public qt_atuacao_meses?: number,
        public participante_vinculo?: any,
        public ultima_alteracao?: string,
        public participantes_evento?: ParticipanteEvento[]
    ) {
        super();
    }

    static fromJson(jsonData: any): Participante {

        if (jsonData.hasOwnProperty('body')) {
            jsonData = jsonData.body;
        }

        return Object.assign(new Participante(), jsonData);

    }
}
