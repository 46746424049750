import { Injectable, Injector } from '@angular/core';

import { BaseResourceService } from '@sicapes/core';

import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

import {InscricaoPalavraChaveModel} from '../../models/inscricao/inscricao-palavra-chave.model';
import {PalavraChaveModel} from '../../models/inscricao/palavra-chave.model';

@Injectable({
    providedIn: 'root'
})
export class PalavraChaveService extends BaseResourceService<PalavraChaveModel> {

    constructor(protected injector: Injector) {
        super('rest/palavras-chave', injector, PalavraChaveModel.fromJson);
    }

    public obterPalavrasChave(descricao: string, pageSize: any): Observable<PalavraChaveModel[]> {

        if (!pageSize) { pageSize = -1; }

        return this.http.get(this.apiPath + '?page_size=' + pageSize + '&descricao=' + descricao ).pipe(map((end: any) => {
            return end._embedded.items.map((r: any) => {
                return Object.assign(new PalavraChaveModel(), r);
            });
        }));
    }

}
