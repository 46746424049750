import {Component} from '@angular/core';
import {ParametrizacaoService} from '../../shared/service/parametrizacao/parametrizacao.service';
import {ParticipanteService} from '../../shared/service/inscricao/participante.service';
import {ToastrService} from 'ngx-toastr';
import {Dashboard} from '../../shared/models/dashboard/dashboard.model';
import {ConfirmationService, ConfirmEventType} from 'primeng/api';
import {InscricaoService} from '../../shared/service/inscricao/inscricao.service';
import * as moment from "moment/moment";

@Component({
    template: ''
})
export abstract class BaseDashboardComponent {

    constructor(
        protected parametrizacaoService: ParametrizacaoService,
        protected participanteService: ParticipanteService,
        protected inscricaoService: InscricaoService,
        protected confirmationService: ConfirmationService,
        protected toastr: ToastrService
    ) { }

    isParametrizacaoIndividual(tpParametrizacao: string): any {
        return this.parametrizacaoService.checkParametrizacaoIndividual(tpParametrizacao);
    }

    isParametrizacaoProjeto(tpParametrizacao: string): any {
        return this.parametrizacaoService.checkParametrizacaoProjeto(tpParametrizacao);
    }

    isParametrizacaoPrivada(parametrizacao: any): boolean {
        return this.parametrizacaoService.checkParametrizacaoPrivado(parametrizacao);
    }

    emitirComprovante(inscricao: any): any {
        this.participanteService.recuperarInformacaoDocumento(inscricao.ds_identificador_registrado, inscricao.cd_inscricao).subscribe(
            resource => {
                const documentoInscricao = resource.items.filter((doc: any) => {
                    return doc.documento_exigido.tipo.id === 555;
                })[0];
                this.participanteService.verDocumento(inscricao.ds_identificador_registrado, inscricao.cd_inscricao, documentoInscricao.id);
            },
            error => this.toastr.warning('Não foi possível localizar o Comprovante de Inscrição')
        );
    }

    public cancelarInscricao(inscricao: Dashboard): void {
        this.confirmationService.confirm({
            header: 'Confirmação',
            message: 'Cancelar inscrição?',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'Sim',
            rejectLabel: 'Não',
            rejectButtonStyleClass: 'p-button-outlined',
            accept: () => {
                this.inscricaoService.cancelarInscricao(inscricao.cd_inscricao).subscribe(
                    (inscricao: any) => {
                        if (inscricao.status) {
                            let error = inscricao;
                            if (inscricao.error) {
                                error = inscricao.error;
                            }
                            this.toastr.error(error.detail);
                        } else {
                            this.toastr.success('Inscrição cancelada com sucesso.');
                            window.location.reload();
                        }
                    },
                    (error: any) => this.toastr.error(error.error.detail)
                )
            },
            reject: (type: any) => {
                switch (type) {
                    case ConfirmEventType.REJECT:
                        this.toastr.error('Você não cancelou a inscrição.');
                        break;
                    case ConfirmEventType.CANCEL:
                        this.toastr.warning('Você não confirmou o cancelamento da inscrição.');
                        break;
                }
            }
        });
    }

    public formataData(data: string): string {
        return (moment(data)).format('DD/MM/YYYY HH:mm:ss');
    }
}
