<div class="row">
    <div class="col-md-12">
        <h3>Referências</h3>
        <span class="help-block"> Listar, conforme as normas da ABNT, as obras e autores consultados e citados no projeto</span>
    </div>
    <div class="col-md-12">
        <button class="btn btn-default" (click)="openModal({})">
            Adicione uma ou mais referências
        </button>
    </div>
</div>
<br/>
<div class="alert alert-info" *ngIf="referenciasBibliograficas.length <= 0">Não existem referências</div>
<div class="table-responsive">
    <table class="table table-bordered table-hover table-striped">
        <thead>
        <tr>
            <th>Descrição</th>
            <th width="100">Ação</th>
        </tr>
        </thead>
        <tbody *ngFor="let rb of referenciasBibliograficas; let i = index">
            <tr>
                <td>{{rb.descricao}}</td>
                <td class="text-center" style="white-space: nowrap">
                    <div class="btn-group btn-group-sm">
                        <button type="button" class="btn btn-default" pTooltip="Editar" (click)="openModal(rb)">
                            <i class="glyphicon glyphicon-edit"></i>
                        </button>
                        <button type="button" class="btn btn-danger" pTooltip="Remover" (click)="excluirReferenciaBibliografica(rb)">
                            <i class="glyphicon glyphicon-trash"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<p-confirmDialog #cd [style]="{width: '20vw'}">
    <ng-template pTemplate="header">
        <h3>Confirmação</h3>
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" label="Não" (click)="cd.reject()"></button>
        <button type="button" pButton icon="pi pi-check" label="Sim" (click)="cd.accept()"></button>
    </ng-template>
</p-confirmDialog>


<p-dialog [(visible)]="display" [modal]="true" [style]="{width: '30vw'}" [maximizable]="true">
    <div class="modal-body">
        <fieldset>
            <legend>{{tituloModalEndereco}}</legend>

            <form [formGroup]="resourceForm">
                <div class="row">
                    <div class="col-md-12 form-group required">
                        <label for="descricao">Referência <span class="badge">{{descricao.value.length||0}} / {{maxCaracteres}}</span></label>
                        <textarea [maxlength]="maxCaracteres" class="form-control" required name="descricao" id="descricao" rows="5" formControlName="descricao" #descricao></textarea>
                    </div>
                </div>

                <div class="modal-footer">
                    <button class="btn btn-primary" value="Salvar" title="Salvar" [disabled]="submittingForm || resourceForm.invalid" (click)="submitForm()">
                        <span><i class="glyphicon glyphicon-floppy-disk"></i> Salvar</span>
                    </button>
                    <button type="button" class="btn btn-danger" value="Cancelar" title="Cancelar" (click)="display=false">
                        <span><i class="glyphicon glyphicon-alert"></i> Cancelar</span>
                    </button>
                </div>

            </form>
        </fieldset>

    </div>



</p-dialog>