import { BaseResourceModel } from '@sicapes/core';

export class InscricaoContextoProjetoModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public acao?: string,
        public acompanhamento?: string,
        public inicio?: string,
        public termino?: string,
        public ameaca?: string,
        public aplicacao?: string,
        public apontamento?: string,
        public apresentacao?: string,
        public areas_prioritarias?: string,
        public atuacao?: string,
        public caracteristica?: string,
        public conhecimento_adquirido?: string,
        public consolidacao?: string,
        public contexto?: string,
        public execucao?: string,
        public fundamentacao?: string,
        public infraestrutura?: string,
        public insumo?: string,
        public interacao?: string,
        public justificativa?: string,
        public metodologia?: string,
        public objetivo?: string,
        public oportunidade?: string,
        public outros?: string,
        public parcerias?: string,
        public plano_acao?: string,
        public pontoforte?: string,
        public pontofraco?: string,
        public problema?: string,
        public produto?: string,
        public relevancia?: string,
        public resultados?: string,
        public resumo?: string,
        public sustentabilidade?: string,
        public tipo_projeto_cofen?: string,
        public ultima_alteracao?: string
) {
        super();
    }

    static fromJson(jsonData: any): InscricaoContextoProjetoModel {
        return Object.assign(new InscricaoContextoProjetoModel(), jsonData);
    }
}
