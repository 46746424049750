import { Component, DoCheck, Input } from '@angular/core';
import { GlobalConstants } from '../../globals/global-constants';

@Component({
    selector: 'app-message-system',
    templateUrl: './message-system.component.html',
    styleUrls: ['./message-system.component.less']
})
export class MessageSystemComponent implements DoCheck {

    @Input() mensagens: any[];
    @Input() formulario: string;

    arrMsg: any[] = [];
    arrPendencia: any[] = [];

    /** Verifica se GlobalConstants.xPedencia ou FORMULARIO é undefined
     * Se for sai do check para não haver erro
     */
    ngDoCheck(): void {

        this.arrMsg = (typeof this.mensagens !== 'undefined') ? this.mensagens : null;

        if (typeof GlobalConstants.xPedencia === 'undefined' || typeof this.formulario === 'undefined') return;

        if (GlobalConstants.xPedencia !== '' && !Array.isArray(GlobalConstants.xPedencia) && this.formulario !== '') {
            this.arrPendencia = JSON.parse(atob(GlobalConstants.xPedencia.toString()))[this.formulario];
        }

    }

}
