import {BaseResourceModel} from '@sicapes/core';
import {Pais} from './pais.model';
import { Finalidade } from './finalidade.model';
import {InscricaoEndereco} from '../../../pages/formularios/inscricao-endereco/shared/models/inscricao-endereco.model';

export class EnderecoModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public municipio?: any,
        public cep?: string,
        public bairro?: string,
        public logradouro?: string,
        public numero?: string,
        public complemento?: string,
        public finalidade?: Finalidade,
        public principal?: string,
        public pais?: Pais,
        public inscricaoEndereco?: InscricaoEndereco,
        public principal_pessoa?: any,
        public checkUtilizado?: any
    ) {
        super();
    }

    static fromJson(jsonData: any): EnderecoModel {
        return Object.assign(new EnderecoModel(), jsonData);
    }
}
