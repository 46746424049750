import { BaseResourceModel } from '@sicapes/core';
import {Inscricao} from './inscricao.model';

export class InscricaoCaracteristicaNovacaoModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public qtdeMes?: number,
        public ultima_alteracao?: string
) {
        super();
    }

    static fromJson(jsonData: any): InscricaoCaracteristicaNovacaoModel {
        return Object.assign(new InscricaoCaracteristicaNovacaoModel(), jsonData);
    }
}
