import { BaseResourceModel } from '@sicapes/core';
import { ItemBolsaModel } from './item-bolsa.model';
import { ItemOrcamentoModel } from './item-orcamento.model';

export class OrcamentoModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public valor_limite?: number,
        public bolsas_orcamento?: ItemBolsaModel[],
        public valor_contrapartida?: any,
        public auxilios_orcamento?: any[],
        public itens_orcamento?: ItemOrcamentoModel[],
        public itens_receita?: any[],
        public ultima_alteracao?: string

    ) {
        super();
    }

    static fromJson(jsonData: any): OrcamentoModel {
        return Object.assign(new OrcamentoModel(), jsonData);
    }
}
