import { BaseResourceModel } from '@sicapes/core';

export class PalavraChaveModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public descricao?: string
) {
        super();
    }

    static fromJson(jsonData: any): PalavraChaveModel {
        return Object.assign(new PalavraChaveModel(), jsonData);
    }
}
