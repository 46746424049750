import { Component, Input, DoCheck } from '@angular/core';
import {GlobalConstants} from '../../globals/global-constants';

@Component({
    selector: 'app-pendencia',
    templateUrl: 'pendencia.component.html',
    styleUrls: ['./pendencia.component.less']
})
export class PendenciaComponent implements DoCheck {

    @Input() formulario: string;

    arrPendencia: any[] = [];

    constructor() { }

    /** Verifica se GlobalConstants.xPedencia  ou FORMULARIO é undefined
     * Se for sai do check para não haver erro
     */
    ngDoCheck(): void {
        if (typeof GlobalConstants.xPedencia === 'undefined' || typeof this.formulario === 'undefined') return;
        if (GlobalConstants.xPedencia !== '' && !Array.isArray(GlobalConstants.xPedencia) && this.formulario !== '') {
            this.arrPendencia = JSON.parse(atob(GlobalConstants.xPedencia.toString()))[this.formulario];
        }
    }
}
