import {BaseResourceModel} from '@sicapes/core';
import {Pais} from './pais.model';
import {TipoIdentificadorRegistrado} from './tipo-identificador-registrado.model';

export class IdentificadorRegistrado extends BaseResourceModel {
    constructor(
        public id?: number,
        public descricao?: string,
        public experdicao?: string,
        public orgao_expedidor?: string,
        public pais?: Pais,
        public uf_orgao_expedidor?: string,
        public inicio_validade?: string,
        public fim_validade?: string,
        public documento_vigente?: string,
        public tipo?: TipoIdentificadorRegistrado
    ) {
        super();
    }

    static fromJson(jsonData: any): IdentificadorRegistrado {
        return Object.assign(new IdentificadorRegistrado(), jsonData);
    }
}
