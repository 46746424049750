import {BaseResourceModel} from '@sicapes/core';
import {Pais} from './pais.model';

export class InstituicaoEnsinoModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public nome?: string,
        public nome_completo?: string,
        public codigo?: string,
        public codigo_capes?: string,
        public faixa_igc?: string,
        public faixa_ci?: string,
        public sigla?: string,
        public pais?: Pais
    ) {
        super();
    }

    static fromJson(jsonData: any): InstituicaoEnsinoModel {
        return Object.assign(new InstituicaoEnsinoModel(), jsonData);
    }
}
