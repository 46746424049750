import {Component, EventEmitter, Injector, OnInit, Output} from '@angular/core';
import {BaseResourceComponent} from '@sicapes/core';

import {CaracteristicaInscricaoService} from '../../service/inscricao/caracteristica.inscricao.service';
import {InscricaoPalavraChaveModel} from '../../models/inscricao/inscricao-palavra-chave.model';
import {ParametrizacaoService} from '../../service/parametrizacao/parametrizacao.service';
import {InscricaoService} from '../../service/inscricao/inscricao.service';
import {PalavraChaveService} from '../../service/inscricao/palavra-chave.service';
import {PalavraChaveModel} from '../../models/inscricao/palavra-chave.model';
import {Inscricao} from '../../models/inscricao/inscricao.model';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-palavra-chave',
    templateUrl: './palavra-chave.component.html',
    styleUrls: ['./palavra-chave.component.less']
})
export class PalavraChaveComponent extends BaseResourceComponent implements OnInit {

    inseridos: InscricaoPalavraChaveModel[] = [];
    excluidos: InscricaoPalavraChaveModel[] = [];
    results: any[] = [];

    viewAutoComplete = false;

    @Output() dadosProjetosEvent = new EventEmitter<any>();

    constructor(protected injector: Injector,
                public parametrizacaoService: ParametrizacaoService,
                public inscricaoService: InscricaoService,
                protected caracteristicaInscricaoService: CaracteristicaInscricaoService,
                protected palavraChaveService: PalavraChaveService,
                protected toastr: ToastrService) {
        super(injector, parametrizacaoService, inscricaoService);
    }

    ngOnInit(): void {

        this.viewAutoComplete = false;
        this.excluidos = [];
        this.inseridos = [];

        // @ts-ignore
        this.initDadosInscricao().subscribe(
            (parametrizacao) => {
                if (parametrizacao.id) {
                    this.loadFormulario();
                }
            }
        );
    }

    protected loadFormulario(): void {
        this.initInscricaoPalavrasChave();
    }

    public initInscricaoPalavrasChave(): void {
        this.caracteristicaInscricaoService.obterInscricaoPalavrasChave(this.proponente, this.codigo).subscribe(
            (palavrasChave: InscricaoPalavraChaveModel[]) => {
                palavrasChave.forEach((p) => this.inseridos.push(p));
                this.dadosProjetosEvent.emit(this.inseridos);
                this.viewAutoComplete = true;
            },
            error => this.toastr.error('Não foi possivel recuperar as palabras chaves.')
        );
    }

    public pesquisaPalavraChave(descricao: any): void {
        if (descricao) {
            if (descricao.query.length >= 3) {
                this.palavraChaveService.obterPalavrasChave(descricao.query, -1).subscribe(
                    (result: PalavraChaveModel[]) => {
                        const arrString: InscricaoPalavraChaveModel[] = [];
                        result.forEach((r: PalavraChaveModel) => {
                            arrString.push(new InscricaoPalavraChaveModel(null, new Inscricao(this.inscricao.id), r));
                        });
                        this.results = arrString;
                    },
                    error => this.toastr.error('Não foi possivel recuperar as palavras chaves.')
                );
            }
        }
    }

    public onKeyUp(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            const tokenInput = event.srcElement as any;
            if (tokenInput.value) {
                this.inseridos.push(new InscricaoPalavraChaveModel(null, new Inscricao(this.inscricao.id), new PalavraChaveModel(null, tokenInput.value)));
                tokenInput.value = '';
                this.dadosProjetosEvent.emit(this.inseridos);
            }
        }
    }

    public salvarPalavraChave(): void {

        let countInseridos = 0;
        this.inseridos.forEach((v) => {
            if (!v.id) {
                countInseridos++;
            }
        });
        let countExcluido = this.excluidos.length;

        if (countExcluido > 0) {
            this.excluidos.forEach(
                (v) => {
                    if (v.id) {
                        countExcluido--;
                        if (countExcluido === 0 && countInseridos <= 0) {
                            this.excluirInscricaoPalavraChave(v, true);
                        } else {
                            this.excluirInscricaoPalavraChave(v);
                        }
                    }
                }
            );
        }

        if (countInseridos > 0) {
            this.inseridos.forEach(
                (v) => {
                    if (!v.id) {
                        countInseridos--;
                        if (countInseridos === 0) {
                            this.salvarInscricaoPalavraChave(v, true);
                        } else {
                            this.salvarInscricaoPalavraChave(v);
                        }
                    }
                }
            );
        }

    }

    private salvarInscricaoPalavraChave(inscricaoPalavraChave: InscricaoPalavraChaveModel, flagExInit: boolean = false): void {
        this.caracteristicaInscricaoService.salvarInscricaoPalavraChave(this.proponente, this.codigo, inscricaoPalavraChave)
            .subscribe((ipc) => {
                    if (flagExInit) {
                        this.ngOnInit();
                    }
                },
                error => this.toastr.error('Não foi possivel salvar a palavra chave.')
            );
    }

    private excluirInscricaoPalavraChave(inscricaoPalavraChave: InscricaoPalavraChaveModel, flagExInit: boolean = false): void {
        this.caracteristicaInscricaoService.excluirInscricaoPalavraChave(this.proponente, this.codigo, inscricaoPalavraChave)
            .subscribe((ipc) => {
                    if (flagExInit) {
                        this.ngOnInit();
                    }
                },
                error => this.toastr.error('Não foi possivel excluir a palavra chave.')
            );
    }

    public inserirPalavraChave(event: any): void {
        this.dadosProjetosEvent.emit(this.inseridos);
    }

    public excluirPalavraChave(event: any): void {
        this.excluidos.push(event);
        this.dadosProjetosEvent.emit(this.inseridos);
    }

}
