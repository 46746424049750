import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { AuthService, Usuario } from '@sicapes/auth';
import { ToastrService } from 'ngx-toastr';
import { InscricaoService } from '../../../shared/service/inscricao/inscricao.service';
import { ParametrizacaoService } from '../../../shared/service/parametrizacao/parametrizacao.service';
import { ParticipanteService } from '../../../shared/service/inscricao/participante.service';
import { InstituicaoEnsinoService } from '../../../shared/service/corporativo/instituicao-ensino.service';
import { InstituicaoEnsinoModel } from '../../../shared/models/corporativo/instituicao-ensino.model';
import { BaseDashboardComponent } from '../base-dashboard.component';
import { ConfirmationService } from 'primeng/api';
import { Router } from '@angular/router';
import {CsabService} from '../../../shared/service/csab/csab.service';

@Component({
    selector: 'app-dashboard-aba-aberta',
    templateUrl: './dashboard-aba-aberta.component.html',
    styleUrls: ['./dashboard-aba-aberta.component.css']
})
export class DashboardAbaAbertaComponent extends BaseDashboardComponent implements OnInit {

    @Input() rows: any[] = [];
    @Input() inscricoes: any;
    @Output() inscricaoEvent = new EventEmitter<any>();

    currentUser: any;
    visible: boolean;

    intituicoesEnsino: InstituicaoEnsinoModel[];
    rowsMemory: any[] = [];
    modalidadeBolsaAbertas: any[];
    programaCapesAbertas: any[];
    contentFile: any;
    fileUpload: any;
    parametrizacao: any;
    tiposParametrizacaoAbertas: any[] = [
        { descricao: 'Auxílio', tipo: 'A' },
        { descricao: 'Bolsa', tipo: 'I' },
        { descricao: 'Projeto', tipo: 'P' },
    ];
    tiposFuncaoSolicitarAcesso: any[] = [
        { descricao: 'Coordenador de Programa', id: 80 },
        { descricao: 'Pró-reitor', id: 42 },
        { descricao: 'Representante Institucional', id: 43 },
    ];
    filter: any = {
        tipo: null,
        modalidade: null,
        programa: null
    };
    solicitacaoAcessoForm = this.formBuilder.group({
        nome: [{ value: null, disabled: false }],
        ies: [{ value: null, disabled: false }, [Validators.required]],
        funcao: [{ value: null, disabled: false }, [Validators.required]],
        contentFile: [{ value: null, disabled: false }],
    });

    constructor(protected parametrizacaoService: ParametrizacaoService,
        protected participanteService: ParticipanteService,
        protected inscricaoService: InscricaoService,
        protected csabService: CsabService,
        protected instituicaoEnsinoService: InstituicaoEnsinoService,
        protected confirmationService: ConfirmationService,
        protected toastr: ToastrService,
        public authService: AuthService,
        protected router: Router,
        private formBuilder: FormBuilder) {
        super(parametrizacaoService, participanteService, inscricaoService, confirmationService, toastr);
        this.authService.currentUser.subscribe((x: any) => this.currentUser = x);
    }


    ngOnInit(): void {
        this.rowsMemory = this.rows;
        this.obterListaFiltro();
    }

    iniciarInscricao(parametrizacao: any): void {
        // validar inscricao NOVACAO
        if (parametrizacao.sg_programa_capes === 'NOVACAO') {
            this.csabService
                .obterNovacaoPessoa(this.currentUser.corporativo.id)
                .subscribe(e => {
                    if (e.length > 0) {
                        const processos: any[] = e.filter((p) => p.podeSolicitarNovacao === true);

                        if (processos.length <= 0) {
                            this.toastr.error('Você não está apto para o Novação.');
                            return;
                        } else {
                            this.iniciar(parametrizacao);
                        }
                    } else {
                        this.toastr.error('Você não está apto para o Novação.');
                        return;
                    }
                });
        } else {
            if (this.currentUser.id == null && parametrizacao.nm_tp_parametro_edital === 'Projeto') {
                this.toastr.error('Para inicar o preenchimento de uma inscrição de projeto, é obrigatório possuir um CPF vinculado ao seu cadastro.');
                return;
            }
            this.iniciar(parametrizacao);
        }
    }

    iniciar(parametrizacao: any): void {
        // removendo o id da parametrizacao para evitar um PUT:
        parametrizacao.id = null;

        this.inscricaoService.iniciarInscricao(parametrizacao).subscribe(
            (inscricao: any) => {
                // faço reload e faço o sistema expandir a nova inscriçao
                this.inscricaoEvent.emit(inscricao.codigo);

                // Redirect para dados pessoais
                const formulariosDadosPessoaisEsp: string[] = [
                    'inscricao-dados-pessoais',
                    'inscricao-dados-pessoais-brasileiros',
                    'inscricao-dados-pessoais-completos',
                    'inscricao-dados-pessoais-completos-dri',
                    'inscricao-dados-pessoais-dpb',
                ];
                const formularioDadosPessoais: any[] = inscricao.formularios.filter((f) => {
                    return formulariosDadosPessoaisEsp.indexOf(f.formulario_parametrizado.tipo.sigla) > -1;
                });
                if (formularioDadosPessoais.length > 0) {
                    this.router.navigate(['/inscricao', inscricao.proponente.codigo, inscricao.codigo, formularioDadosPessoais[0].formulario_parametrizado.tipo.sigla]);
                }
            },
            (error: any) => {
                this.toastr.error('Erro ao criar uma nova inscrição.');
            }
        );
    }

    isInscricaoEmPreenchimento(parametrizacao: any): boolean {
        const inscricoes: any[] = this.inscricoes.emPreenchimento;

        if (inscricoes.length === undefined) {
            return false;
        }

        return inscricoes.some(x => x.sg_edital === parametrizacao.sg_edital);

    }

    public obterListaFiltro(): void {

        this.modalidadeBolsaAbertas = Array.from(new Set(this.rows.map(a => a.modalidade)))
            .map(modalidade => {
                return this.rows.find(a => a.modalidade === modalidade);
            }
            );

        this.programaCapesAbertas = Array.from(new Set(this.rows.map(a => a.nm_programa_capes)))
            .map(nmProgramaCapes => {
                return this.rows.find(a => a.nm_programa_capes === nmProgramaCapes);
            }
            );

    }

    public filterLista(tipo): void {

        this.rows = this.rowsMemory;

        if (tipo === 'tipo') {
            this.filter.modalidade = null;
            this.filter.programa = null;
            if (this.filter.tipo !== null) {
                this.rows = this.rows.filter((row) => row.tp_parametro_edital === this.filter.tipo.tipo);
            }
        }

        if (tipo === 'modalidade') {
            this.filter.tipo = null;
            this.filter.programa = null;
            if (this.filter.modalidade !== null) {
                this.rows = this.rows.filter((row) => row.modalidade === this.filter.modalidade.modalidade);
            }
        }

        if (tipo === 'programa') {
            this.filter.modalidade = null;
            this.filter.tipo = null;
            if (this.filter.programa !== null) {
                this.rows = this.rows.filter((row) => row.nm_programa_capes === this.filter.programa.nm_programa_capes);
            }
        }

    }

    openModalSolicitarAcesso(parametrizacao: any): void {
        this.solicitacaoAcessoForm.get('nome').setValue(this.currentUser.corporativo.nome);
        this.visible = true;
        this.parametrizacao = parametrizacao;
    }

    enviarSolicitacaoAcesso(dados: any): void {
        this.visible = false;
    }

    public filterInstituicoesEnsino(e: any): void {
        this.instituicaoEnsinoService.getInstituicoesBrasileiras(e.query).subscribe(
            ies => {
                this.intituicoesEnsino = ies;
            },
            error => this.toastr.error(error.error.detail)
        );
    }

    public onFileSelected(event): void {
        this.contentFile = null;
        const file: File = event.target.files[0];
        const reader: FileReader = new FileReader();

        if (file) {
            reader.readAsDataURL(file);
            const toastr = this.toastr;
            
            
            reader.onload = () => {
                this.contentFile = reader.result;
                this.fileUpload = file;
                
                // verifica se é um PDF REAL
                if (typeof this.contentFile === 'string') {
                    const pdfTest = this.contentFile.substr(0, 20);
                    if (pdfTest !== 'data:application/pdf') {
                        toastr.error('Arquivo corrompido ou não é compativel');
                        return;
                    }
                }

                if (file.name.length > 100) {
                    toastr.error('O nome do arquivo possui tamanho maior que 100 caracteres.');
                    return;
                }
            };
        }
    }

    onSubmit(): void {
        // Process checkout data here
        console.warn('Your order has been submitted', this.solicitacaoAcessoForm.value);
        const siglaEdital = this.parametrizacao.sg_edital;
        const usuario = { id: this.currentUser.corporativo.id, nome: this.currentUser.corporativo.nome, codigo: this.currentUser.corporativo.codigo };
        const instituicao = { id: this.solicitacaoAcessoForm.get('ies').value.id, nome: this.solicitacaoAcessoForm.get('ies').value.nome, sigla: this.solicitacaoAcessoForm.get('ies').value.sigla };
        const funcao = this.solicitacaoAcessoForm.get('funcao').value;
        const situacao = { tipo: 'P', descricao: 'Pendente' };
        const arquivoDoc = this.prepareDoc();

        this.parametrizacaoService.salvarSolicitacaoAcesso(siglaEdital, usuario, instituicao, funcao, situacao, arquivoDoc).subscribe(
            acesso => {
                this.parametrizacao.cs_situacao_acesso = acesso.body.situacao_acesso;
                this.parametrizacao.dt_solicitacao_acesso = moment(acesso.body.ultima_alteracao, 'DD/MM/YYYY HH:mm:ss').format('YYYY-MM-DD[T]HH:mm:ss');
                this.toastr.success('Solicitação enviada com sucesso!');
            },
            error => this.toastr.error('Não foi possível enviar a solicitação de acesso.')
        );
        this.solicitacaoAcessoForm.reset();
    }

    private prepareDoc(): any {

        const body = {
            type: 'application/pdf',
            content: this.contentFile,
            nome: this.fileUpload.name,
        };

        return body;
    }

    public permiteAcesso(parametrizacao): boolean {
        let dt_limite_solicitacao = moment(parametrizacao.dt_fim_acesso);
        let agora = moment();
        if (dt_limite_solicitacao.isBefore(agora)) {
            return false;
        }
        if (parametrizacao.cs_situacao_acesso == 'I' || parametrizacao.cs_situacao_acesso == 'A') {
            return false;
        }
        if (parametrizacao.dt_inicio_acesso && parametrizacao.cs_situacao_acesso != 'P') {
            return true;
        }
        return false;
    }

    public permiteIniciarInscricao(parametrizacao): boolean {
        let dt_inicio_inscricao = moment(parametrizacao.dt_inicio_fase);
        let agora = moment();
        if (parametrizacao.cs_situacao_acesso == 'I' || dt_inicio_inscricao.isAfter(agora)) {
            return false;
        }
        if (!parametrizacao.dt_inicio_acesso || (parametrizacao.dt_inicio_acesso && parametrizacao.cs_situacao_acesso != 'P' && parametrizacao.cs_situacao_acesso != null)) {
            return true;
        }
        return false;
    }

    public dataMaiorQueHoje(data_comparacao): boolean {
        let agora = moment();
        return moment(data_comparacao).isAfter(agora);
    }

}
