<div class="modal-pareceres-emitidos">
    <div class="modal-pareceres-emitidos-header">
        <h5>Outros pareceres emitidos na análise</h5>
        <div class="modal-pareceres-emitidos-header-close">
            <a (click)="fecharModal()">X</a>
        </div>
    </div>
    <div *ngFor="let etapa of pareceresAnalise.pareceres" class="modal-pareceres-emitidos-etapa">
        <p class="modal-pareceres-emitidos-etapa-nome">{{etapa.nomeEtapa}}</p>
        <div class="modal-pareceres-emitidos-etapa-parecer">
            <span
                class="modal-pareceres-emitidos-etapa-parecer-tipo">{{etapa.pareceresDisponiveis.parecer.tipoParecer}}</span>
            <!-- -
            <span class="modal-pareceres-emitidos-etapa-parecer-nota">{{etapa.pareceresDisponiveis.parecer.nota}}</span> -->
            <a class="btn btn-default"
                (click)="downloadParecerAnalise(etapa.pareceresDisponiveis.parecer.idDocumento)">Download
            </a>
        </div>
    </div>
</div>