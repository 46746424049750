<div class="busca-bolsa">
    <div class="row">
        <div class="col-md-12">
            <div class="col-md-2 margin-top-pesquisa">
                <div class="form-group">
                    <label for="tipo">Tipo:</label>
                    <select formControlName="tipo" id="tipo" class="form-control select-filtro" (change)="alterarBusca($event, 'TIPO_PARAM_INSCRICAO')">
                        <option></option>
                        <option *ngFor="let tipo of tiposParametrizacao" [value]="tipo.tipo">{{tipo.descricao}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4 margin-top-pesquisa">
                <div class="form-group">
                    <label for="modalidade_bolsa">Buscar por Modalidade de bolsa:</label>
                    <select formControlName="modalidade_bolsa" id="modalidade_bolsa" class="form-control select-filtro" (change)="alterarBusca($event, 'MODALIDADE')">
                        <option></option>
                        <option *ngFor="let modalidade of modalidadeBolsa" [value]="modalidade.ds_modalidade_bolsa">{{modalidade.ds_modalidade_bolsa}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-4 margin-top-pesquisa">
                <div class="form-group">
                    <label for="programa_capes">Buscar por Programa Capes:</label>
                    <select formControlName="programa_capes" id="programa_capes" class="form-control select-filtro" (change)="alterarBusca($event, 'PROGRAMA')">
                        <option></option>
                        <option *ngFor="let programa of programaCapes" [value]="programa.id_programa_capes">{{programa.nm_programa_capes}}</option>
                    </select>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <button style="margin-top: 25px;" class="btn btn-default" (click)="alterarBusca('', 'limpar')">Limpar Busca</button>
                </div>
            </div>
        </div>
    </div>
</div>