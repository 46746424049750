import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RegisterComponent} from './pages/components/register/register.component';
import {AuthGuard} from '@sicapes/auth';
import { InscricaoOrcamentoProjetoBolsaFormComponent } from './pages/formularios/inscricao-orcamento-projeto/components/inscricao-orcamento-projeto-bolsa-form/inscricao-orcamento-projeto-bolsa-form.component';

const routes: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboard/dashboard.module').then(d => d.DashboardModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'inscricao/:proponente/:codigo/finalizacao',
        loadChildren: () => import('./pages/formularios/inscricao-finalizacao/inscricao-finalizacao.module').then(d => d.InscricaoFinalizacaoModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'inscricao/:proponente/:codigo',
        loadChildren: () => import('./pages/formularios/inscricao-dados-pessoais/inscricao-dados-pessoais.module').then(d => d.InscricaoDadosPessoaisModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'inscricao/:proponente/:codigo/inscricao-endereco',
        loadChildren: () => import('./pages/formularios/inscricao-endereco/inscricao-endereco.module').then(d => d.InscricaoEnderecoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'inscricao-endereco' }
    },
    {
        path: 'inscricao/:proponente/:codigo/inscricao-telefones',
        loadChildren: () => import('./pages/formularios/inscricao-telefone/inscricao-telefone.module').then(d => d.InscricaoTelefoneModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'inscricao-telefones' }
    },
    {
        path: 'inscricao/:proponente/:codigo/inscricao-correios-eletronicos',
        loadChildren: () => import('./pages/formularios/inscricao-correio-eletronico/inscricao-correio-eletronico.module').then(d => d.InscricaoCorreioEletronicoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'inscricao-correios-eletronicos' }
    },
    {
        path: 'inscricao/:proponente/:codigo/inscricao-empregos',
        loadChildren: () => import('./pages/formularios/inscricao-emprego/inscricao-emprego.module').then(d => d.InscricaoEmpregoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'inscricao-empregos' }
    },
    {
        path: 'inscricao/:proponente/:codigo/inscricao-empregos-vinculo',
        loadChildren: () => import('./pages/formularios/inscricao-emprego-vinculo/inscricao-emprego-vinculo.module').then(d => d.InscricaoEmpregoVinculoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'inscricao-empregos-vinculo' }
    },
    {
        path: 'inscricao/:proponente/:codigo/inscricao-trajetoria',
        loadChildren: () => import('./pages/formularios/inscricao-trajetoria-academica/inscricao-trajetoria-academica.module').then(d => d.InscricaoTrajetoriaAcademicaModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'inscricao-trajetoria' }
    },
    {
        path: 'inscricao/:proponente/:codigo/socioeconomico',
        loadChildren: () => import('./pages/formularios/inscricao-socioeconomico/inscricao-socioeconomico.module').then(d => d.InscricaoSocioeconomicoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'socioeconomico' }
    },
    {
        path: 'inscricao/:proponente/:codigo/inscricao-titulos',
        loadChildren: () => import('./pages/formularios/inscricao-titulo/inscricao-titulo.module').then(d => d.InscricaoTituloModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'inscricao-titulos' }
    },
    {
        path: 'inscricao/:proponente/:codigo/inscricao-formacoes-andamento',
        loadChildren: () => import('./pages/formularios/inscricao-formacao/inscricao-formacao.module').then(d => d.InscricaoFormacaoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'inscricao-formacoes-andamento' }
    },
    {
        path: 'inscricao/:proponente/:codigo/inscricao-formacoes-andamento-sucupira',
        loadChildren: () => import('./pages/formularios/inscricao-formacao-sucupira/inscricao-formacao-sucupira.module').then(d => d.InscricaoFormacaoSucupiraModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'inscricao-formacoes-andamento' }
    },
    {
        path: 'inscricao/:proponente/:codigo/documentos-exigidos',
        loadChildren: () => import('./pages/formularios/inscricao-documentos-exigidos/inscricao-documentos-exigidos.module').then(d => d.InscricaoDocumentosExigidosModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'documentos-exigidos' }
    },
    {
        path: 'inscricao/:proponente/:codigo/documentos-anexos-uab',
        loadChildren: () => import('./pages/formularios/inscricao-documentos-exigidos-uab/inscricao-documentos-exigidos-uab.module').then(d => d.InscricaoDocumentosExigidosUabModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'documentos-exigidos-uab' }
    },
    {
        path: 'inscricao/:proponente/:codigo/estudos-pretendidos-tipo-bolsa',
        loadChildren: () => import('./pages/formularios/inscricao-solicitacao/inscricao-solicitacao.module').then(d => d.InscricaoSolicitacaoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'inscricao-estudos-pretendidos' }
    },
    {
        path: 'inscricao/:proponente/:codigo/dados-gerais-projeto',
        loadChildren: () => import('./pages/formularios/inscricao-dados-gerais-projeto/inscricao-dados-gerais-projeto.module').then(d => d.InscricaoDadosGeraisProjetoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'dados-gerais-projeto' }
    },
    {
        path: 'inscricao/:proponente/:codigo/dados-gerais-projeto-dri',
        loadChildren: () => import('./pages/formularios/inscricao-dados-gerais-projeto-dri/inscricao-dados-gerais-projeto-dri.module').then(d => d.InscricaoDadosGeraisProjetoDriModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'dados-gerais-projeto-dri' }
    },
    {
        path: 'inscricao/:proponente/:codigo/dados-gerais-projeto-dri-completo',
        loadChildren: () => import('./pages/formularios/inscricao-dados-gerais-projeto-dri-completo/inscricao-dados-gerais-projeto-dri-completo.module').then(d => d.InscricaoDadosGeraisProjetoDriCompletoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'dados-gerais-projeto-dri-completo' }
    },
    {
        path: 'inscricao/:proponente/:codigo/dados-projeto-ies',
        loadChildren: () => import('./pages/formularios/inscricao-dados-gerais-projeto-ies/inscricao-dados-gerais-projeto-ies.module').then(d => d.InscricaoDadosGeraisProjetoIesModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'dados-projeto-ies' }
    },
    {
        path: 'inscricao/:proponente/:codigo/dados-projeto-paegp',
        loadChildren: () => import('./pages/formularios/inscricao-dados-gerais-projeto-paegp/inscricao-dados-gerais-projeto-paegp.module').then(d => d.InscricaoDadosGeraisProjetoPaegpModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'dados-projeto-paegp' }
    },
    {
        path: 'inscricao/:proponente/:codigo/dados-gerais-projeto-uab',
        loadChildren: () => import('./pages/formularios/inscricao-dados-gerais-projeto-uab/inscricao-dados-gerais-projeto-uab.module').then(d => d.InscricaoDadosGeraisProjetoUabModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'dados-gerais-projeto-uab' }
    },
    {
        path: 'inscricao/:proponente/:codigo/ies-participantes',
        loadChildren: () => import('./pages/formularios/inscricao-ies-participante/inscricao-ies-participante.module').then(d => d.InscricaoIesParticipanteModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'ies-participantes' }
    },
    {
        path: 'inscricao/:proponente/:codigo/membros-projeto-geral',
        loadChildren: () => import('./pages/formularios/inscricao-membro-equipe-geral/inscricao-membro-equipe-geral.module').then(d => d.InscricaoMembroEquipeGeralModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'membros-projeto-geral' }
    },
    {
        path: 'inscricao/:proponente/:codigo/pessoas-projeto-uab',
        loadChildren: () => import('./pages/formularios/inscricao-membro-equipe-uab/inscricao-membro-equipe-uab.module').then(d => d.InscricaoMembroEquipeUabModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'pessoas-projeto-uab' }
    },
    {
        path: 'inscricao/:proponente/:codigo/resultado-projeto',
        loadChildren: () => import('./pages/formularios/inscricao-resultado-projeto/inscricao-resultado-projeto.module').then(d => d.InscricaoResultadoProjetoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'resultado-projeto' }
    },
    {
        path: 'inscricao/:proponente/:codigo/cronograma-projeto',
        loadChildren: () => import('./pages/formularios/inscricao-cronograma-projeto/inscricao-cronograma-projeto.module').then(d => d.InscricaoCronogramaProjetoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'cronograma-projeto' }
    },
    {
        path: 'inscricao/:proponente/:codigo/cronograma-estudos',
        loadChildren: () => import('./pages/formularios/inscricao-cronograma-estudo/inscricao-cronograma-estudo.module').then(d => d.InscricaoCronogramaEstudoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'cronograma-estudos' }
    },
    {
        path: 'inscricao/:proponente/:codigo/objetivo-especifico-projeto-uab',
        loadChildren: () => import('./pages/formularios/inscricao-cronograma-projeto-uab/inscricao-cronograma-projeto-uab.module').then(d => d.InscricaoCronogramaProjetoUabModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'objetivo-especifico-projeto-uab' }
    },
    {
        path: 'inscricao/:proponente/:codigo/orcamento-projeto',
        loadChildren: () => import('./pages/formularios/inscricao-orcamento-projeto/inscricao-orcamento-projeto.module').then(d => d.InscricaoOrcamentoProjetoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'orcamento-projeto' }
    },
    {
        path: 'inscricao/:proponente/:codigo/orcamento-projeto-bolsa',
        loadChildren: () => import('./pages/formularios/inscricao-orcamento-projeto/inscricao-orcamento-projeto.module').then(d => d.InscricaoOrcamentoProjetoModule),
        component: InscricaoOrcamentoProjetoBolsaFormComponent,
        canActivate: [AuthGuard],
        data: { siglaForm: 'orcamento-projeto-bolsa' }
    },
    {
        path: 'inscricao/:proponente/:codigo/orcamento-projeto-uab',
        loadChildren: () => import('./pages/formularios/inscricao-orcamento-projeto-uab/inscricao-orcamento-projeto-uab.module').then(d => d.InscricaoOrcamentoProjetoUabModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'orcamento-projeto-uab' }
    },
    {
        path: 'inscricao/:proponente/:codigo/plano-trabalho-projeto',
        loadChildren: () => import('./pages/formularios/inscricao-plano-trabalho-projeto/inscricao-plano-trabalho-projeto.module').then(d => d.InscricaoPlanoTrabalhoProjetoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'plano-trabalho-projeto' }
    },
    {
        path: 'inscricao/:proponente/:codigo/plano-trabalho-cronograma-projeto',
        loadChildren: () => import('./pages/formularios/inscricao-plano-trabalho-projeto/inscricao-plano-trabalho-projeto.module').then(d => d.InscricaoPlanoTrabalhoProjetoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'plano-trabalho-cronograma-projeto' }
    },
    {
        path: 'inscricao/:proponente/:codigo/participante-ppg',
        loadChildren: () => import('./pages/formularios/inscricao-participante-ppg/inscricao-participante-ppg.module').then(d => d.InscricaoParticipantePpgModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'participante-ppg' }
    },
    {
        path: 'inscricao/:proponente/:codigo/projeto-evento',
        loadChildren: () => import('./pages/formularios/inscricao-projeto-evento/inscricao-projeto-evento.module').then(d => d.InscricaoProjetoEventoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'projeto-evento' }
    },
    {
        path: 'inscricao/:proponente/:codigo/publico-alvo-evento',
        loadChildren: () => import('./pages/formularios/inscricao-publico-alvo/inscricao-publico-alvo.module').then(d => d.InscricaoPublicoAlvoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'publico-alvo-evento' }
    },
    {
        path: 'inscricao/:proponente/:codigo/orcamento-evento',
        loadChildren: () => import('./pages/formularios/inscricao-orcamento-evento/inscricao-orcamento-evento.module').then(d => d.InscricaoOrcamentoEventoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'orcamento-evento' }
    },
    {
        path: 'inscricao/:proponente/:codigo/recurso',
        loadChildren: () => import('./pages/formularios/inscricao-recurso/inscricao-recurso.module').then(d => d.InscricaoRecursoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'recurso' }
    },
    {
        path: 'inscricao/:proponente/:codigo/dados-gerais-projeto-prapg',
        loadChildren: () => import('./pages/formularios/inscricao-dados-gerais-projeto-prapg/inscricao-dados-gerais-projeto-prapg.module').then(d => d.InscricaoDadosGeraisProjetoPrapgModule),
        canActivate: [AuthGuard]
    },
    {
        path: 'inscricao/:proponente/:codigo/dados-gerais-projeto-parfor',
        loadChildren: () => import('./pages/formularios/inscricao-dados-gerais-projeto-parfor/inscricao-dados-gerais-projeto-parfor.module').then(d => d.InscricaoDadosGeraisProjetoParforModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'dados-gerais-projeto-parfor' }
    },
    {
        path: 'inscricao/:proponente/:codigo/dados-gerais-projeto-proext',
        loadChildren: () => import('./pages/formularios/inscricao-dados-gerais-projeto-proext/inscricao-dados-gerais-projeto-proext.module').then(d => d.InscricaoDadosGeraisProjetoProextModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'dados-gerais-projeto-proext' }
    },
    {
        path: 'inscricao/:proponente/:codigo/oferta-cursos-projeto-parfor',
        loadChildren: () => import('./pages/formularios/inscricao-oferta-cursos/inscricao-oferta-cursos.module').then(d => d.InscricaoOfertaCursosModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'oferta-cursos-projeto-parfor' }
    },
    {
        path: 'inscricao/:proponente/:codigo/bolsa-declaratoria',
        loadChildren: () => import('./pages/formularios/inscricao-bolsa-declaratoria/inscricao-bolsa-declaratoria.module').then(d => d.InscricaoBolsaDeclaratoriaModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'bolsa-declaratoria' }
    },
    // PDSE
    {
        path: 'inscricao/:proponente/:codigo/proposta-pesquisa-individual',
        loadChildren: () => import('./pages/formularios/inscricao-proposta-pesquisa-individual/inscricao-proposta-pesquisa-individual.module').then(d => d.InscricaoPropostaPesquisaIndividualModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'proposta-pesquisa-individual' }
    },
    {
        path: 'inscricao/:proponente/:codigo/excepcionalidade-proposta-pesquisa',
        loadChildren: () => import('./pages/formularios/inscricao-proposta-pesquisa/inscricao-proposta-pesquisa.module').then(d => d.InscricaoPropostaPesquisaModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'excepcionalidade-proposta-pesquisa' }
    },
    {
        path: 'inscricao/:proponente/:codigo/objetivos-produtos-esperados',
        loadChildren: () => import('./pages/formularios/inscricao-objetivos-produtos-esperados/inscricao-objetivos-produtos-esperados.module').then(d => d.InscricaoObjetivosProdutosEsperadosModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'objetivos-produtos-esperados' }
    },
    {
        path: 'inscricao/:proponente/:codigo/participantes-evento',
        loadChildren: () => import('./pages/formularios/inscricao-participante-evento/inscricao-participante-evento.module').then(d => d.InscricaoParticipanteEventoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'participantes-evento' }
    },
    {
        path: 'inscricao/:proponente/:codigo/instituicao-promotora-evento',
        loadChildren: () => import('./pages/formularios/inscricao-ies-promotora-evento/inscricao-ies-promotora-evento.module').then(d => d.InscricaoIesPromotoraEventoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'instituicao-promotora-evento' }
    },
    {
        path: 'inscricao/:proponente/:codigo/programacao-evento',
        loadChildren: () => import('./pages/formularios/inscricao-programacao-evento/inscricao-programacao-evento.module').then(d => d.InscricaoProgramacaoEventoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'programacao-evento' }
    },
    {
        path: 'inscricao/:proponente/:codigo/proposta-uab',
        loadChildren: () => import('./pages/formularios/inscricao-proposta-uab/inscricao-proposta-uab.module').then(d => d.InscricaoPropostaUabModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'proposta-uab' }
    },
    {
        path: 'inscricao/:proponente/:codigo/trabalho',
        loadChildren: () => import('./pages/formularios/inscricao-trabalho/inscricao-trabalho.module').then(d => d.InscricaoTrabalhoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'trabalho' }
    },
    {
        path: 'inscricao/:proponente/:codigo/evento',
        loadChildren: () => import('./pages/formularios/inscricao-evento/inscricao-evento.module').then(d => d.InscricaoEventoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'evento' }
    },
    {
        path: 'inscricao/:proponente/:codigo/inscricao-indicacao-vagas',
        loadChildren: () => import('./pages/formularios/inscricao-indicacao-vagas/inscricao-indicacao-vagas.module').then(d => d.InscricaoIndicacaoVagasModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'inscricao-indicacao-vagas' }
    },
    {
        path: 'inscricao/:proponente/:codigo/projeto-institucional-pibid',
        loadChildren: () => import('./pages/formularios/inscricao-projeto-institucional-pibid/inscricao-projeto-institucional-pibid.module').then(d => d.InscricaoProjetoInstitucionalPibidModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'projeto-institucional-pibid' }
    },
    {
        path: 'inscricao/:proponente/:codigo/subprojeto-institucional-pibid',
        loadChildren: () => import('./pages/formularios/inscricao-subprojeto-pibid/inscricao-subprojeto-pibid.module').then(d => d.InscricaoSubprojetoPibidModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'subprojeto-institucional-pibid' }
    },
    {
        path: 'inscricao/:proponente/:codigo/inscricao-solicitacao-novacao',
        loadChildren: () => import('./pages/formularios/inscricao-solicitacao-novacao/inscricao-solicitacao-novacao.module').then(d => d.InscricaoSolicitacaoNovacaoModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'inscricao-solicitacao-novacao' }
    },
    {
        path: 'inscricao/:proponente/:codigo/dados-gerais-projeto-procad-drogas',
        loadChildren: () => import('./pages/formularios/inscricao-dados-gerais-projeto-procad-drogas/inscricao-dados-gerais-projeto-procad-drogas.module').then(d => d.InscricaoDadosGeraisProjetoProcadDrogasModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'dados-projeto-politicas-publicas' }
    },
    {
        path: 'inscricao/:proponente/:codigo/estudo-pretendido-pais',
        loadChildren: () => import('./pages/formularios/inscricao-solicitacao-ppg/inscricao-solicitacao-ppg.module').then(d => d.InscricaoSolicitacaoPpgModule),
        canActivate: [AuthGuard],
        data: { siglaForm: 'estudo-pretendido-pais' }
    },
    {
        path: 'auth',
        component: RegisterComponent,
    },
    // otherwise redirect to home
    {
        path: '**',
        redirectTo: 'dashboard',
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
