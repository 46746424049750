import { Injectable, Injector } from "@angular/core";
import { BaseResourceService } from "@sicapes/core";
import { Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Recurso } from "../../models/inscricao/recurso.model";

@Injectable({
    providedIn: 'root'
})
export class RecursoService extends BaseResourceService<Recurso>{
    constructor(protected injector: Injector,) {
        super('rest/inscricoes', injector, Recurso.fromJson);
    }

    /**
     * Obtem dados do recurso
     *
     * @param {*} proponente
     * @param {*} codigo
     * @returns {Observable<Recurso>}
     * @memberof RecursoService
     */
    public obterRecurso(proponente, codigo): Observable<Recurso> {
        const url = 'rest/inscricoes/' + proponente + '/' + codigo + '/recurso';
        return this.http.get(url)
            .pipe(
                map(this.jsonDataToResource.bind(this)), catchError(this.handleError)
            );
    }

    /**
     * Obtem dados do recurso
     *
     * @param {*} proponente
     * @param {*} codigo
     * @returns {Observable<Recurso>}
     * @memberof RecursoService
     */
    public obterRecursos(proponente, codigo): Observable<any> {
        const url = 'rest/inscricoes/' + proponente + '/' + codigo + '/recurso';
        return this.http.get(url);
    }
    
    /**
     *
     *
     * @param {string} proponente
     * @param {string} codigo
     * @param {Recurso} recurso
     * @returns {Observable<Recurso>}
     * @memberof RecursoService
     */
    public salvarRecurso(proponente: string, codigo: string, recurso: Recurso): Observable<Recurso> {
        const url = 'rest/inscricoes/' + proponente + '/' + codigo + '/recurso/' + recurso.id;
        return this.http.put(url, recurso);
    }
    /**
     *
     *
     * @param {string} proponente
     * @param {string} codigo
     * @returns {*}
     * @memberof RecursoService
     */
    public enviarParaAnalise(proponente: string, codigo: string): any {
        const url = 'rest/inscricoes/' + proponente + '/' + codigo + '/submeter-recurso';
        return this.http.post(url, {})
    }
}