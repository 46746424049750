<div *ngIf="trigger && this.inscricao.cd_inscricao === this.target" class="modal">
    <div class="modal-bg" (click)="closeModal()"></div>
    <div class="modal-content">
        <div class="modal-content-header">{{title}}
            <span><button class="btn btn-default btn-xs" (click)="closeModal()">X</button></span>
        </div>
        <div class="modal-content-body">
            <div *ngFor="let recurso of recursos" class="recurso">
                <div class="recurso-header">
                    <div class="recurso-header-etapa">{{recurso.situacao_analise}}</div>
                    <div class="recurso-header-situacao">
                        <span *ngIf="recursoEnviado(recurso)">Recurso encaminhado</span>
                        <span *ngIf="!recursoEnviado(recurso)">Recurso não encaminhado</span>
                    </div>
                </div>
                <div class="recurso-informacao-analise" *ngFor="let ia of recurso.informacoes_analise">
                    <div class="recurso-documentos">
                        <div *ngIf="filtraTipoDocumento(ia.documentos, 'P').length > 0">
                            <span class="recurso-documentos-titulo">Pareceres Contestáveis</span>
                            <div class="recurso-documentos-content">
                                <div class="btn-group btn-group-sm">
                                    <a *ngFor="let doc of filtraTipoDocumento(ia.documentos, 'P')"
                                        (click)="donwloadDocumento(inscricao, doc)" class="btn btn-default"><i
                                            class="glyphicon glyphicon-file"></i>{{doc.documento.nome}}</a>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="filtraTipoDocumento(ia.documentos, 'J').length > 0">
                            <span class="recurso-documentos-titulo">Comprovante de recurso</span>
                            <div class="recurso-documentos-content">
                                <div class="btn-group btn-group-sm">
                                    <a *ngFor="let doc of filtraTipoDocumento(ia.documentos, 'J')"
                                        (click)="donwloadDocumento(inscricao, doc)" class="btn btn-default"><i
                                            class="glyphicon glyphicon-file"></i>{{doc.documento.nome}}</a>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="filtraTipoDocumento(ia.documentos, 'A').length > 0">
                            <span class="recurso-documentos-titulo">Anexos</span>
                            <div class="recurso-documentos-content">
                                <div class="btn-group btn-group-sm">
                                    <a *ngFor="let doc of filtraTipoDocumento(ia.documentos, 'A')"
                                        (click)="donwloadDocumento(inscricao, doc)" class="btn btn-default"><i
                                            class="glyphicon glyphicon-file"></i>{{doc.documento.nome}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <div class="modal-content-footer"></div>
    </div>
</div>