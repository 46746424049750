import {Component, Input} from '@angular/core';
import {ParametrizacaoService} from '../../../shared/service/parametrizacao/parametrizacao.service';
import {BaseDashboardComponent} from '../base-dashboard.component';
import {ParticipanteService} from '../../../shared/service/inscricao/participante.service';
import {ToastrService} from 'ngx-toastr';
import {ConfirmationService} from 'primeng/api';
import {InscricaoService} from '../../../shared/service/inscricao/inscricao.service';

@Component({
  selector: 'app-dashboard-aba-cancelada',
  templateUrl: './dashboard-aba-cancelada.component.html',
  styleUrls: ['./dashboard-aba-cancelada.component.css']
})
export class DashboardAbaCanceladaComponent extends BaseDashboardComponent {

  @Input() rows: any;

  constructor(protected parametrizacaoService: ParametrizacaoService,
              protected participanteService: ParticipanteService,
              protected inscricaoService: InscricaoService,
              protected confirmationService: ConfirmationService,
              protected toastr: ToastrService) {
    super(parametrizacaoService, participanteService, inscricaoService, confirmationService, toastr);
  }


}
