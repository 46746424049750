import {Component, Input, OnInit} from '@angular/core';
import {ParticipanteService} from '../../../shared/service/inscricao/participante.service';
import {ParametrizacaoService} from '../../../shared/service/parametrizacao/parametrizacao.service';
import {ConfirmationService, ConfirmEventType} from 'primeng/api';
import {InscricaoService} from '../../../shared/service/inscricao/inscricao.service';
import {ToastrService} from 'ngx-toastr';
import {BaseDashboardComponent} from '../base-dashboard.component';

@Component({
    selector: 'app-dashboard-aba-finalizada',
    templateUrl: './dashboard-aba-finalizada.component.html',
    styleUrls: ['./dashboard-aba-finalizada.component.css'],
    providers: [ConfirmationService]
})
export class DashboardAbaFinalizadaComponent extends BaseDashboardComponent {

    @Input() rows: any;

    constructor(protected parametrizacaoService: ParametrizacaoService,
                protected participanteService: ParticipanteService,
                protected confirmationService: ConfirmationService,
                protected inscricaoService: InscricaoService,
                protected toastr: ToastrService) {
        super(parametrizacaoService, participanteService, inscricaoService, confirmationService, toastr);
    }

    public outrosPareceres(inscricao: any): void {
        this.participanteService.obterPareceresEmitidos(inscricao.ds_identificador_registrado, inscricao.cd_inscricao).subscribe(
            p => {

        }, err => {
            if(err.status === 404){
            this.toastr.warning("Não foram disponibilizados pareceres para esse processo.","Não encontrado (404)");
            }
        })
    }

    emitirComprovante(inscricao: any): any {
        this.participanteService.recuperarInformacaoDocumento(inscricao.ds_identificador_registrado, inscricao.cd_inscricao).subscribe(
            resources => {
                const documentoInscricao = resources.filter((doc: any) => {
                    return doc.documento_exigido.tipo.id === 555;
                })[0];
                this.participanteService.verDocumento(inscricao.ds_identificador_registrado, inscricao.cd_inscricao, documentoInscricao.id);
            },
            error => this.toastr.warning('Não foi possível localizar o Comprovante de Inscrição')
        );
    }

    downloadParecerAnalise(inscricao: any, parecer: any): any {
        this.participanteService.downloadParecerAnalise(inscricao.ds_identificador_registrado, inscricao.cd_inscricao, parecer.idDocumento);
    }
}
