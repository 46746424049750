import { Component, Input, OnInit } from '@angular/core';
import { BaseDashboardComponent } from '../base-dashboard.component';
import { ParametrizacaoService } from 'src/app/shared/service/parametrizacao/parametrizacao.service';
import { ParticipanteService } from 'src/app/shared/service/inscricao/participante.service';
import { InscricaoService } from 'src/app/shared/service/inscricao/inscricao.service';
import { ConfirmationService } from 'primeng/api';
import { ToastrService } from 'ngx-toastr';
import { DashboardService } from 'src/app/shared/service/dashboard/dashboard.service';

@Component({
  selector: 'app-dashboard-aba-em-analise',
  templateUrl: './dashboard-aba-em-analise.component.html',
  styleUrls: ['./dashboard-aba-em-analise.component.less']
})

export class DashboardAbaEmAnaliseComponent extends BaseDashboardComponent implements OnInit {

  @Input() rows: any;
  public pareceresAnalise = { proponente: null, inscricao: null, pareceres: null };

  constructor(
    protected parametrizacaoService: ParametrizacaoService,
    protected participanteService: ParticipanteService,
    protected inscricaoService: InscricaoService,
    protected confirmationService: ConfirmationService,
    protected toastr: ToastrService,
    protected dashboardService: DashboardService
  ) { super(parametrizacaoService, participanteService, inscricaoService, confirmationService, toastr) }

  ngOnInit(): void {

  }
  /**
   * Realiza a chamada para o serviço do SAP para baixar os  documentos emitidos na análise
   *
   * @param {Inscricao} inscricao
   * @memberof InscricaoRecursoFormComponent
   */
  public outrosPareceres(inscricao: any): void {
    this.participanteService.obterPareceresEmitidos(inscricao.ds_identificador_registrado, inscricao.cd_inscricao).subscribe(
      p => {
        if (p.total_items > 0) {
          this.pareceresAnalise.proponente = inscricao.ds_identificador_registrado
          this.pareceresAnalise.inscricao = inscricao.cd_inscricao
          this.pareceresAnalise.pareceres = p._embedded.items
        }
      }, err => {
        if (err.status === 404) {
          this.toastr.warning("Não foram disponibilizados pareceres para esse processo.", "Não encontrado (404)");
        }
      })
  }
  public resetParecerModal() {
    this.pareceresAnalise = { proponente: null, inscricao: null, pareceres: null };
  }
  public abrirModal(inscricao: any) {
    this.dashboardService.openModal(inscricao);
    this.dashboardService.openedModalEvent.subscribe((isOpened) => {
      inscricao.recurso_modal = !inscricao.recurso_modal;
    })
  }

}
