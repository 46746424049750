import { Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { BaseResourceService } from '@sicapes/core';
import {OrcamentoModel} from '../../models/inscricao/orcamento.model';
import {ItemBolsaModel} from '../../models/inscricao/item-bolsa.model';
import {ItemOrcamentoModel} from '../../models/inscricao/item-orcamento.model';
import {ItemReceita} from '../../models/inscricao/item-receita.model';
import {PublicoAlvo} from "../../models/evento/publico-alvo.model";

@Injectable({
    providedIn: 'root'
})
export class OrcamentoProjetoService extends BaseResourceService<OrcamentoModel> {

    constructor(protected injector: Injector) {
        super('rest/inscricoes', injector, OrcamentoModel.fromJson);
    }

    public obterOrcamento(proponente: string, codigo: string): Observable<OrcamentoModel> {
        const url = this.apiPath + '/' + proponente + '/' + codigo + '/orcamentos-projeto/vigente';
        return this.http.get(url).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );
    }

    public excluirItemOrcamento(proponente: string, codigo: string, itemOrcamento: ItemOrcamentoModel): Observable<ItemBolsaModel> {

        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/orcamentos-projeto/' + codigo + '/itens-orcamento/' + itemOrcamento.id;

        return this.http.delete(url, {observe: 'response'}).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    public salvarItemOrcamento(proponente: string, codigo: string, codOrcamento: number, itemOrcamento: ItemOrcamentoModel): Observable<ItemBolsaModel> {

        let url = '/rest/inscricoes/' + proponente + '/' + codigo + '/orcamentos-projeto/' + codOrcamento + '/itens-orcamento';

        if (itemOrcamento.id) {
            url += '/' + itemOrcamento.id;
            return this.http.put(url, itemOrcamento, {observe: 'response'}).pipe(
                map(() => itemOrcamento),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, itemOrcamento, {observe: 'response'}).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );

    }

    public salvarItemReceita(proponente: string, codigo: string, itemReceita: ItemReceita): Observable<ItemReceita> {

        let url = '/rest/inscricoes/' + proponente + '/' + codigo + '/orcamentos-projeto/' + codigo + '/itens-receita';

        if (itemReceita.id) {
            url += '/' + itemReceita.id;
            return this.http.put(url, itemReceita, {observe: 'response'}).pipe(
                map(() => itemReceita),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, itemReceita, {observe: 'response'}).pipe(
            map((end: any) => ItemReceita.fromJson(end)),
            catchError(this.handleError)
        );

    }

    public excluirItemReceita(proponente: string, codigo: string, itemReceita: ItemReceita): Observable<ItemReceita> {

        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/orcamentos-projeto/' + codigo + '/itens-receita/' + itemReceita.id;

        return this.http.delete(url, {observe: 'response'}).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }

    public salvarItemBolsa(proponente: string, codigo: string, itemBolsa: ItemBolsaModel): Observable<ItemBolsaModel> {

        let url = '/rest/inscricoes/' + proponente + '/' + codigo + '/orcamentos-projeto/vigente/itens-bolsa';

        if (itemBolsa.id) {
            url += '/' + itemBolsa.id;
            return this.http.put(url, itemBolsa, {observe: 'response'}).pipe(
                map(() => itemBolsa),
                catchError(this.handleError)
            );
        }

        return this.http.post(url, itemBolsa, {observe: 'response'}).pipe(
            map(this.jsonDataToResource.bind(this)),
            catchError(this.handleError)
        );

    }

    public excluirItemBolsa(proponente: string, codigo: string, itemBolsa: ItemBolsaModel): Observable<ItemBolsaModel> {

        const url = '/rest/inscricoes/' + proponente + '/' + codigo + '/orcamentos-projeto/vigente/itens-bolsa/' + itemBolsa.id;

        return this.http.delete(url, {observe: 'response'}).pipe(
            map(() => null),
            catchError(this.handleError)
        );
    }


}
