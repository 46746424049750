import {APP_INITIALIZER, NgModule, LOCALE_ID} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {ToastrModule} from 'ngx-toastr';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {TemplateModule} from './template/template.module';
import {CoreModule} from '@sicapes/core';
import {AuthModule, JwtInterceptor, AUTH_ENVIRONMENT, AuthService} from '@sicapes/auth';
import {SharedModule} from './shared/shared.module';
import {CommonModule, registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClient} from '@angular/common/http';
import {InitAppService} from './shared/service/init-app.service';
import {environment} from '../environments/environment';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {PedenciaInterceptor} from './shared/helpers/pedencia.interceptor';
import ptBr from '@angular/common/locales/pt';
import {TooltipModule} from 'primeng/tooltip';
import {InputTextModule} from 'primeng/inputtext';

export function initApp(initAppService: InitAppService): any {
  return () => initAppService.initializeApp();
}

registerLocaleData(ptBr);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        CoreModule,
        AuthModule,
        AppRoutingModule,
        TemplateModule,
        SharedModule,
        TooltipModule,
        InputTextModule,
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'pt'},
        {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: PedenciaInterceptor, multi: true},
        AuthService, {provide: AUTH_ENVIRONMENT, useValue: environment},
        InitAppService, {provide: APP_INITIALIZER, useFactory: initApp, multi: true, deps: [InitAppService]}
    ],
    exports: [
  ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
