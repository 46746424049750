
<!--p-messages *ngIf="qtdeEmRecurso > 0" [(value)]="dashboardMessages"></p-messages-->

<div class="pc-tab">
  <input [checked]="tabInscricoesAbertas" id="inscricoes-abertas" type="radio" name="pct"  />
  <input [checked]="tabMinhasInscricoes" id="minhas-inscricoes" type="radio" name="pct" />
  <nav>
    <ul>
      <li class="inscricoes-abertas">
        <label for="inscricoes-abertas" class="">
          Editais com Inscrições Abertas&nbsp;&nbsp; 
          <span class="badge rounded-pill text-bg-secondary text-white">{{qtdeAberta}}</span>
        </label>
      </li>
      <li class="minhas-inscricoes">
        <label for="minhas-inscricoes">Minhas Inscrições&nbsp;&nbsp; <span class="badge rounded-pill text-bg-secondary text-white">{{qtdeMinhasInscricoes}}</span></label>
      </li>
    </ul>
  </nav>

  <section>
    <div class="inscricoes-abertas" id="inscricoesAbertas">
      <app-dashboard-aba-aberta *ngIf="parametrizacoes?.length > 0" [rows]="parametrizacoes" [inscricoes]="inscricoes" (inscricaoEvent)="reciverInscricao($event)"></app-dashboard-aba-aberta>
    </div>
    <div class="minhas-inscricoes" id="minhasInscricoes">
      <div class="inner-panel enrollment aba-aberta">
        
        <div *ngIf="qtdeEmRecurso > 0" class="row card-finalizado">
          <div class="h4 pb-2 mb-4 text-danger border-bottom border-danger">
            <a id="inscricoes-recurso"></a>
            <a href="javascript:void(0);" [routerLink]="" (click)="handleChange('recurso')" >Inscrições em Recurso <span class="badge text-bg-danger">{{qtdeEmRecurso}}</span></a>
          </div>
          <app-dashboard-aba-em-recurso *ngIf="inscricoes.recurso?.length > 0"  [rows]="inscricoes"></app-dashboard-aba-em-recurso>
        </div>
      
        <div *ngIf="qtdeEmPreenchimento > 0" class="row card-finalizado">
          <div class="h4 pb-2 mb-4 text-purple border-bottom border-purple">
            <a id="inscricoes-em-preenchimento"></a>
            <a href="javascript:void(0);" [routerLink]="" (click)="handleChange('em-preenchimento')" >Inscrições em Preenchimento <span class="badge text-bg-purple">{{qtdeEmPreenchimento}}</span></a>
          </div>
          <app-dashboard-aba-em-preenchimento *ngIf="inscricoes.emPreenchimento?.length > 0" [rows]="inscricoes" [expandirInscricao]="cdInscricaoExpandir"></app-dashboard-aba-em-preenchimento>
        </div>

        <div *ngIf="qtdeFinalizada > 0" class="row card-finalizado">
          <div class="h4 pb-2 mb-4 text-success border-bottom border-success">
            <a id="inscricoes-finalizada"></a>
            <a href="javascript:void(0);" [routerLink]="" (click)="handleChange('finalizada')" >Inscrições Finalizadas <span class="badge text-bg-success">{{qtdeFinalizada}}</span></a>
          </div>
          <app-dashboard-aba-finalizada *ngIf="inscricoes.finalizada?.length > 0" [rows]="inscricoes"></app-dashboard-aba-finalizada>
        </div>

        <div *ngIf="qtdeEmAnalise > 0" class="row card-analise">
          <div class="h4 pb-2 mb-4 text-purple border-bottom border-purple">
            <a id="inscricoes-em-analise"></a>
            <a href="javascript:void(0);"  [routerLink]="" (click)="handleChange('em-analise')" >Inscrições em Análise <span class="badge text-bg-purple">{{qtdeEmAnalise}}</span></a>
          </div>
          <app-dashboard-aba-em-analise id="analise" *ngIf="inscricoes.emAnalise?.length" [rows]="inscricoes"></app-dashboard-aba-em-analise>
        </div>
      
        <div *ngIf="qtdeCancelada > 0" class="row card-cancelada">
          <div class="h4 pb-2 mb-4 text-dark border-bottom border-dark">
            <a id="inscricoes-cancelada"></a>
            <a href="javascript:void(0);" [routerLink]="" (click)="handleChange('cancelada')">Inscrições Canceladas <span class="badge text-bg-dark">{{qtdeCancelada}}</span></a>
          </div>
          <app-dashboard-aba-cancelada *ngIf="inscricoes.cancelada?.length > 0 || inscricoes.canceladaExpirada?.length > 0" [rows]="inscricoes"></app-dashboard-aba-cancelada>
        </div>
      </div>
    </div>
  </section>
  
</div>