import { BaseResourceModel } from '@sicapes/core';
import { InscricaoContextoParforModel } from './inscricao-contexto-parfor.model';

export class InscricaoCaracteristicaProjetoParforModel extends BaseResourceModel {
    constructor(
        public id?: number,
        public ic?: string,
        public igc?: string,
        public contextoParfor?: InscricaoContextoParforModel,
        public ultimaAlteracao?: string
) {
        super();
    }

    static fromJson(jsonData: any): InscricaoCaracteristicaProjetoParforModel {
        return Object.assign(new InscricaoCaracteristicaProjetoParforModel(), jsonData);
    }
}
