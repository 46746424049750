import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  openedModal = new Subject<any>();
  openedModalEvent: Observable<any> = this.openedModal.asObservable();
  dadosCarregados = new Subject<any>();
  dadosCarregadosEvent: Observable<any> = this.dadosCarregados.asObservable();
  inscricao = new BehaviorSubject<any>({});
  inscricaoEvent: Observable<any> = this.inscricao.asObservable();
  protected isOpened: boolean = false;
  constructor() { }

  public openModal(inscricao) {
    this.isOpened = true;
    this.inscricao.next(inscricao);
    this.openedModal.next(this.isOpened);
  }
  public closeModal() {
    this.isOpened = false;
    this.inscricao.next({});
    this.openedModal.next(this.isOpened);
  }

  public loadingFinished(){
    this.dadosCarregados.next('ok')
  } 
}
