import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ToggleButtonModule } from 'primeng/togglebutton';
import { SelectButtonModule } from 'primeng/selectbutton';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';

import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { LoadingScreenInterceptor } from './helpers/loading.interceptor';
import { FormDebugComponent } from './components/form-debug/form-debug.component';
import { FormFieldErrorComponent } from './components/form-field-error/form-field-error.component';
import { ButtonModule } from 'primeng/button';
import { InputMaskModule } from 'primeng/inputmask';
import { CalendarModule } from 'primeng/calendar';
import { PendenciaComponent } from './components/pendencia/pendencia.component';
import { TooltipModule } from 'primeng/tooltip';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { ChipsModule } from 'primeng/chips';
import { PalavraChaveComponent } from './components/palavra-chave/palavra-chave.component';
import {
    ReferenciaBibliograficaComponent
} from './components/referencia-bibliografica/referencia-bibliografica.component';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { NgxCurrencyModule } from 'ngx-currency';
import { MessageSystemComponent } from './components/message-system/message-system.component';
import { MensagensParametrizadasComponent } from './components/mensagens-parametrizadas/mensagens-parametrizadas.component';
import {InputNumberModule} from 'primeng/inputnumber';
import {
    InscricaoCadastrarDadosPessoaJuridicaFormModalComponent
} from './components/modal/inscricao-cadastrar-dados-pessoa-juridica-form-modal/inscricao-cadastrar-dados-pessoa-juridica-form-modal.component';
import { PareceresEmitidosComponent } from './components/pareceresEmitidos/pareceresEmitidos.component';
import { InscricaoObjetivoMetaIndicadoresFormModalComponent } from './components/modal/inscricao-objetivo-meta-indicadores-form-modal/inscricao-objetivo-meta-indicadores-form-modal.component';
// import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
    declarations: [
        LoadingScreenComponent,
        FormDebugComponent,
        FormFieldErrorComponent,
        PendenciaComponent,
        FileUploadComponent,
        PalavraChaveComponent,
        ReferenciaBibliograficaComponent,
        MessageSystemComponent,
        MensagensParametrizadasComponent,
        InscricaoCadastrarDadosPessoaJuridicaFormModalComponent,
        PareceresEmitidosComponent,
        InscricaoObjetivoMetaIndicadoresFormModalComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ToggleButtonModule,
        SelectButtonModule,
        DropdownModule,
        DialogModule,
        ButtonModule,
        InputMaskModule,
        CalendarModule,
        TooltipModule,
        AutoCompleteModule,
        ChipsModule,
        ConfirmDialogModule,
        NgxIntlTelInputModule,
        NgxCurrencyModule,
        InputNumberModule,
        // AngularEditorModule
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: LoadingScreenInterceptor, multi: true },
        ConfirmationService,
    ],
    exports: [
        LoadingScreenComponent,
        FormDebugComponent,
        FormFieldErrorComponent,
        ReactiveFormsModule,
        FormsModule,
        ToggleButtonModule,
        SelectButtonModule,
        DropdownModule,
        DialogModule,
        ButtonModule,
        InputMaskModule,
        InputNumberModule,
        CalendarModule,
        TooltipModule,
        AutoCompleteModule,
        ConfirmDialogModule,
        PendenciaComponent,
        FileUploadComponent,
        ChipsModule,
        PalavraChaveComponent,
        ReferenciaBibliograficaComponent,
        MessageSystemComponent,
        MensagensParametrizadasComponent,
        InscricaoCadastrarDadosPessoaJuridicaFormModalComponent,
        PareceresEmitidosComponent,
        InscricaoObjetivoMetaIndicadoresFormModalComponent,
        // AngularEditorModule
    ]
})
export class SharedModule {
}
