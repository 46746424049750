<div class="card card-cancelada" 
*ngFor="let inscricao of rows.cancelada; let i = index">
    <p class="card-header text-dark">
      <em class="{{ inscricao.tp_parametro_edital == 'I' ? 'pi pi-user' : inscricao.tp_parametro_edital == 'P' ? 'pi pi-book' : 'pi pi-money-bill' }}" pTooltip="{{ inscricao.tp_parametro_edital == 'I' ? 'Bolsa Individual' : inscricao.tp_parametro_edital == 'P' ? 'Projeto' : 'Auxílio' }}"></em>
      {{inscricao.nm_edital}} ({{inscricao.sg_edital}})
    </p>
    <div class="card-body">
      <div class="row">
        <div class="col ms-3">
          <p class="card-text">
            <em class="pi pi-book"></em> Código da Inscrição: <span class="fw-semibold">{{inscricao.cd_inscricao}}</span>
          </p>
          <p class="card-text">
            <em class="pi pi-calendar"></em> Data do cancelamento: <span class="fw-semibold">{{ formataData(inscricao.dt_operacao) }}</span> horas
          </p>
        </div>
      </div>
    </div>
</div>